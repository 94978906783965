import withPostList from 'containers/with-post-list';
import './styles.scss';
import JobCard from 'components/career-card';
import 'containers/jobForm';
import { getMetaTag } from '../../helpers/seo';
const Main = withPostList({
  entityName: 'job',
  title: 'Explore Openings',
  subTitle: 'At Laxaar',
  CardComponent: JobCard,
  classNames: {
    page: 'portfolio products animate__animated animate__fadeIn',
  },
  requestParams: {
    sort: 'createdAt',
    sortType: 'desc',
    size: 20,
  },
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Careers at Laxaar | Careers in Software Development | Careers in Business Development | Careers in IT',
        true
      );
    },
  },
  showFilters: false,
});
Main.displayName = 'Careers';
export default Main;
