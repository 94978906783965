import React from 'react';
import preProcess from 'containers/preprocess';
import { Form, Input, Select } from 'antd';
import ContactForm from 'containers/contactForm';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { GET_ENTITIES } from 'app-redux/actions';
import withApiData from 'containers/with-api-data';

const { Option } = Select;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ product, data, loading }) => {
  return (
    <ContactForm
      showLabels={true}
      inline={true}
      getExtraFields={
        !product
          ? ({ form }) => {
              const { getFieldDecorator } = form;
              return (
                <Form.Item label={'Select Product'} hasFeedback={true}>
                  {getFieldDecorator('product', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select the product',
                      },
                    ],
                    initialValue:
                      data && data.length > 0 ? data[0].title : undefined,
                  })(
                    <Select loading={loading}>
                      {(data || []).map(({ title }, index) => {
                        return (
                          <Option value={title} key={index}>
                            {title}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              );
            }
          : undefined
      }
      extra={{
        category: 'Enhancement',
        product: product ? product : undefined,
      }}
    />
  );
};

export default withApiData(Main, {
  requestConfig: {
    entityName: 'product',
    all: true,
  },
});
