let obj = {
  emitter: (state = null) => state,
};

export default obj;

function requireAll(r) {
  r.keys()
    .map((r) => {
      if (r !== 'index.js') {
        const name = r.replace('.js', '');
        const file = require(`${r}`);
        if (file) {
          obj[name.replace('./', '')] = file.default;
        }
      }
      return r;
    })
    .forEach(r);
}
requireAll(require.context('./', true, /\.js$/));
