import React from 'react';
import Link from '@arivaa-react/components/link';
import './styles.scss';
import ImageCarousel from '../portfolio-post/image-carousel';
import OtherInformation from '../portfolio-post/other-information';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ post }) => {
  return post ? (
    <div className="blog-details">
      <div className="tags">
        {post.tags
          ? post.tags.map((item, index) => {
              return (
                <Link key={index} className="tag">
                  {item}
                </Link>
              );
            })
          : null}
      </div>
      <p className="title">{post.title}</p>
      <div className="image">
        <img loading="lazy" alt="image" src={post.image} />
      </div>
      <div
        className="editor-content"
        dangerouslySetInnerHTML={{ __html: post.description }}
      />
      {post.portfolio && (
        <>
          <div className="screenshots">
            <p className="label">Project Screenshots</p>
            <ImageCarousel post={post.portfolio} />
          </div>
          <div className="other-information">
            <OtherInformation post={post.portfolio} />
          </div>
        </>
      )}
    </div>
  ) : null;
};

Main.displayName = 'Blog-Post';

export default Main;
