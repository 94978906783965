import React from 'react';
import './styles.scss';
import { Input, Button, Form } from 'antd';

const { TextArea } = Input;

const FormItem = Form.Item;
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { form, translate } = this.props;
  const { getFieldDecorator } = form;
  this.setValidations();
  const { name, message, email } = this.validations;
  return (
    <div className="general-form login">
      <div className="content">
        <div className="form">
          <div className="fields">
            <p className="title">{translate('support.title')}</p>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <div>
                <FormItem
                  hasFeedback={true}
                  label={translate('support.name.label')}
                >
                  {getFieldDecorator(
                    'name',
                    name
                  )(
                    <Input
                      maxLength={200}
                      placeholder={translate('support.name.placeholder')}
                      className="input"
                    />
                  )}
                </FormItem>
                <FormItem
                  hasFeedback={true}
                  label={translate('support.email.label')}
                >
                  {getFieldDecorator(
                    'email',
                    email
                  )(
                    <Input
                      maxLength={100}
                      placeholder={translate('support.email.placeholder')}
                      className="input"
                    />
                  )}
                </FormItem>
                <FormItem
                  hasFeedback={true}
                  label={translate('support.message.label')}
                >
                  {getFieldDecorator(
                    'message',
                    message
                  )(
                    <TextArea
                      className="support-message"
                      maxLength={2000}
                      placeholder={translate('support.message.placeholder')}
                      rows={10}
                    />
                  )}
                </FormItem>
              </div>
              <div className="action">
                <Button htmlType={'submit'} className="btn green-btn">
                  {translate('support.submit')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default view;
