import React from 'react';
import './styles.scss';
import { Button, Dropdown, Icon, Layout, Menu } from 'antd';
import Link from '@arivaa-react/components/link';
import { getCurrentRoute } from '@arivaa-react/react-router5';
import { ReactComponent as SmallLogo } from '../../../images/laxaar/small-logo.svg';
import { ReactComponent as WhiteLogo } from '../../../images/laxaar/logo-white.svg';
import { ReactComponent as Whatsapp } from '../../../images/laxaar/whatsapp.svg';
import { ReactComponent as Phone } from '../../../images/laxaar/phone.svg';
import ExpertiseDropdown from '../../../containers/expertiseDropdown';
import { isMobile, mobileAndTabletcheck } from '@arivaa-react/helpers/web';
import {
  CaretDownOutlined,
  MenuOutlined,
  MoreOutlined,
} from '@ant-design/icons';

const { Header } = Layout;
const routes = [
  {
    routeKey: 'landing',
    title: 'Home',
  },
  {
    routeKey: 'services',
    title: 'Services',
  },
  {
    title: 'Expertise',
    Component: ExpertiseDropdown,
  },
  {
    routeKey: 'portfolio',
    title: 'Portfolio',
  },
  {
    routeKey: 'products',
    title: 'Products',
  },
  {
    routeKey: 'blogs',
    title: 'Blog',
  },
  {
    routeKey: 'case-studies',
    title: 'Case Studies',
  },
  {
    routeKey: 'become-partner',
    title: 'Partner With Us',
  },
  {
    routeKey: 'enhancement',
    title: 'Feature Request',
  },
  {
    routeKey: 'careers',
    title: 'Careers',
  },
  {
    routeKey: 'faqs',
    title: 'FAQs',
  },
  {
    routeKey: 'contact',
    title: 'Contact Us',
  },
];

var view = function () {
  const { scrolled } = this.state;
  const route = getCurrentRoute();
  const { setUiProperty } = this.props;
  const menu = (
    <Menu>
      {routes.slice(6, routes.length).map(({ routeKey, title }, index) => {
        return (
          <Menu.Item key={index} className={title.replace(/\s+/g, '')}>
            <Link routeKey={routeKey}>{title}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Header
      className={
        'public-header fixed ' +
        (scrolled ? 'scrolled animated slideInDown ' : route.routeKey)
      }
    >
      <div className="section">
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="mobile-drawer">
                <Link
                  onClick={() =>
                    setUiProperty({
                      name: 'drawer',
                      value: true,
                    })
                  }
                >
                  <MenuOutlined />
                </Link>
              </div>
              <div className="logo-container">
                {window.app.ui.WEBSITELOGO ||
                window.app.ui.WEBSITELOGO_WHITE ? (
                  scrolled ? (
                    <Link routeKey="landing" className="logo">
                      <WhiteLogo />
                    </Link>
                  ) : (
                    <Link routeKey="landing" className="logo">
                      <WhiteLogo />
                    </Link>
                  )
                ) : null}
                <Link routeKey="landing" className="small-logo">
                  <SmallLogo />
                </Link>
              </div>
              <Menu
                className="menu"
                selectedKeys={route ? [route.routeKey] : null}
              >
                {routes
                  .slice(0, 6)
                  .map(({ routeKey, title, Component }, index) => {
                    return Component ? (
                      <Menu.Item key={index}>
                        <Dropdown
                          overlay={<Component />}
                          placement="bottomCenter"
                          trigger={['hover']}
                          overlayClassName="expertise-dropdown"
                        >
                          <Link>
                            {title} <CaretDownOutlined />
                          </Link>
                        </Dropdown>
                      </Menu.Item>
                    ) : (
                      <Menu.Item
                        key={routeKey}
                        className={title.replace(/\s+/g, '')}
                      >
                        <Link routeKey={routeKey}>{title}</Link>
                      </Menu.Item>
                    );
                  })}

                <Menu.Item>
                  <Dropdown
                    overlay={menu}
                    placement="bottomLeft"
                    trigger={['hover']}
                  >
                    <Button
                      className="dropdown-btn"
                      onClick={(e) => e.preventDefault()}
                    >
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </Menu.Item>
              </Menu>
            </div>
            <div className="right">
              <a
                className="phone"
                target={'_blank'}
                href={`https://wa.me/${window.app.ui.PHONE}?text=`}
              >
                <Whatsapp />

                <span className="text">
                  {(window.app.ui.PHONE || '').replace(
                    /(\d{2})(\d{5})(\d{5})/,
                    '$1-$2-$3'
                  )}
                </span>
              </a>
              <Link className="quote-btn" routeKey="quote">
                ``
                <Button className="btn yellow-btn">Get a free Quote</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};
export default view;
