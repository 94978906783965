import filesystemRouting from '@arivaa-react/helpers/filesystem-routing';
import withPageData from 'containers/with-page-data';

let obj = {};
export default obj;

/**
 * Import all the routes in pages directory
 */
filesystemRouting(obj, {
  /**
   * Both need  to be passed through directory
   */
  context: require.context('./', true, /\.js$/),
  fileRequire: function (name) {
    return require(`${name}`);
  },
  wrapComponent: withPageData,
});
