import React from 'react';
import './styles.scss';
import { Row, Col } from 'antd';
import PortfolioDiamond from '../../images/laxaar/portfolio-diamond-background.svg';
import Link from '@arivaa-react/components/link';
import arrow from '../../images/laxaar/arrow.svg';
import TagList from '../tag-list';
import Rating from '../rating';
/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ tags, title, image, testimonial, shortDescription, id }) => {
  return (
    <Link
      routeKey={'project'}
      routeParams={{
        key: id,
      }}
    >
      <div className="portfolio-card">
        <div className="bg">
          <img loading="lazy" alt="image" src={PortfolioDiamond} />
        </div>
        <div className="project">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div className="info">
                <div className="tags">
                  <TagList data={tags} />
                </div>
                <p className="title">{title}</p>
                <Rating rating={testimonial?.rating} />
                <p className="description">{shortDescription}</p>
                {/* <Link> */}
                <span className="read-more">
                  <span>Read More</span>
                  <img loading="lazy" alt="image" src={arrow} />
                </span>
                {/* </Link> */}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div className="screen">
                <img loading="lazy" alt="image" src={image} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Link>
  );
};

Main.displayName = 'Portfolio Card';

export default Main;
