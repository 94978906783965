import React from 'react';
import './styles.scss';
import Footer from 'containers/footer';
import { Button, Col, Row, Skeleton } from 'antd';
import { ReactComponent as BlueLine } from '../../images/laxaar/job-details-blue-line.svg';
import { ModalTrigger } from '@arivaa-react/components/modal';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ form, pageData, loading }) => {
  const post = pageData?.post;

  return (
    <div className="screen job-details animate__animated animate__fadeIn">
      <div className="vector" />
      <div className="vector left" />
      <div className="wave blue">
        <BlueLine />
      </div>
      <div className="content">
        <div className="container">
          <div className="heading">
            <p className="primary-text">Welcome to Laxaar Careers</p>
            <p className="primary-light-text">Join Laxaar team!</p>
          </div>
          <div className="job">
            {loading ? (
              <Skeleton />
            ) : (
              post && (
                <Row gutter={48}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <div className="back">
                      <Link routeKey="careers">
                        <Button className="yellow-btn-text">
                          Go To Careers
                        </Button>
                      </Link>
                    </div>
                    <div className="info">
                      <p className="job-title">{post.title}</p>
                      <div className="item">
                        <span className="label">Job Category</span>
                        <span className="text">{post.category}</span>
                      </div>
                      <div className="item">
                        <span className="label">Job Type</span>
                        <span className="text">{post.type}</span>
                      </div>
                      <div className="item">
                        <span className="label">Location</span>
                        <span className="text">{post.location}</span>
                      </div>
                    </div>
                    <ModalTrigger
                      modalProps={{
                        title: null,
                        footer: null,
                        className: 'apply-form-modal',
                        contentProps: {
                          ...post,
                          id: post ? post.key : undefined,
                        },
                      }}
                      modalId="jobForm"
                    >
                      <div className="action">
                        <Button className="yellow-btn">Apply Now</Button>
                      </div>
                    </ModalTrigger>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <div className="image">
                      <span className="border" />
                      <img loading="lazy" alt="image" src={post.image} />
                      <span className="border yellow" />
                    </div>
                  </Col>
                </Row>
              )
            )}
          </div>
          <div
            className="details"
            dangerouslySetInnerHTML={{ __html: post && post.description }}
          ></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

//Pre process the container with Redux Plugins
export default Main;
