import React from 'react';
import './styles.scss';
import { Button, Carousel, Col, Row } from 'antd';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { GET_ENTITIES } from 'app-redux/actions';
import Link from '@arivaa-react/components/link';
import { ReactComponent as Arrow } from '../../images/laxaar/arrow.svg';
import { ReactComponent as ArrowBlue } from '../../images/laxaar/arrow-blue.svg';
import { ReactComponent as CtaLowerWave } from '../../images/laxaar/cta-lower-wave.svg';
import { ReactComponent as CtaUpperWave } from '../../images/laxaar/cta-upper-wave.svg';
import { ReactComponent as NextIcon } from '../../images/laxaar/next.svg';
import { ReactComponent as NextDarkIcon } from '../../images/laxaar/next-dark.svg';
import { ReactComponent as PreviousIcon } from '../../images/laxaar/previous.svg';
import { ReactComponent as PreviousDarkIcon } from '../../images/laxaar/prev-dark.svg';
import { ReactComponent as PortfolioLowerWave } from '../../images/laxaar/portfolio-lower-wave.svg';
import { ReactComponent as PortfolioUpperWave } from '../../images/laxaar/portfolio-upper-wave.svg';
import { ReactComponent as ProductsLowerWave } from '../../images/laxaar/products-lower-wave.svg';
import { ReactComponent as ProductsUpperWave } from '../../images/laxaar/products-upper-wave.svg';
import { ReactComponent as Wave } from '../../images/laxaar/hero-section-lower-wave.svg';
import HighlightCard from '../../components/highlight-card';
import PriceCard from '../../components/price-card';
import HeroGraphic from '../../images/laxaar/hero-mobile-graphic.png';
import ServiceCard from '../../components/service-card';
import PortfolioCard from '../../components/portfolio-card';
import TestimonialCard from '../../components/testimonial-card';
import ProductCard from '../../components/product-card';
import BlogCard from '../../components/blog-card';
import Footer from '../../containers/footer';
import { getCarouselArrowsWithoutError } from '../../helpers/carousel';

const NextDark = getCarouselArrowsWithoutError(NextDarkIcon);
const Next = getCarouselArrowsWithoutError(NextIcon);
const PreviousDark = getCarouselArrowsWithoutError(PreviousDarkIcon);
const Previous = getCarouselArrowsWithoutError(PreviousIcon);
const CarouselComponent = ({ items, ItemComponent, darkArrows }) => {
  return items.length > 0 ? (
    <Carousel
      effect="fade"
      arrows={true}
      autoplay={true}
      nextArrow={darkArrows ? <NextDark /> : <Next />}
      prevArrow={darkArrows ? <PreviousDark /> : <Previous />}
    >
      {items.map((item, index) => {
        return (
          <div key={index}>
            <ItemComponent {...item} id={item.key} />
          </div>
        );
      })}
    </Carousel>
  ) : null;
};

const Main = ({ pageData }) => {
  const {
    testimonial = [],
    product = [],
    caseStudies = [],
    portfolio = [],
    services = [],
    blogs = [],
  } = pageData || {};
  return (
    <div className="landing ">
      {/* <HighlightCard
        title="Laxaar uses Cookies"
        description="We use cookies and other data for a number of reasons, such as keeping our reliable and secure, personalising content and ads, providing social media features and to analyse how our Sites are used."
      />  */}
      <div className="hero-section">
        <div className="container">
          <div className="content animate__animated animate__fadeIn">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <div className="branding">
                  <p className="title">Transforming</p>
                  <p className="yellow-text">Ideas into Great Products</p>
                  <p className="description">
                    Laxaar provides next-generation services in the design and
                    development of scalable software solutions turning your
                    ideas into reality.
                  </p>
                  <div className="action">
                    <Link className="link" routeKey="quote">
                      <span className="text">Let's Talk</span>
                      <Arrow />
                    </Link>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                className="hero-graphic"
              >
                <div className="graphic">
                  {/*<Mobile />*/}
                  <img src={HeroGraphic} alt="image" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="wave">
          <Wave />
        </div>
      </div>

      <div className="section services-cards">
        <div className="container">
          <p className="section-title">Covering all Your Business Needs</p>
          <p className="section-description">
            A team of dedicated and experienced software engineers with seasoned
            management skills and in-depth knowledge of the latest software
            methodologies. We know how to create production-ready software
            products.
          </p>
          <div className="list">
            <Row gutter={24}>
              {services.map((item, index) => {
                return (
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                    <ServiceCard {...item} />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>

      <div className="section portfolio">
        <div className="curve upper">
          <PortfolioUpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="section-title">Industry wide Portfolio</p>
            <div className="slides">
              <CarouselComponent
                items={portfolio}
                ItemComponent={PortfolioCard}
              />
            </div>
          </div>
        </div>
        <div className="curve">
          <PortfolioLowerWave />
        </div>
      </div>

      <div className="section testimonial">
        <div className="content">
          <div className="container">
            <p className="message">
              Trusted by over 300+ clients across the globe
            </p>
            <div className="slides">
              <CarouselComponent
                items={testimonial}
                ItemComponent={TestimonialCard}
                darkArrows={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section products">
        <div className="curve upper">
          <ProductsUpperWave />
        </div>
        <div className="content">
          <div className="container">
            <div className="heading">
              <p className="section-title">Premium Prebuilt Products</p>
              <Link className="link" routeKey="products">
                <span className="text">View All</span>
                <Arrow />
              </Link>
            </div>
            <div className="list">
              <Row gutter={48}>
                {product.map((item, index) => {
                  return (
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                      <ProductCard {...item} id={item.key} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
        <div className="curve lower">
          <ProductsLowerWave />
        </div>
      </div>

      <div className="section case-studies">
        <div className="content">
          <div className="container">
            <div className="heading">
              <p className="section-title">Our Success Stories to Tell</p>
              <Link className="link" routeKey="case-studies">
                <span className="text">View All</span>
                <ArrowBlue />
              </Link>
            </div>
            <div className="list">
              <Row gutter={48}>
                {caseStudies.map((item, index) => {
                  return (
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                      <BlogCard {...item} caseStudy={true} id={item.key} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      </div>

      {blogs.length > 0 && (
        <div className="section case-studies blogs">
          <div className="vector" />
          <div className="content">
            <div className="container">
              <div className="heading">
                <p className="section-title">Our Latest Research</p>
                <Link className="link" routeKey="blogs">
                  <span className="text">View All</span>
                  <ArrowBlue />
                </Link>
              </div>
              <div className="list">
                <Row gutter={48}>
                  {blogs.map((item, index) => {
                    return (
                      <Col xs={24} sm={24} md={24} lg={8} xl={8} key={index}>
                        <BlogCard {...item} caseStudy={false} id={item.key} />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="section cta">
        <div className="curve upper">
          <CtaUpperWave />
        </div>
        <div className="content">
          <div className="container">
            <div className="list">
              <p className="title">Grow your business with Us!</p>
              <p className="description">
                Take your business to the next level with world-class User
                Experience and User Interfaces.
              </p>
              <Link routeKey="quote">
                <Button className="primary-btn">Let's Talk</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <CtaLowerWave />
        </div>
      </div>

      <Footer />
    </div>
  );
};
Main.routeProps = {
  public: true,
  breadcrumbs:false,
  getPageData: async function () {
    const requests = {
      testimonial: {
        entityName: 'testimonial',
        size: 10,
        sort: 'rating',
        search: 'featured;true;eq;boolean',
      },
      portfolio: {
        entityName: 'portfolio',
        size: 6,
        search: 'featured;true;eq;boolean',
        sort: 'createdAt',
        sortType: 'desc',
      },
      product: {
        entityName: 'product',
        size: 2,
        search: 'featured;true;eq;boolean',
        sort: 'createdAt',
        sortType: 'desc',
      },
      /*technology: {
        entityName: 'technology',
        size: 12,
      },*/
      caseStudies: {
        entityName: 'case-study',
        size: 6,
        search: 'featured;true;eq;boolean',
        sort: 'createdAt',
        sortType: 'desc',
      },
      blogs: {
        entityName: 'blog-post',
        size: 6,
        search: 'featured;true;eq;boolean',
        sort: 'createdAt',
        sortType: 'desc',
      },
      services: {
        entityName: 'service',
        all: true,
        search: [`type;Home`],
        sort: 'createdAt',
        sortType: 'asc',
      },
    };
    let output = {};

    await Promise.all(
      Object.keys(requests).map(async (key) => {
        try {
          let payload = await createAction(GET_ENTITIES, {
            ...requests[key],
          }).payload;
          payload = requests[key].all ? payload.data : payload.data.data;
          output[key] = payload;
        } catch (e) {
          console.error('Error while getting data for landing page', {
            e,
            key,
          });
        }
      })
    );
    return output;
  },
  title: 'Developing Cutting Edge Software Solutions For Your Business',
};
Main.url = '/';
export default Main;
