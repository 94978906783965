import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Button } from 'antd';
import Link from '@arivaa-react/components/link';
import { getRouteByPath } from '@arivaa-react/react-router5';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({
  image,
  title,
  price,
  productUrl,
  productIcon,
  description,
  purchaseUrl,
  ...props
}) => {
  const route = getRouteByPath(`/product${productUrl}`);
  const [html, sethtml] = useState('');
  useEffect(() => sethtml(description), []);
  return (
    <Link
      routeKey={route ? route.routeKey : null}
      href={route ? undefined : productUrl}
      target={route ? undefined : '_blank'}
    >
      <div className="product-card">
        <div className="product-banner">
          <img loading="lazy" alt="image" src={image} />
        </div>
        <div className="info">
          <p className="title">{title}</p>
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          ></p>
          <div className="bottom">
            <div className="logo">
              <img loading="lazy" alt="image" src={productIcon} />
            </div>
            <Link href={purchaseUrl} target={'_blank'}>
              <Button className="primary-light-btn">Learn More</Button>
            </Link>
          </div>
        </div>
      </div>
    </Link>
  );
};

Main.displayName = 'Product-Card';

export default Main;
