import React from 'react';
import './styles.scss';
import { Button, Icon } from 'antd';
import Link from '@arivaa-react/components/link';
import { ReactComponent as Dot } from '../../images/laxaar/dot.svg';
import { ModalTrigger } from '@arivaa-react/components/modal';
import { EnvironmentOutlined } from '@ant-design/icons';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = (props) => {
  const {
    title,
    shortDescription,
    type,
    location,
    createdAt,
    category,
    id,
  } = props;
  return (
    <div className="career-card">
      <p className="title">{title}</p>
      <p className="category">{shortDescription}</p>
      <div className="others">
        <div className="highlights">
          <ul>
            <li>
              <div className="item">
                <span className="dot">
                  <Dot />
                </span>
                <span className="text">{type}</span>
              </div>
            </li>
            <li>
              <div className="item">
                <span className="dot">
                  <Dot />
                </span>
                <span className="text">{category}</span>
              </div>
            </li>
          </ul>
        </div>
        {/*<p className="timestamp">{moment(createdAt).fromNow()}</p>*/}
      </div>
      <p className="location">
        <EnvironmentOutlined /> {location}
      </p>

      <div className="actions">
        <ModalTrigger
          modalProps={{
            title: null,
            footer: null,
            className: 'apply-form-modal',
            contentProps: {
              ...props,
            },
          }}
          modalId="jobForm"
        >
          <Button className="yellow-btn">Apply Now</Button>
        </ModalTrigger>
        <Link routeKey="job-details" routeParams={{ key: id }}>
          <Button className="yellow-btn-text">View Details</Button>
        </Link>
      </div>
    </div>
  );
};

Main.displayName = 'Career Card';

export default Main;
