import React from 'react';
import './styles.scss';
import { Col, Row } from 'antd';
import Link from '../../@arivaa-react/components/link';
import { ReactComponent as Facebook } from '../../images/laxaar/fb.svg';
import { ReactComponent as Github } from '../../images/laxaar/github.svg';
import { ReactComponent as LinkedIn } from '../../images/laxaar/linkedin.svg';
import { ReactComponent as LowerWave } from '../../images/laxaar/contact-us-lower-wave.svg';
import { ReactComponent as Mail } from '../../images/laxaar/mail.svg';
import { ReactComponent as Phone } from '../../images/laxaar/phone.svg';
import { ReactComponent as Twitter } from '../../images/laxaar/tw.svg';
import { ReactComponent as UpperWave } from '../../images/laxaar/contact-us-upper-wave.svg';
import { ReactComponent as WaveRight } from '../../images/laxaar/contact-wave-right.svg';
import ContactForm from '../../containers/contactForm';
import SocialLinks from 'components/social-links';
/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = () => {
  return (
    <div className="contact-section">
      <div className="curve upper">
        <UpperWave />
      </div>
      <div className="content">
        <div className="container">
          <p className="section-title">Contact Us</p>
          <div className="details">
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={10}>
                <div className="info">
                  <p className="title">Contact Information</p>
                  <p className="message">
                    Fill up the form and our team will get back to you within 24
                    hours.
                  </p>
                  <ul>
                    <li>
                      <a
                        className="phone item"
                        href={`tel:${window.app.ui.PHONE}`}
                      >
                        <Phone />
                        <span className="text">
                          {' '}
                          {(window.app.ui.PHONE || '').replace(
                            /(\d{2})(\d{5})(\d{5})/,
                            '$1-$2-$3'
                          )}
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="item"
                        href={`mailto:${window.app.ui.CONTACT_EMAIL}`}
                      >
                        <Mail />
                        <span className="text">
                          {window.app.ui.CONTACT_EMAIL}
                        </span>
                      </a>
                    </li>
                    {/* <li>
                      <p className="item">
                        <Location />
                        <span className="text">
                          Chitkara Innovation Incubator, Panchkula
                        </span>
                      </p>
                    </li> */}
                  </ul>
                  <SocialLinks />
                  <div className="contact-wave">
                    <WaveRight />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={14}>
                <div className="form">
                  <div className="contact-wave">
                    <WaveRight />
                  </div>
                  <ContactForm showLabels />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="curve lower">
        <LowerWave />
      </div>
    </div>
  );
};

Main.displayName = 'Contact Section';

export default Main;
