import React from 'react';
import './styles.scss';

import { Carousel } from 'antd';
import { ReactComponent as NextDarkIcon } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDarkIcon } from '../../../images/laxaar/arrow-fill-prev.svg';
import { getCarouselArrowsWithoutError } from '../../../helpers/carousel';

const NextDark = getCarouselArrowsWithoutError(NextDarkIcon);
const PreviousDark = getCarouselArrowsWithoutError(PreviousDarkIcon);

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ post }) => {
  const images = [
    ...(post && post.image ? [post.image] : []),
    ...((post && post.additionalImages) || []),
  ];

  return post ? (
    <div className="carousel">
      <Carousel
        effect="fade"
        customPaging={(i) => (
          <a>
            <img loading="lazy" alt="image" src={images[i]} />
          </a>
        )}
        slidesToScroll={1}
        slidesToShow={1}
        speed={500}
        dotsClass="slick-dots slick-thumb"
        dots
        infinite={false}
        arrows={true}
        nextArrow={<NextDark />}
        prevArrow={<PreviousDark />}
      >
        {images.map((item, index) => {
          return (
            <div className="slide" key={index}>
              <img loading="lazy" alt="image" src={item} />
            </div>
          );
        })}
      </Carousel>
    </div>
  ) : null;
};

Main.displayName = 'Image-Carousel';

export default Main;
