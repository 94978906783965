import React from 'react';
import './styles.scss';

import { Button, Col, Row } from 'antd';
import TagList from '../../../components/tag-list';
import moment from 'moment';
import Link from '@arivaa-react/components/link';

const Main = ({ post }) => {
  const credentials = post.credentials || [];
  return (
    <div className="other-info">
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="credentials">
            <p className="label">Project Glossary</p>
            <div className="details">
              {post?.caseStudy?.key && (
                <Link
                  routeKey={'case-study'}
                  routeParams={{ key: post?.caseStudy?.key }}
                  key={'case-study'}
                >
                  <Button className="yellow-btn">View Case Study</Button>
                </Link>
              )}
              {credentials.map((item, index) => {
                const key = item.split(':')[0];
                const value = item.split(':').slice(1).join(':');
                return key.toLowerCase() === 'link' ? (
                  <a
                    href={value}
                    target={'_blank'}
                    key={index}
                    aria-label="Visit Project"
                  >
                    <Button className="yellow-btn">Visit Project</Button>
                  </a>
                ) : (
                  <div className="credentials" key={index}>
                    <p className="label">{key}</p>
                    <span className="value">{value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="information">
            <p className="label">Information</p>
            <div className="details">
              {post?.category?.title && (
                <p className="item">
                  <span className="text">Category - </span>
                  <span className="value">{post.category.title}</span>
                </p>
              )}

              <p className="item">
                <span className="text">Date - </span>
                <span className="value">
                  {moment(post.projectDate).format('MMM DD, YYYY')}
                </span>
              </p>
              {/* {post.price && (
                <p className="item">
                  <span className="text">Budget - </span>
                  <span className="value">{post.price}</span>
                </p>
              )} */}
              <p className="item">
                <span className="text">Tags - </span>
                <span className="value">
                  <TagList data={post.tags} />
                </span>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
Main.displayName = 'Other-Informatio ';

export default Main;
