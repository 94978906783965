import withPostList from 'containers/with-post-list';
import BlogCard from '../../components/blog-card';
import { getMetaTag } from '../../helpers/seo';
const Main = withPostList({
  entityName: 'case-study',
  title: 'Interesting',
  subTitle: 'Case Studies',
  requestParams: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  CardComponent: (props) => <BlogCard {...props} caseStudy={true} />,
  classNames: {
    page: 'portfolio products animate__animated animate__fadeIn',
  },
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Case Studies on Cutting Edge Websites and Mobile App Projects',
        true
      );
    },
    priority: 1.0,
  },
  showFilters: false,
});
Main.displayName = 'Case-Studies';

export default Main;
