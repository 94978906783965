import React from 'react';
import './styles.scss';
import star from '../../images/laxaar/star.svg';
/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ rating = 0 }) => {
  return (
    <div className="rating">
      <ul>
        {Array(rating)
          .fill(1)
          .map((item, index) => (
            <li key={index}>
              <img loading="lazy" alt="image" src={star} />
            </li>
          ))}
      </ul>
    </div>
  );
};

Main.displayName = 'Tag-List';

export default Main;
