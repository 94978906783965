/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
// import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/logo.png';
import section1 from './assets/section1.png';
import section2 from './assets/section2.png';
import section3 from './assets/section3.png';
import section4 from './assets/section4.png';

import screenshot1 from './assets/screenshot1.jpg';
import screenshot2 from './assets/screenshot2.jpg';
import screenshot3 from './assets/screenshot3.jpg';
import screenshot4 from './assets/screenshot4.jpg';
import screenshot5 from './assets/screenshot5.jpg';
import screenshot6 from './assets/screenshot6.jpg';
import { getMetaTag } from '../../../helpers/seo';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
  ];
  return (
    <div className="public-page product project-management-app">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <p className="title">
                  Superpage - Project/Task Management Application
                </p>
                <p className="description">
                  A fully customizable solution tailored to enhance productivity
                  and streamline workflows for your users. Ideal for businesses
                  of all sizes, TaskMaster Pro integrates advanced features for
                  task scheduling, team collaboration, and progress tracking.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section1} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <Icon type="compass" />
                </div>
                <div className="text">
                  <p className="title">Custom Dashboards</p>
                  <p className="description">
                    Create customizable dashboards that display key project
                    metrics, task statuses, and team performance indicators to
                    keep stakeholders informed.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <Icon type="file-search" />
                </div>
                <div className="text">
                  <p className="title">Automated Task Reminders</p>
                  <p className="description">
                    Automatically send reminders and notifications for upcoming
                    deadlines, pending approvals, and other critical task
                    updates to keep the project on track.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <Icon type="customer-service" />
                </div>
                <div className="text">
                  <p className="title">Onsite Deployment </p>
                  <p className="description">
                    For smooth business transition, our team will deploy the
                    system onto your chosen infrastructure at no extra cost.
                    Also, our Customer Support team is here to assist you even
                    after going live.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section2} alt="image" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Maximized Efficiency with Streamlined Task Handling
                </p>
                <p className="description">
                  Users can reduce their manual effort and enhance productivity
                  by managing hundreds of tasks simultaneously. Sharepage
                  enables quick assignment, tracking, and completion of tasks,
                  freeing up your time for high-impact activities.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Get in touch</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Enhanced Task Management with Specialized Features
                </p>
                <p className="description">
                  Refine your users' project management strategy with advanced
                  targeting and customization options. Let them set task
                  priorities, allocate resources efficiently, and define
                  deadlines to ensure optimal workflow.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Got Questions?</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section3} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section4} alt="image" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Drive Results with Tailored Task Assignments
                </p>
                <p className="description">
                  Users can create and assign tasks with precision using
                  customizable templates and detailed project briefs. They can
                  tailor tasks to specific team members' strengths and skill
                  sets, maximizing efficiency and output.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Let's get started?</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">
              Consistent User Experience with a Unified Interface
            </p>
            <p className="description">
              Give your users a consistent and intuitive user experience with
              Sharepage's unified web interface. The user-friendly design
              ensures easy navigation and accessibility, allowing your users to
              manage tasks and projects effortlessly.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image" />
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <div className="card-container">
                <ContactCard title="Looking for something similar?" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave blue">
          <ProductBlueLine />
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequently Asked Questions (FAQs)</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="Is there any recurring license cost e.g any yearly license cost to this product?"
                  key="1"
                >
                  <p>
                    There is no recurring license cost. There is just one time
                    license cost of 3000$ for using the system lifetime for your
                    business
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system? " key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system? "
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag(
        'title',
        'Superpage - Project/Task Management Application',
        true
      ),
      ...getMetaTag(
        'description',
        'A fully customizable solution tailored to enhance productivity and streamline workflows for your users. Ideal for businesses of all sizes, TaskMaster Pro integrates advanced features for task scheduling, team collaboration, and progress tracking.',
        true
      ),
      ...getMetaTag('image', section1, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'Superpage - Project/Task Management Application',
      },
    ];
  },
};

Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
