import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import { ReactComponent as TwoArrows } from '../../../images/laxaar/two-arrows.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/smartservice-logo.svg';
import smartserviceHero from './assets/smartservice-hero.png';
import smartserviceHome from './assets/smartservice-homepage.png';
import smartserviceDoubleScreens from './assets/smartservice-double-screens.png';
import smartserviceThreeScreens from './assets/smartservice-three-screens.png';

import screenshot1 from './assets/screenshot1.png';
import screenshot2 from './assets/screenshot2.png';
import screenshot3 from './assets/screenshot3.png';
import screenshot4 from './assets/screenshot4.png';
import screenshot5 from './assets/screenshot5.png';
import screenshot6 from './assets/screenshot6.png';
import screenshot7 from './assets/screenshot7.png';
import screenshot8 from './assets/screenshot8.png';
import screenshot9 from './assets/screenshot9.png';

import mobile1 from './assets/mobile-app/mobile-screenshot1.png';
import mobile2 from './assets/mobile-app/mobile-screenshot2.png';
import mobile3 from './assets/mobile-app/mobile-screenshot3.png';
import mobile4 from './assets/mobile-app/mobile-screenshot4.png';
import mobile5 from './assets/mobile-app/mobile-screenshot5.png';
import mobile6 from './assets/mobile-app/mobile-screenshot6.png';
import mobile7 from './assets/mobile-app/mobile-screenshot7.png';
import mobile8 from './assets/mobile-app/mobile-screenshot8.png';
import mobile9 from './assets/mobile-app/mobile-screenshot9.png';
import mobile10 from './assets/mobile-app/mobile-screenshot10.png';
import mobile11 from './assets/mobile-app/mobile-screenshot11.png';
import mobile12 from './assets/mobile-app/mobile-screenshot12.png';

import { openChat } from '../../../helpers/chat';
import { getMetaTag } from '../../../helpers/seo';
import {
  AlertOutlined,
  CaretRightOutlined,
  DeploymentUnitOutlined,
  SafetyOutlined,
} from '@ant-design/icons';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
  ];

  const mobileMockups = [
    mobile1,
    mobile2,
    mobile3,
    mobile4,
    mobile5,
    mobile6,
    mobile7,
    mobile8,
    mobile9,
    mobile10,
    mobile11,
    mobile12,
  ];

  const text = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices 
arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus 
ligula. Facilisis ultrices arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi 
sed facilisi sem id purus ligula. Facilisis ultrices arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur 
adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices arcu, viverra in interdum. 
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices 
arcu, viverra in interdum
`;
  return (
    <div className="public-page product smart-service">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="image"/>
                </div>
                <p className="title">
                  Solutions at Your Fingertips - On-Demand, On the Go!
                </p>
                <p className="description">
                  Obtain a SmartService to offer, manage or customize your
                  on-demand service business. Whether you're offering cleaning,
                  repairs, or other household tasks, this solution ensures
                  seamless operations and scalability, making it effortless to
                  cater to your customers' needs with reliability and ease.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={smartserviceHero} alt="image"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <SafetyOutlined />
                </div>
                <div className="text">
                  <p className="title">Secure Payments</p>
                  <p className="description">
                    Provide your users with safe & secure transactions along
                    with multiple payment options to choose from.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <AlertOutlined />
                </div>
                <div className="text">
                  <p className="title">Service Tracking</p>
                  <p className="description">
                    Users can track the status of service requests in real-time
                    for enhanced convenience.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <DeploymentUnitOutlined />
                </div>
                <div className="text">
                  <p className="title">Onsite Deployment</p>
                  <p className="description">
                    For smooth business transition, our team will deploy the
                    system onto your chosen infrastructure at no extra cost.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={smartserviceDoubleScreens} alt="image"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  The all in one on-demand service platform
                </p>
                <p className="description">
                  With SmartService the users benefit from access to their
                  service history, insightful analytics and reporting tools, and
                  multi-platform, multi-language support. Customization options
                  cater to diverse needs, and a feedback mechanism promotes
                  continuous improvement.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Get in touch</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Perfect Service Provider Based Preferences
                </p>
                <p className="description">
                  With SmartSerivce's advanced search filters and geolocation
                  services, finding the right service provider is easier than
                  ever. The users can simply input their preferences, and let
                  the app do the rest. Whether they need a plumber, electrician,
                  cleaner, or any other service, SmartService connects them with
                  nearby, qualified professionals in just a few taps.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Got Questions?</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={smartserviceHome} alt="image"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">Trust and Security Guaranteed</p>
            <p className="description">
              All service providers on SmartService are vetted and verified for
              quality and reliability. Users can leave honest reviews and
              ratings to help other users make informed decisions, creating a
              trusted community of homeowners and service providers alike.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image"/>
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image"/>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title="Looking for something similar?" />
            </Col>
          </Row>
        </div>
        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={smartserviceThreeScreens} alt="image"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Best Design Choices Integrated With Modern Standards
                </p>
                <p className="description">
                  SmartService offers a unified user experience across web and
                  mobile platforms, ensuring smooth navigation and accessibility
                  for all users. Our user-friendly design makes it easy to find
                  and book home services, allowing users to focus on what
                  matters most.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Let's get started?</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="wave blue">
          <ProductBlueLine />
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequently Asked Questions (FAQs)</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="How do you ensure the safety and security of users?"
                  key="1"
                >
                  <p>
                    We implement various safety measures such as background
                    checks for service providers, secure payment processing,
                    user verification, encrypted communication, and customer
                    support systems.
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system? " key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system? "
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag(
        'title',
        'SmartService | On-Demand Service Booking Application',
        true
      ),
      ...getMetaTag(
        'description',
        'SmartServices offers a manageable, scalable on-demand service platform with secure payments, real-time tracking, onsite deployment, and vetted providers. Users enjoy easy booking, insightful analytics, and continuous improvements.',
        true
      ),
      ...getMetaTag('image', smartserviceHero, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'SmartService | On-Demand Service Booking Application',
      },
    ];
  },
};
Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
