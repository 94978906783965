import React, { Component, useRef } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Form, Button, Input, Icon, message } from 'antd';
import { createAction } from '@arivaa-react/redux';
import { CREATE_ENTITY } from 'app-redux/actions';

const { TextArea } = Input;
const { Item } = Form;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({
  inline,
  showLabels,
  form,
  getExtraFields,
  inputProps,
  translate,
  submitEnquiry,
  extra,
  formProps,
  itemProps = {},
  dataFormatter,
}) => {
  const contactFormRef = useRef(null);
  const { getFieldDecorator } = form;
  inputProps = inputProps || {};
  const extraFields =
    getExtraFields instanceof Function ? getExtraFields({ form }) : null;
  const validations = {
    email: {
      rules: [
        { required: true, message: 'Please enter your email' },
        { type: 'email', message: 'Please enter a valid email' },
      ],
    },
    message: {
      rules: [
        {
          required: true,
          message: `Please enter the ${itemProps?.message?.label || 'message'}`,
        },
      ],
    },
    name: {
      rules: [{ required: true, message: 'Please enter your name' }],
    },
  };
  /**
   * On Submit of  Form
   * @param event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { validateFieldsAndScroll, resetFields } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(contactFormRef.current);
      values = { ...values };
      try {
        if (dataFormatter instanceof Function) {
          values = await dataFormatter(values);
        }

        const { name, email, message: yourMessage, ...otherInfo } = values;
        const { error, payload } = await submitEnquiry({
          name,
          email,
          message: yourMessage,
          page: window.location.href,
          ...extra,
          otherInfo,
        });
        if (error) {
          throw payload.response;
        }
        resetFields();
        message.success(translate('support.success'));
      } catch (e) {
        message.error(translate('support.error'));
        console.error(e);
      }
      window.stopSpinning(contactFormRef.current);
    });
  };
  const Name = (
    <Item
      hasFeedback={true}
      label={showLabels ? 'Name' : ''}
      {...itemProps.name}
    >
      {getFieldDecorator(
        'name',
        validations.name
      )(<Input placeholder={'Name'} className="input" {...inputProps.name} />)}
    </Item>
  );
  const Email = (
    <Item
      hasFeedback={true}
      label={showLabels ? 'Email' : ''}
      {...itemProps.email}
    >
      {getFieldDecorator(
        'email',
        validations.email
      )(
        <Input placeholder={'Email'} className="input" {...inputProps.email} />
      )}
    </Item>
  );
  return (
    <div className="contact-form">
      <Form {...formProps} onSubmit={handleSubmit}>
        <div className="fields" ref={contactFormRef}>
          {inline ? (
            <div className="inline">
              {Name}
              {Email}
            </div>
          ) : (
            <React.Fragment>
              {Name}
              {Email}
            </React.Fragment>
          )}
          <Item
            hasFeedback={true}
            label={showLabels ? 'Phone Number' : ''}
            {...itemProps.phone}
          >
            {getFieldDecorator(
              'phone',
              validations.phone
            )(
              <Input
                placeholder={'Phone Number'}
                className="input"
                {...inputProps.phone}
              />
            )}
          </Item>
          {extraFields}
          <Item
            hasFeedback={true}
            label={showLabels ? 'Message' : ''}
            {...itemProps.message}
          >
            {getFieldDecorator(
              'message',
              validations.message
            )(<TextArea placeholder="Your Message" {...inputProps.message} />)}
          </Item>
        </div>
        <div className="action">
          <Button htmlType="submit" className="yellow-btn">
            Send Message
          </Button>
        </div>
      </Form>
    </div>
  );
};

Main.displayName = 'Contact';
/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    submitEnquiry: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'contact',
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ emitter, ui }) => {
  return {
    emitter,
    ui,
  };
};
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
  withRouter: true,
});
