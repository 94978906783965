import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import { ReactComponent as TwoArrows } from '../../../images/laxaar/two-arrows.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/logo.svg';
import section1 from './assets/section1.png';
import section2 from './assets/section2.png';
import section3 from './assets/section3.png';
import section4 from './assets/section4.png';

import screenshot1 from './assets/screenshot1.jpg';
import screenshot2 from './assets/screenshot2.jpg';
import screenshot3 from './assets/screenshot3.jpg';
import screenshot4 from './assets/screenshot4.jpg';
import screenshot5 from './assets/screenshot5.jpg';
import screenshot6 from './assets/screenshot6.jpg';
import screenshot7 from './assets/screenshot7.jpg';
import screenshot8 from './assets/screenshot8.jpg';
import screenshot9 from './assets/screenshot9.jpg';
import screenshot10 from './assets/screenshot10.jpg';
import screenshot11 from './assets/screenshot11.jpg';
import screenshot12 from './assets/screenshot12.jpg';
import screenshot13 from './assets/screenshot13.jpg';
import screenshot14 from './assets/screenshot14.jpg';
import { getMetaTag } from '../../../helpers/seo';
import {
  AppstoreOutlined,
  CaretRightOutlined,
  DeploymentUnitOutlined,
  RobotOutlined,
} from '@ant-design/icons';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10,
    screenshot11,
    screenshot12,
    screenshot13,
    screenshot14,
  ];

  const text = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices 
arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus 
ligula. Facilisis ultrices arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi 
sed facilisi sem id purus ligula. Facilisis ultrices arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur 
adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices arcu, viverra in interdum. 
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices 
arcu, viverra in interdum
`;
  return (
    <div className="public-page product chatbot-application">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="image" />
                </div>
                <p className="title">ChatAssist - ChatBot Application</p>
                <p className="description">
                  ChatAssist revolutionizes task management by offering
                  automated assistance for various tasks. From scheduling
                  appointments to organizing your to-do list, ChatAssist
                  simplifies the users' workload, allowing them to focus on more
                  critical aspects of their day.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section1} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <AppstoreOutlined />
                </div>
                <div className="text">
                  <p className="title">Multi-Platform Integration</p>
                  <p className="description">
                    Users get to experience smooth management of interactions &
                    tasks across various platforms.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <RobotOutlined />
                </div>
                <div className="text">
                  <p className="title">Natural Language Processing</p>
                  <p className="description">
                    NLP enables the chatbot to understand, interpret, and
                    respond to human language.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <DeploymentUnitOutlined />
                </div>
                <div className="text">
                  <p className="title">Onsite Deployment</p>
                  <p className="description">
                    For easy business transition, our team will deploy the
                    system onto your chosen infrastructure at no extra cost.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section2} alt="image" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Effortless Task Management at Your Fingertips
                </p>
                <p className="description">
                  Farewell to manual task management with ChatAssist. With just
                  a few clicks, users can delegate tasks, set reminders, and
                  prioritize assignments, saving them precious time and energy.
                  It lets the users experience easy task execution and witness a
                  boost in productivity like never before.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Get in touch</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Advanced Customization For Desired Outputs
                </p>
                <p className="description">
                  Users can customize their task management experience with
                  ChatAssist's specialized features. It lets them set task
                  priorities, deadlines, and dependencies to streamline workflow
                  and ensure efficient task completion. With intelligent task
                  categorization and filtering, the users can focus on what
                  matters most, leaving mundane tasks to ChatAssist.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Get in touch</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section3} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section4} alt="image" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Personalized Assistance for Enhanced Efficiency
                </p>
                <p className="description">
                  ChatAssist utilizes advanced algorithms to analyze the users'
                  task histories and preferences, generating personalized
                  recommendations and suggestions to optimize their workflow.
                  From generating reports to providing insightful analytics,
                  ChatAssist empowers people to achieve your goals efficiently.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Let's get started?</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">
              Seamless Integration for a Unified Task Management Experience
            </p>
            <p className="description">
              Users can enjoy a unified task management experience across all
              devices with ChatAssist's seamless integration capabilities.
              Whether they're on your desktop, tablet, or smartphone, ChatAssist
              ensures consistent functionality and accessibility, enabling them
              to stay productive on the go. With an intuitive interface and
              user-friendly design, ChatAssist simplifies task management for
              users of all levels.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image" />
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title="Looking for something similar?" />
            </Col>
          </Row>
        </div>

        <div className="wave blue">
          <ProductBlueLine />
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequently Asked Questions (FAQs)</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="What customization options are available in Connecty?"
                  key="1"
                >
                  <p>
                    In Connecty, we offer a variety of customization options to
                    meet the diverse needs of our users. It includes branding
                    customization, theme customization, widget customization,
                    language localization, and many more.
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system?" key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system?"
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag('title', 'ChatAssist - ChatBot Application', true),
      ...getMetaTag(
        'description',
        'ChatAssist revolutionizes task management by offering automated assistance for various tasks. From scheduling appointments to organizing your to-do list, ChatAssist simplifies the users workload, allowing them to focus on more critical aspects of their day.',
        true
      ),
      ...getMetaTag('image', section1, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'ChatAssist - ChatBot Application',
      },
    ];
  },
};

Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
