import * as api from 'client/constants/api';

export const GET_CONFIGS = 'GET_CONFIGS';
export const SAMPLE_ACTION = 'SAMPLE_ACTION';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FORGOT = 'FORGOT';
export const REGISTER = 'REGISTER';
export const UI_SET_PROPERTY = 'UI_SET_PROPERTY';
export const UI_DELETE_PROPERTY = 'UI_DELETE_PROPERTY';
export const GET_PROFILE = 'GET_PROFILE';
export const CHECK_DUPLICATE_EMAIL = 'CHECK_DUPLICATE_EMAIL';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const TOKEN_VALIDATE = 'TOKEN_VALIDATE';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const RESEND = 'RESEND';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const RESET = 'RESET';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const GET_ENTITIES = 'GET_ENTITIES';
export const CREATE_ENTITY = 'CREATE_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const GET_ENTITY = 'GET_ENTITY';
export const RESET_ENTITY = 'RESET_ENTITY';
export const SEND_NOTIFICATIONS = 'SEND_NOTIFICATIONS';
export const REFRESH_INDEX = 'REFRESH_INDEX';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const API_REQUEST = 'API_REQUEST';
export const DUPLICATE_ENTITY = 'DUPLICATE_ENTITY';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

/**
 * Delete client fields from
 * data to be sent to server
 * @param data
 * @returns {*}
 */
function filterData(data) {
  data = {
    ...data,
  };
  delete data.entityId;
  delete data.entityName;
  delete data.url;
  return data;
}

/**
 * Get Entity URl
 * @param entityName
 * @param url
 * @returns {string}
 */
export function getEntityUrl({ entityName, url, entityId }) {
  let output = api['SERVER'];
  if (entityName) {
    output += `${entityName}/`;
  }
  if (entityId) {
    output += `${entityId}/`;
  }
  if (url) {
    output += `${url}/`;
  }
  output = output.replace(/\/(?=[^\/]*$)/, '');
  return output;
}

/**
 * Actions configuration -
 * All actions are configured here
 * Configuration Schema - {
 *  name - Action Name
 *  type - Type of Action - ajax or normal ->
 *  config - {
 *      url => For Ajax
 *      method => For Ajax
 *      headers => For Ajax
 *      promise => For normal action -> if we want data to be resolved as dummy promise
 *      getData => General -> If we want to customize the data -> Input data is passed as argument
 *      getParams => For Ajax -> If we want to get params out of input data
 *  }
 * }
 * @type {[*]}
 */
export default [
  {
    name: GET_DASHBOARD,
    type: 'ajax',
    config: {
      url: api.DASHBOARD,
      method: 'GET',
    },
  },
  {
    name: API_REQUEST,
    type: 'ajax',
    config: {
      getUrl: (data) => {
        const url = data.url;
        delete data.url;
        return url;
      },
      getMethod: (data) => {
        const method = data.method;
        delete data.method;
        return method || 'GET';
      },
    },
  },
  {
    name: DUPLICATE_ENTITY,
    type: 'ajax',
    config: {
      getUrl: ({ entityName }) => api.SERVER + entityName + '/duplicate',
      getData: ({ entityName, ...data }) => data,
      method: 'POST',
    },
  },
  {
    name: REFRESH_INDEX,
    type: 'ajax',
    config: {
      url: api.REFRESH_INDEX,
      method: 'GET',
    },
  },
  {
    name: SOCIAL_LOGIN,
  },
  {
    name: REFRESH_TOKEN,
  },
  {
    name: GET_CONFIGS,
    type: 'ajax',
    config: {
      url: api.GET_CONFIGS,
      method: 'GET',
    },
  },
  {
    name: RESEND,
    type: 'ajax',
    config: {
      url: api.CONFIRM_EMAIL + '/resend',
      method: 'GET',
    },
  },
  {
    name: SAMPLE_ACTION,
    config: {
      getData: () => {
        return {
          text: 'Yo this is updated text',
        };
      },
    },
  },
  {
    name: RESEND,
    type: 'ajax',
    config: {
      url: api.CONFIRM_EMAIL + '/resend',
      method: 'GET',
    },
  },
  {
    name: SAMPLE_ACTION,
    config: {
      getData: () => {
        return {
          text: 'Yo this is updated text',
        };
      },
    },
  },
  {
    name: UI_SET_PROPERTY,
  },
  {
    name: UI_DELETE_PROPERTY,
  },
  {
    name: LOGIN,
    type: 'ajax',
    config: {
      url: api.AUTHENTICATE,
      method: 'POST',
    },
  },
  {
    name: UPDATE_CURRENT_USER,
    type: 'ajax',
    config: {
      url: api.UPDATE_CURRENT_USER,
      method: 'PATCH',
    },
  },
  {
    name: PASSWORD_RESET_REQUEST,
    type: 'ajax',
    config: {
      getUrl: ({ token }) => api.PASSWORD_RESET + '/' + token,
      getData: ({ password }) => {
        return {
          password,
        };
      },
      method: 'PATCH',
    },
  },
  {
    name: TOKEN_VALIDATE,
    type: 'ajax',
    config: {
      getUrl: (data) => api.PASSWORD_RESET + '/' + data,
      method: 'GET',
    },
  },
  {
    name: PASSWORD_RESET,
    type: 'ajax',
    config: {
      url: api.PASSWORD_RESET,
      method: 'POST',
    },
  },
  {
    name: LOGOUT,
    config: {
      promise: true,
    },
  },
  {
    name: FORGOT,
    type: 'ajax',
    config: {},
  },
  {
    name: REGISTER,
    type: 'ajax',
    config: {
      url: api.REGISTER,
      method: 'POST',
    },
  },
  {
    name: SEND_EMAIL,
    type: 'ajax',
    config: {
      url: api.SEND_EMAIL,
      method: 'POST',
    },
  },
  {
    name: CHECK_DUPLICATE_EMAIL,
    type: 'ajax',
    config: {},
  },
  {
    name: GET_PROFILE,
    type: 'ajax',
    config: {
      url: api.GET_CURRENT_USER,
      method: 'GET',
    },
  },
  {
    name: VERIFY_ACCOUNT,
    type: 'ajax',
    config: {},
  },
  {
    name: RESET_PASSWORD,
    type: 'ajax',
    config: {},
  },
  {
    name: RESET,
  },
  {
    name: RESET_PASSWORD,
    type: 'ajax',
    config: {},
  },
  {
    name: SAVE_PROFILE,
    type: 'ajax',
    config: {},
  },
  {
    name: GET_ENTITIES,
    type: 'ajax',
    config: {
      getUrl: getEntityUrl,
      method: 'GET',
      getParams: filterData,
    },
  },
  {
    name: GET_ENTITY,
    type: 'ajax',
    config: {
      getUrl: getEntityUrl,
      method: 'GET',
      getParams: filterData,
    },
  },
  {
    name: CREATE_ENTITY,
    type: 'ajax',
    config: {
      getUrl: getEntityUrl,
      method: 'POST',
      getData: filterData,
    },
  },
  {
    name: UPDATE_ENTITY,
    type: 'ajax',
    config: {
      getUrl: getEntityUrl,
      method: 'PATCH',
      getData: filterData,
    },
  },
  {
    name: DELETE_ENTITY,
    type: 'ajax',
    config: {
      getUrl: getEntityUrl,
      method: 'DELETE',
      getData: filterData,
    },
  },
  {
    name: SEND_NOTIFICATIONS,
    type: 'ajax',
    config: {
      url: api.ADMIN_ENTITIES + '/users/' + api.PUSH_NOTIFICATIONS,
      method: 'POST',
    },
  },
  {
    name: RESET_ENTITY,
  },
];
