import React from 'react';
import './styles.scss';
import { ReactComponent as ReviewWave } from '../../images/laxaar/contact-card-wave.svg';
import { Button } from 'antd';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = () => {
  return (
    <div className="business-card">
      <div className="review-wave">
        <ReviewWave />
      </div>
      <p className="title">Become our Partner</p>
      <p className="reward">
        Earn upto <span className="value">$10000</span> on each quality lead
      </p>
      <Link routeKey="become-partner">
        <Button className="btn yellow-btn">Learn More</Button>
      </Link>
    </div>
  );
};

Main.displayName = 'Contact Card';

export default Main;
