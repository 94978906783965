import { portfolioMapper } from '../../helpers/mappers';
import withPostList from 'containers/with-post-list';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { GET_ENTITIES } from 'app-redux/actions';
import './styles.scss';
import PortfolioCard from '../../components/portfolio-small-card';
import { getMetaTag } from '../../helpers/seo';
const Main = withPostList({
  entityName: 'portfolio',
  mapper: portfolioMapper,
  requestParams: {
    //populate: true,
    sort: 'createdAt',
    sortType: 'desc',
  },
  getFilters: (pageData) => {
    const { categories } = pageData || {};
    return (categories || []).map(({ title, key }) => {
      return {
        label: title,
        value: key,
      };
    });
  },
  CardComponent: PortfolioCard,
  title: 'Industry Wide',
  subTitle: 'Portfolio',
  onFilterChange: (values) => {
    const categories = Object.keys(values).filter((key) => !!values[key]);
    return {
      search:
        categories.length > 0 ? [`category;${categories.join(',')};in`] : [],
    };
  },
  getPageData: async () => {
    const { data } = await createAction(GET_ENTITIES, {
      entityName: 'portfolio-category',
      all: true,
    }).payload;
    return {
      categories: data || [],
    };
  },
  classNames: {
    page: 'portfolio animate__animated animate__fadeIn',
  },
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Best Websites and Mobile Apps on Cutting Edge technologies',
        true
      );
    },
    priority: 1.0,
  },
});
export default Main;
