import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Col, Row, Skeleton } from 'antd';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <Row gutter={48}>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <div className="card skeleton">
          <div className="card-details">
            <Skeleton paragraph={{ rows: 8 }} />
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <div className="card skeleton">
          <div className="card-details">
            <Skeleton paragraph={{ rows: 8 }} />
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <div className="card skeleton">
          <div className="card-details">
            <Skeleton paragraph={{ rows: 8 }} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'Sample-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
