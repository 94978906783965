import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';

import Footer from 'containers/footer';
import { getMetaTag } from '../../helpers/seo';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ title, html }) => {
  return (
    <div className="screen terms static-page">
      <div className="vector" />
      <div className="vector left" />
      <div className="container">
        <div className="section">
          <div className="heading">
            <p className="primary-text">{title} </p>
            {/* <p className="primary-light-text">Subtitle</p> */}
          </div>
          {/*<div className="heading">
            {title && title !== '' && (
              <div className="title">
                <p>{title}</p>
              </div>
            )}
          </div>*/}
          <div className="details">
            <div
              className="editor-content"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'Static Page';
Main.routeProps = {
  getMetaTags: (pageData) => {
    return getMetaTag('title', pageData.title, true);
  },
};
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
