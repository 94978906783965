import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Simplifying',
  subTitle: 'On-Demand Services',
  description: `We help our clients cater to their large customer bases with our digitally enhanced and scalable software solutions. We will take care of all your On-demand service needs to help you stay ahead of the curve.
`,
  services: [
    'Home Servies Suite Development',
    'Garage and Workshop Management Tools',
    'Business Rule Engines and Analytics Suites',
    'Cab Booking and Fleet Management apps',
  ],
  bannerTitle: 'Holding out for the right on-demand service provider?',
  bannerDescription: `Our on-demand services are focused on transforming your idea into a reality with the best possible technology. At Laxaar, we deliver you with a product that is market-ready and stands out among competitors. `,
  statistics: [
    {
      title: '30+',
      description: 'Websites and Apps',
    },
    {
      title: '10000+',
      description: 'Hours Worked',
    },
    {
      title: '10M+',
      description: 'Daily Data Volume',
    },
    {
      title: '1M+',
      description: 'End Users',
    },
  ],
  tags: ['On Demand', 'Home Services', 'Email Marketing'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Cutting Edge On-Demand Services Software for your Businesses',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
