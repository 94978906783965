import React, { useState } from 'react';
import './styles.scss';
import Footer from 'containers/footer';
import { Collapse, Skeleton } from 'antd';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { GET_ENTITIES } from 'app-redux/actions';
import Link from '@arivaa-react/components/link';
import { jsUcfirst } from '@arivaa-react/helpers/common';
import { getMetaTag } from '../../helpers/seo';
const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ pageData, loading }) => {
  const data = pageData?.data || [];
  const [activeCategory, setActiveCategory] = useState(undefined);
  let categories = {};
  data.forEach((item) => {
    let { category } = item;
    category = category.trim().toLowerCase();
    if (!categories[category]) {
      categories[category] = [item];
    } else {
      categories[category].push(item);
    }
  });

  return (
    <div className="screen faqs animate__animated animate__fadeIn">
      <div className="vector" />
      <div className="vector left" />
      <div className="container">
        <div className="heading">
          <p className="primary-text">Frequently Asked</p>
          <p className="primary-light-text">Questions</p>
        </div>
        <div className="filters">
          <ul>
            <li>
              <Link
                className={`filter ${activeCategory ? '' : 'active'}`}
                onClick={() => setActiveCategory(undefined)}
              >
                Show All
              </Link>
            </li>
            {Object.keys(categories).map((item, index) => (
              <li key={index} onClick={() => setActiveCategory(item)}>
                <Link
                  className={`filter ${
                    activeCategory === item ? 'active' : ''
                  }`}
                >
                  {jsUcfirst(item)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="content">
          <div className="details">
            {loading && <Skeleton />}
            {data.length > 0 &&
              (activeCategory ? [activeCategory] : Object.keys(categories)).map(
                (category) => (
                  <div className="section" key={category}>
                    <p className="title">{jsUcfirst(category)}</p>
                    <Collapse>
                      {categories[category].map(
                        ({ title, description }, index) => {
                          return (
                            <Panel header={title} key={index}>
                              <p>{description}</p>
                            </Panel>
                          );
                        }
                      )}
                    </Collapse>
                  </div>
                )
              )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Main.displayName = 'Blog';

export default Main;

Main.routeProps = {
  getPageData: async function () {
    const { data } = await createAction(GET_ENTITIES, {
      entityName: 'faq',
      all: true,
    }).payload;
    return {
      data,
    };
  },

  getMetaTags: () => {
    return getMetaTag(
      'title',
      'FAQs for Software Development Contracts | Laxaar',
      true
    );
  },
};
