import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Get in control of',
  subTitle: ' Your Infrastructure',
  description: `Even though we are in the era of cloud computing, certain business scenarios require on-premises infrastructure setup, especially with regulatory compliances like GDPR,  where the data has to be kept in that location. If you are one of them, Laxaar is here for you!`,
  services: [
    'On Premises VPS Setup and Deployment',
    'Cpanel Single/Multi Server Deployments',
    'Plesk Retail/Reseller/Admin Deployments',
    'Infrastructure discovery and optimization',
  ],
  bannerTitle: 'Want full control with a dedicated infrastructure?',
  bannerDescription: `On-Premises Setups like VPS is a more secure and stable solution than shared hosting where you don’t get a dedicated server space. Running a VPS lets you set up your website within a secure container with guaranteed resources (memory, disk space, CPU cores, etc. )`,
  statistics: [
    {
      title: '50+',
      description: 'Server Setups Till Date',
    },
    {
      title: '20+',
      description: 'Happy Clients',
    },
    {
      title: '30+',
      description: 'Daily Monitorings',
    },
    {
      title: '10+',
      description: 'Reseller Hosting Clients',
    },
  ],
  process: [
    {
      title: 'Financial Analysis',
      details: [
        'How much is the infrastructure budget?',
        'Purpose behind the infrastructure?',
        'Who are our target audience?',
        'What are the scaling expectations?',
        'Applicable regulations and compliances?',
      ],
    },
    {
      title: 'Technical Analysis',
      details: [
        'Best vendor for the infrastructure?',
        'Long term outlook for the provider?',
        'Downtime and SLA Trends ?',
        'Scaling approach of the providers?',
        'Software License Requirements?',
      ],
    },
    {
      title: 'Security Analysis',
      details: [
        'Type of services to be run?',
        'Security Risk Identification?',
        'Security Risk Analysis and Mitigation?',
        'Service Dependency Analysis?',
        'Infrastructure Architecture Planning?',
      ],
    },
    {
      title: 'Deployment Plan',
      details: [
        'Infrastructure Backlog Planning',
        'Infrastructure Planning and Discussion',
        'Security Testing Plan',
        'Stakeholder review meetings',
        'Go Live and Release Plan',
      ],
    },
  ],
  tags: ['CPanel', 'VPS', 'Plesk'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Cutting Edge Infrastructure Solutions for your Business',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
