import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Automated Software Releases',
  subTitle: 'With Cloud ',
  description: `Through continuous integration and delivery, powered by automated build and release processes, we are able to ship software releases faster. This helps clients stay on budget and cut down on the timeline that comes with manual deployments.
`,
  services: [
    'CI/CD Setup  over Github, Bitbucket and Gitlab',
    'On-Premises Pipeline Development using Jenkins',
    'On-Premises Private Code Registry Deployment',
    'Application Containerization using Docker',
  ],
  bannerTitle: 'Looking for faster release and quick fixes?',
  bannerDescription: `Integrated CI/CD services built into your infrastructure allows you to automatically build, test, and deploy your code across multiple environments. It essentially helps you manage your entire development workflow quite effortlessly.`,
  statistics: [
    {
      title: '500+',
      description: 'CI/CD Pipelines',
    },
    {
      title: '100+',
      description: 'Happy Clients',
    },
    {
      title: '10000+',
      description: 'Automated Releases',
    },
    {
      title: 'Countless',
      description: 'Hours Saved',
    },
  ],
  process: [
    {
      title: 'Infrastructure Analysis',
      details: [
        'How much is the CI/CD budget?',
        'Scope of IT Infrastructure?',
        'How many environments do we have?',
        'What are the release time expectations?',
        'Type of Code Revision Management?',
      ],
    },
    {
      title: 'Technical Analysis',
      details: [
        'Best option for Code Hosting?',
        'What would be the Git Workflow?',
        'Merge checks to be applied?',
        'Unit Testing Setup?',
        'End to End Setup?',
      ],
    },
    {
      title: 'Code Repository Analysis',
      details: [
        'Supported CI/CD Tools?',
        'Number of Projects?',
        'Repository Dependency Analysis',
        'Build Dependency Analysis',
        'Release Notification Integration',
      ],
    },
    {
      title: 'CI/CD Setup',
      details: [
        'Private Registry Setup',
        'Git Workflow Setup',
        'Branch-Environment Mapping',
        'Pipeline Development',
        'Final Testing and Go Live',
      ],
    },
  ],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Get CI/CD Guidance & Tools — Unlock True Automation with CI/CD Pipelines',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
