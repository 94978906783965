import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Top Fintech',
  subTitle: 'Software Development Services',
  description: `We provide top-notch FinTech solutions leveraging our expertise in FinTech Software development. We are here to deliver solutions that help your business scale, reach your target audience and take success rates above and beyond.
`,
  services: [
    'Loan Processing and Management Systems',
    'Blockchain, Crypto and Defi Platforms',
    'NFT Marketplace Development',
    'Online Insurance Aggregators',
  ],
  bannerTitle: 'Do you have a great FinTech Software idea?',
  bannerDescription: `We will help you turn your crypto, branking, and insurance ideas, into real-time business models with our Fintech Software Development Solutions and cutting-edge technologies. You get market-ready products that help your business scale.`,
  statistics: [
    {
      title: '10+',
      description: 'FinTech Implementations',
    },
    {
      title: '4000+',
      description: 'Hours Worked',
    },
    {
      title: '30+',
      description: 'Countries and Markets',
    },
    {
      title: '100%',
      description: 'Client Satisfaction',
    },
  ],
  tags: ['Finance', 'Crypto', 'Loans'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Innovative Software Solutions For Fintech, Blockchain, NFTs & Insurance',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
