import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Redefining',
  subTitle: 'Web Development Services',
  description: `One-stop solution to all your web development needs, Laxaar aims to deliver top quality, high-tech solutions for your website that abides by coding standards with a special focus on code reusability, future scalability, and extensibility. 
`,
  services: [
    'Website Design and Development',
    'Progressive Web App Design and Development',
    'Rest and SOAP API Development',
    'Software Architecture Consultation and Review',
  ],
  bannerTitle: 'Wondering why a customized website is crucial?',
  bannerDescription: `Your website is the center of your digital ecosystem that determines the first impression of your potential clients: make sure they are more than pleased with a website that is customized, optimized, and alive with a personality.`,
  statistics: [
    {
      title: '100+',
      description: 'Websites Developed',
    },
    {
      title: '10+',
      description: 'Progressive Web Apps',
    },
    {
      title: '15000+',
      description: 'Hours Worked',
    },
    {
      title: '50+',
      description: 'Technologies',
    },
  ],
  tags: ['MERN', 'MEAN', 'PHP', 'Python'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'High Quality Website Development for your Business',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
