import React from 'react';
import preProcess from 'containers/preprocess';
import { Form, Input, Select } from 'antd';
import ContactForm from 'containers/contactForm';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { CREATE_ENTITY } from 'app-redux/actions';
import FilePicker from '@arivaa-react/components/filePicker';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ uploadFile }) => {
  return (
    <ContactForm
      showLabels={true}
      inline={true}
      inputProps={{
        message: {
          placeholder: 'Any specific points you would like to highlight?',
        },
      }}
      getExtraFields={({ form }) => {
        const { getFieldDecorator } = form;
        return (
          <Form.Item label={'Attachment'} hasFeedback={true}>
            {getFieldDecorator('attachment')(
              <FilePicker
                uploadProps={{
                  accept:
                    '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf',
                }}
                maxFileSize={50 * 1024 * 1024}
              />
            )}
          </Form.Item>
        );
      }}
      dataFormatter={async (data) => {
        if (data.attachment) {
          data.attachment = (
            await uploadFile({
              name: data.attachment.name,
              type: data.attachment.type,
              file: data.attachment.dataUri,
              fileCode: 'mediaFile',
            })
          ).payload.data.url;
        }
        return data;
      }}
      extra={{
        category: 'Quote',
      }}
    />
  );
};
/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    uploadFile: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'storage',
          ...data,
        })
      );
    },
  };
};
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [null, bindAction],
});
