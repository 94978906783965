import { Component } from 'react';
import ComponentView from './view';
import preProcess from '../../preprocess';
import { createAction } from '@arivaa-react/redux';
import { Form } from 'antd';
import { goToRoute } from '@arivaa-react/react-router5';
import { UI_SET_PROPERTY } from 'app-redux/actions';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {
    window.addEventListener('scroll', (event) => {
      if (window.pageYOffset > 120) {
        this.setState({
          scrolled: true,
        });
      } else {
        this.setState({
          scrolled: false,
        });
      }
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    setUiProperty: (data) => {
      return dispatch(createAction(UI_SET_PROPERTY, data));
    },
  };
};

Main.displayName = 'Header-Container';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [null, bindAction],
  localize: true,
  withRouter: true,
});
