import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { addOnRouteChange, getCurrentRoute } from '@arivaa-react/react-router5';
import Link from '../../@arivaa-react/components/link';
import './styles.scss';

const hyphenCaseToCamelCase = (text) => {
  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/-/g, ' ');
};

const getBreadcrumbs = () => {
  const currentRoute = getCurrentRoute();
  if (currentRoute.getBreadcrumbs instanceof Function) {
    return currentRoute.getBreadcrumbs();
  }
  const pathnames = currentRoute.routeKey.split('.');
  const breadcrumbs = [{ label: 'Home', routeKey: 'landing' }];
  pathnames.forEach((value) => {
    const label = hyphenCaseToCamelCase(value);
    breadcrumbs.push({
      label: label,
      routeKey: currentRoute.routeKey,
    });
  });
  return breadcrumbs;
};

const Breadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState(getBreadcrumbs());
  useEffect(() => {
    addOnRouteChange(() => {
      setBreadcrumbs(getBreadcrumbs());
    });
  }, []);
  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index) => (
        <Breadcrumb.Item key={index}>
          <Link routeKey={breadcrumb.routeKey}>{breadcrumb.label}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
