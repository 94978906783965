import React from 'react';
import './styles.scss';
import { ReactComponent as Box } from '../../images/laxaar/portfolio-diamond-background.svg';
import Link from '@arivaa-react/components/link';
import arrow from '../../images/laxaar/arrow.svg';
import TagList from '../tag-list';
import Rating from '../rating';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ image, tags, title, testimonial, shortDescription, id }) => {
  return (
    <Link
      routeKey={'project'}
      routeParams={{
        key: id,
      }}
    >
      <div className="portfolio-small-card">
        <div className="bg">
          <Box />
        </div>
        <div className="project">
          <div className="screen">
            <img loading="lazy" alt="image" src={image} />
          </div>
          <div className="info">
            <div className="tags">
              <TagList data={tags} />
            </div>
            <div className="details">
              <p className="title">{title}</p>
              {/* <Rating rating={testimonial?.rating} /> */}
            </div>
            <p className="description">{shortDescription}</p>
            <span className="read-more">
              <span>Read More</span>
              <img loading="lazy" alt="image" src={arrow} />
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

Main.displayName = 'Portfolio Card';

export default Main;
