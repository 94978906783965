import React, { useEffect } from 'react';
import './styles.scss';
import { Row, Col, Skeleton } from 'antd';
import Footer from 'containers/footer';
import ContactCard from 'components/contact-card';
import { GET_ENTITY } from 'app-redux/actions';
import { GET_ENTITIES } from 'app-redux/actions';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { getMetaTag } from '../../helpers/seo';
import { ReactComponent as BlueLine } from '../../images/laxaar/blue-line-portfolio.svg';
import { ReactComponent as YellowLine } from '../../images/laxaar/yellow-line-portfolio.svg';
import yamz from 'yet-another-medium-zoom';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
export default (config) => {
  config = config || {};
  const {
    postRequestParams,
    requestParams,
    entityName,
    RelatedPostComponent,
    classNames,
    getPageData: getExtraPageData,
    PostComponent,
    renderDirect,
    getBreadcrumbs,
  } = config;
  const pageSize = 4;
  const Main = (props) => {
    const { pageData, loading } = props;
    const post = pageData?.post;
    const relatedPosts = pageData?.relatedPosts || [];
    useEffect(() => {
      // console.log('Effect Called');
      setTimeout(() => {
        const $images = [
          ...document.querySelectorAll(
            '.slide img,.editor-content img,.blog-details .image img'
          ),
        ];
        yamz.setOptions({
          wrapAlbum: true,
        });
        yamz.bind($images, {
          album: $images.map((img) => ({ img })),
        });
      });
    }, [pageData]);
    return renderDirect ? (
      <PostComponent {...props} />
    ) : (
      <div className={`screen ${classNames.page}`}>
        <div className="wave">
          <BlueLine />
        </div>
        <div className="container">
          <div className="section">
            <Row gutter={48}>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                {!loading && post && <PostComponent post={post} />}
                {loading && <Skeleton />}
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="right">
                  <ContactCard />
                </div>
              </Col>
            </Row>
          </div>
          {relatedPosts.length > 0 && (
            <div className="section more">
              <p className="label">View More</p>
              <div className="list">
                <Row gutter={48}>
                  {relatedPosts.map((item, index) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} key={index}>
                      <RelatedPostComponent {...item} id={item.key} />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          )}
        </div>
        <div className="wave yellow">
          <YellowLine />
        </div>
        <Footer />
      </div>
    );
  };

  Main.displayName = 'Post';
  Main.showPageSpinner = false;
  Main.routeProps = {
    getPageData: async function (route, { params }) {
      try {
        let data = {};
        const { key } = params;
        data.post = (
          await createAction(GET_ENTITY, {
            entityName,
            entityId: key,
            ...postRequestParams,
          }).payload
        ).data;
        if (RelatedPostComponent) {
          data.relatedPosts = (
            await createAction(GET_ENTITIES, {
              entityName,
              size: pageSize,
              ...requestParams,
            }).payload
          ).data.data
            .filter(({ key }) => {
              return key !== data.post.key;
            })
            .slice(0, pageSize - 1);
        }
        return {
          ...data,
          ...(getExtraPageData instanceof Function
            ? await getExtraPageData.apply(this, arguments)
            : {}),
        };
      } catch (e) {
        console.error('Error whle getting page data', { e });
        e.page = '/error404';
        throw e;
      }
    },
    getMetaTags: (pageData) => {
      return pageData && pageData.post
        ? {
            ...getMetaTag('title', pageData.post.title, true),
            ...getMetaTag('description', pageData.post.shortDescription, true),
            ...getMetaTag('image', pageData.post.image, true),
            ...getMetaTag('type', 'article', true),
            ...getMetaTag(
              'article:publisher',
              'https://www.facebook.com/laxaar',
              false
            ),
            ...getMetaTag(
              'article:author',
              'https://www.facebook.com/laxaar',
              false
            ),
            ...getMetaTag(
              'article:published_time',
              new Date(pageData.post.createdAt).toISOString(),
              false
            ),
            ...getMetaTag(
              'article:modified_time',
              new Date(pageData.post.updatedAt).toISOString(),
              false
            ),
            ...getMetaTag('author', 'Laxaar', false),
            ...getMetaTag('twitter:card', 'summary_large_image', false),
            ...getMetaTag(
              'twitter:creator',
              'https://twitter.com/LaxaarServ',
              false
            ),
            ...getMetaTag('twitter:site', 'LaxaarServ', false),
            ...getMetaTag('twitter:label1', 'Written by', false),
            ...getMetaTag('twitter:label2', 'Est. reading time', false),
            ...getMetaTag('twitter:data2', '5 minutes', false),
          }
        : {};
    },
    getBreadcrumbs,
    getJsonLdData: (pageData, pageUrl, req) => {
      const createdAt = pageData
        ? new Date(pageData.post.createdAt).toISOString()
        : null;
      const updatedAt = pageData
        ? new Date(pageData.post.updatedAt).toISOString()
        : null;
      return pageData && pageData.post
        ? {
            '@context': 'https://schema.org',
            '@graph': [
              {
                '@type': 'BlogPosting',
                '@id': pageUrl,
                isPartOf: {
                  '@id': pageUrl,
                },
                author: {
                  name: 'Laxaar',
                  '@id': 'https://www.laxaar.com/#author',
                },
                headline: pageData.post.title,
                name: pageData.post.title,
                datePublished: createdAt,
                dateModified: updatedAt,
                mainEntityOfPage: {
                  '@id': `${pageUrl}/#webpage`,
                },
                wordCount: pageData.post.description.split(' ').length,
                publisher: {
                  '@id': 'https://www.laxaar.com/#organization',
                },
                image: {
                  '@id': `${pageUrl}#primaryimage`,
                },
                thumbnailUrl: pageData.post.image,
                keywords: pageData.post.tags,
                articleSection: pageData.post.tags,
                inLanguage: 'en-US',
              },
              {
                '@type': 'WebPage',
                '@id': pageUrl,
                url: pageUrl,
                name: pageData.post.title,
                isPartOf: {
                  '@id': 'https://laxaar.com/#website',
                },
                primaryimageOfPage: {
                  '@id': `${pageUrl}#primaryimage`,
                },
                image: {
                  '@id': `${pageUrl}#primaryimage`,
                },
                thumbnailUrl: pageData.post.image,
                datePublished: createdAt,
                dateModified: updatedAt,
                description: pageData.post.shortDescription,
                inLanguage: 'en-US',
                potentialAction: [
                  {
                    '@type': 'ReadAction',
                    target: [pageUrl],
                  },
                ],
              },
              {
                '@type': 'ImageObject',
                inLanguage: 'en-US',
                '@id': `${pageUrl}#primaryimage`,
                url: pageData.post.image,
                contentUrl: pageData.post.image,
                width: 1200,
                height: 603,
              },
              {
                '@type': 'WebSite',
                '@id': 'https://laxaar.com/#website',
                url: 'https://laxaar.com/',
                name: 'Laxaar',
                description:
                  'Experts in Web Development, Mobile Development, DevOps, ML and AI Solutions',
                publisher: {
                  '@id': 'https://laxaar.com/#organization',
                },
                inLanguage: 'en-US',
              },
              {
                '@type': 'Organization',
                '@id': 'https://laxaar.com/#organization',
                name: 'Laxaar',
                url: 'https://laxaar.com/',
                logo: {
                  '@type': 'ImageObject',
                  inLanguage: 'en-US',
                  '@id': 'https://laxaar.com/#/schema/logo/image/',
                  url: 'https://laxaar.com/logo.png',
                  contentUrl: 'https://laxaar.com/logo.png',
                  width: 985,
                  height: 229,
                  caption: 'Laxaar',
                },
                image: {
                  '@id': 'https://laxaar.com/#/schema/logo/image/',
                },
                sameAs: [
                  'https://www.facebook.com/laxaar',
                  'https://twitter.com/LaxaarServ',
                ],
              },
            ],
          }
        : {};
    },
  };
  return Main;
};
