import React, { useEffect } from 'react';
import './styles.scss';
import Header from '../header';
import { renderRoutes } from 'react-router-config';
import { addOnRouteChange, getCurrentRoute } from '@arivaa-react/react-router5';
import { scrollToTopWindow } from '@arivaa-react/helpers/web';
import { ReactComponent as Wave } from '../../images/laxaar/yellow-wave.svg';
import MobileDrawer from 'containers/mobile-drawer';
import preprocess from 'containers/preprocess';
import { createAction } from '@arivaa-react/redux';
import { UI_SET_PROPERTY } from 'app-redux/actions';
import Breadcrumbs from '../../components/breadcrumb';

const Main = (props) => {
  const { route: propRoute, drawer, closeDrawer } = props;
  const route = getCurrentRoute();
  const { header } = route;
  useEffect(() => {
    addOnRouteChange(() => {
      setTimeout(() => {
        closeDrawer();
        scrollToTopWindow();
      }, 0);
    });
  }, []);
  return (
    <div className="app-container">
      {/*<BarLoader color="#FFB703" height="2" className="bar-loader"/>*/}
      {drawer && <MobileDrawer />}
      <div>
        {header !== false ? <Header type={header} route={propRoute} /> : null}
        <div
          className={
            'main-container ' +
            (header == false ? ' no-header ' : (header && header.type) || '')
          }
        >
          {route.routeKey !== 'landing' ? (
            <div className="yellow-wave">
              <Wave />
            </div>
          ) : null}
          {route.breadcrumbs == false ? null : <Breadcrumbs />}
          {renderRoutes(propRoute.routes)}
        </div>
      </div>
    </div>
  );
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui }) => {
  return {
    drawer: !!ui.drawer,
  };
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    closeDrawer: () => {
      return dispatch(
        createAction(UI_SET_PROPERTY, {
          name: 'drawer',
          value: false,
        })
      );
    },
  };
};

//Pre process the container with Redux Plugins
export default preprocess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
  withRouter: true,
});
