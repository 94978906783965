import React, { useEffect } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Drawer, Dropdown, Icon, Menu } from 'antd';
import Link from '../../@arivaa-react/components/link';
import { getCurrentRoute } from '../../@arivaa-react/react-router5';
import { ReactComponent as ReviewWave } from '../../images/laxaar/contact-card-wave.svg';
import { UI_SET_PROPERTY } from 'app-redux/actions';
import { createAction } from '@arivaa-react/redux';
import ExpertiseDropdown from '../expertiseDropdown';
import { RightOutlined } from '@ant-design/icons';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const routes = [
  {
    routeKey: 'landing',
    title: 'Home',
  },
  {
    routeKey: 'services',
    title: 'Services',
  },
  {
    title: 'Expertise',
    Component: ExpertiseDropdown,
  },
  {
    routeKey: 'portfolio',
    title: 'Portfolio',
  },
  {
    routeKey: 'products',
    title: 'Products',
  },
  {
    routeKey: 'blogs',
    title: 'Blog',
  },
  {
    routeKey: 'case-studies',
    title: 'Case Studies',
  },
  {
    routeKey: 'become-partner',
    title: 'Partner With Us',
  },
  {
    routeKey: 'enhancement',
    title: 'Feature Request',
  },
  {
    routeKey: 'careers',
    title: 'Careers',
  },
  {
    routeKey: 'faqs',
    title: 'FAQs',
  },
  {
    routeKey: 'contact',
    title: 'Contact Us',
  },
];

const Main = ({ closeDrawer }) => {
  const route = getCurrentRoute();
  useEffect(() => {
    document
      .querySelector('.ant-drawer-mask')
      ?.addEventListener('click', closeDrawer);
  }, []);
  return (
    <Drawer
      placement="left"
      closable={false}
      visible={true}
      getContainer={false}
      className="mobile-drawer"
      style={{ position: 'fixed' }}
    >
      <div className="review-wave">
        <ReviewWave />
      </div>
      <div className="navigation">
        <Menu className="menu" selectedKeys={route ? [route.routeKey] : null}>
          {routes.map(({ routeKey, title, Component }) => {
            return Component ? (
              <Menu.Item>
                <Dropdown
                  overlay={<Component />}
                  placement="bottomCenter"
                  trigger={['hover']}
                  overlayClassName="expertise-dropdown"
                >
                  <Link className="expertise-link">
                    <span>{title}</span>
                    <RightOutlined />
                  </Link>
                </Dropdown>
              </Menu.Item>
            ) : (
              <Menu.Item key={routeKey} className={title.replace(/\s+/g, '')}>
                <Link routeKey={routeKey} on>
                  {title}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
        <Link className="quote-btn" routeKey="quote">
          <Button className="btn yellow-btn">Get a free Quote</Button>
        </Link>
      </div>
      <div className="review-wave lower">
        <ReviewWave />
      </div>
    </Drawer>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    closeDrawer: () => {
      return dispatch(
        createAction(UI_SET_PROPERTY, {
          name: 'drawer',
          value: false,
        })
      );
    },
  };
};

Main.displayName = 'Mobile-Drawer';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [null, bindAction],
  localize: true,
});
