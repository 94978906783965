import React, { useEffect, useRef } from 'react';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ id, theme }) => {
  const element = useRef(null);
  useEffect(() => {
    const rzpPaymentForm = element.current;

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement('script');
      script.src = 'https://cdn.razorpay.com/static/widget/payment-button.js';
      script.async = true;
      script.dataset.payment_button_id = id;
      script.dataset.button_theme = theme || 'rzp-dark-standard';
      rzpPaymentForm.appendChild(script);
    }
  }, []);

  return <form ref={element}></form>;
};

Main.displayName = 'Razorpay-Payment-Button';

export default Main;
