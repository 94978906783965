import React from 'react';
import Footer from 'containers/footer';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { GET_ENTITIES } from 'app-redux/actions';
import { Button, Carousel, Col, Row } from 'antd';
import Link from '@arivaa-react/components/link';
import { ReactComponent as Star } from 'images/laxaar/white-star.svg';
import { ReactComponent as UpperWave } from 'images/laxaar/contact-us-upper-wave.svg';
import { ReactComponent as LowerWave } from 'images/laxaar/contact-us-lower-wave.svg';
import { ReactComponent as ArrowOrange } from 'images/laxaar/arrow-secondary.svg';
import { ReactComponent as CtaUpperWave } from 'images/laxaar/cta-upper-wave.svg';
import { ReactComponent as CtaLowerWave } from 'images/laxaar/cta-lower-wave.svg';

import { ReactComponent as NextDarkIcon } from 'images/laxaar/next-dark.svg';
import { ReactComponent as NextIcon } from 'images/laxaar/next.svg';
import { ReactComponent as PreviousDarkIcon } from 'images/laxaar/prev-dark.svg';
import { ReactComponent as PreviousIcon } from 'images/laxaar/previous.svg';
import { ReactComponent as PortfolioLowerWave } from 'images/laxaar/portfolio-lower-wave-ex.svg';
import { ReactComponent as PortfolioUpperWave } from 'images/laxaar/portfolio-upper-wave-ex.svg';
import TestimonialCard from 'components/testimonial-card';
import BlogCard from 'components/blog-card';
import PortfolioCard from 'components/portfolio-small-card';
import { getCarouselArrowsWithoutError } from '../../helpers/carousel';
import './styles.scss';

const NextDark = getCarouselArrowsWithoutError(NextDarkIcon);
const Next = getCarouselArrowsWithoutError(NextIcon);
const PreviousDark = getCarouselArrowsWithoutError(PreviousDarkIcon);
const Previous = getCarouselArrowsWithoutError(PreviousIcon);
const process = [
  {
    title: 'Product Analysis',
    details: [
      'Understanding the product',
      'Grasping the purpose of the product',
      'Specifying the Target audience',
      'Learning Design Expectations',
      'Exploring chief competitors',
    ],
  },
  {
    title: 'Technical Analysis',
    details: [
      'Best technologies for implementation',
      'Infrastructure Requirements',
      'Possibility for Phased releases?',
      'Scope for using open-source?',
      'Software License Requirements',
    ],
  },
  {
    title: 'Financial Analysis',
    details: [
      'Processing time estimation',
      'Analyzing budget and costing',
      'Infrastructure Cost Analysis',
      'Scope of quicker MVP launch',
      'Planning Phased releases?',
    ],
  },
  {
    title: 'Development Plan',
    details: [
      'Product Backlog Development',
      'Sprint Planning and Discussion',
      'Sprint Testing and QA',
      'Stakeholder review meetings',
      'Go Live and Release Plan',
    ],
  },
];
const CarouselComponent = ({ items, ItemComponent, darkArrows }) => {
  return items.length > 0 ? (
    <Carousel
      effect="fade"
      arrows={true}
      autoplay={true}
      nextArrow={darkArrows ? <NextDark /> : <Next />}
      prevArrow={darkArrows ? <PreviousDark /> : <Previous />}
    >
      {items.map((item, index) => {
        return (
          <div key={index}>
            <ItemComponent {...item} id={item.key} />
          </div>
        );
      })}
    </Carousel>
  ) : null;
};

export default (config) => {
  const Main = ({ pageData }) => {
    const {
      testimonial = [],
      caseStudies = [],
      portfolio = [],
      technology = [],
    } = pageData || {};

    return (
      <div className="screen expertise-page animate__animated animate__fadeIn">
        <div className="vector" />
        <div className="vector left" />

        <div className="section">
          <div className="container">
            <div className="heading">
              <p className="primary-text">{config.title}</p>
              <p className="primary-light-text">{config.subTitle}</p>
            </div>
            <p className="description">{config.description}</p>
            <div className="action">
              <Link routeKey="quote">
                <Button
                  className="yellow-btn"
                  children="Start a Project"
                  {...config.quoteButtonProps}
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="section services">
          <div className="container">
            <p className="title">Services</p>
            <div className="list">
              <Row gutter={48}>
                {(config.services || []).map((service, index) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
                    <div className="item">
                      <div className="graphic">
                        <Star />
                      </div>
                      <p className="text">{service}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>

        <div className="section cta">
          <div className="curve upper">
            <UpperWave />
          </div>
          <div className="content">
            <div className="container">
              <div className="list">
                <p className="title">{config.bannerTitle}</p>
                <p className="text">{config.bannerDescription}</p>
                <Link routeKey="quote">
                  <Button
                    className="white-btn"
                    children="Start a Project"
                    {...config.quoteButtonProps}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="curve lower">
            <LowerWave />
          </div>
        </div>

        <div className="section services work">
          <div className="container">
            <p className="title">How We Work</p>
            <div className="list">
              <Row>
                {(config.process || process).map(
                  ({ title, details = [] }, index) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
                      <div className="item">
                        <div className="heading">
                          <div className="count">
                            <div className="graphic">
                              <span>{index + 1}</span>
                            </div>
                            <span className="step">{title}</span>
                          </div>
                          <div className="arrow">
                            <ArrowOrange />
                          </div>
                        </div>
                        <div className="details">
                          {details.map((text, index) => (
                            <div className="info">
                              <p>•</p>
                              <p className="text" key={text}>
                                {text}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </div>
          </div>
        </div>

        <div className="section statistics">
          <div className="curve upper">
            <CtaUpperWave />
          </div>
          <div className="content">
            <div className="container">
              <Row>
                {(config.statistics || []).map(
                  ({ title, description }, index) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} key={index}>
                      <div className="item">
                        <p className="value">{title}</p>
                        <p className="text">{description}</p>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </div>
          </div>
          <div className="curve">
            <CtaLowerWave />
          </div>
        </div>

        <div className="section technologies">
          <div className="container">
            <p className="title">Tech Stack</p>
            <div className="list">
              <ul>
                {technology.map((item, index) => {
                  return (
                    <li key={index}>
                      <div className="item">
                        <div className="logo">
                          <object
                            width="100"
                            height="100"
                            type="image/svg+xml"
                            data={item.image}
                            aria-label="image"
                          />
                        </div>
                        <p className="text">{item.title}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="section case-studies">
          <div className="container">
            <p className="section-title">Case Studies</p>
            <div className="list">
              <Row gutter={48}>
                {caseStudies.map((item, index) => {
                  return (
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                      <BlogCard {...item} caseStudy={true} id={item.key} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>

        <div className="section testimonial">
          <div className="content">
            <div className="container">
              <p className="message">
                Trusted by over 50,000 people of the world’s leading companies
              </p>
              <div className="slides">
                <CarouselComponent
                  items={testimonial}
                  ItemComponent={TestimonialCard}
                  darkArrows={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section portfolio">
          <div className="curve upper">
            <PortfolioUpperWave />
          </div>
          <div className="content">
            <div className="container">
              <p className="section-title">Our Portfolio</p>
              <div className="list">
                <Row gutter={48}>
                  {portfolio.map((item, index) => {
                    return (
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
                        <PortfolioCard
                          id={portfolio[index].key}
                          {...portfolio[index]}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
          <div className="curve lower">
            <PortfolioLowerWave />
          </div>
        </div>

        <Footer />
      </div>
    );
  };

  Main.routeProps = {
    public: true,
    priority: 1.0,
    ...config.routeProps,
    getPageData: async function () {
      const requests = {
        testimonial: {
          entityName: 'testimonial',
          size: 5,
          sort: 'rating',
          search: 'featured;true;eq;boolean',
        },
        portfolio: {
          entityName: 'portfolio',
          size: 3,
          sort: 'projectDate',
          sortType: 'desc',
          search: config.tags
            ? `tags;${config.tags.join(',')};containsAny`
            : undefined,
        },
        technology: {
          entityName: 'technology',
          size: 50,
        },
        caseStudies: {
          entityName: 'case-study',
          size: 2,
          sort: 'createdAt',
          sortType: 'desc',
          search: config.tags
            ? `tags;${config.tags.join(',')};containsAny`
            : undefined,
        },
      };
      let output = {};

      await Promise.all(
        Object.keys(requests).map(async (key) => {
          try {
            let payload = await createAction(GET_ENTITIES, {
              ...requests[key],
            }).payload;
            payload = requests[key].all ? payload.data : payload.data.data;
            output[key] = payload;
          } catch (e) {
            console.error('Error while getting data for landing page', {
              e,
              key,
            });
          }
        })
      );
      return output;
    },
  };
  return Main;
};
