import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Scalable Solutions for',
  subTitle: 'Ecommerce and Hypermarkets',
  description: `Being one of the most active and data-intensive markets, the E-commerce and Hypermarkets industry has always found a special place in Laxaar's Journey as a software development company. It's one of the markets, requiring constant scalability and performance analysis.
`,
  services: [
    'Multi-Vendor Ecommerce Marketplace development',
    'Grocery and Delivery Apps Development ',
    'Megamart and Malls POS Systems',
    'CRM Data Analytics and Automation Services',
  ],
  bannerTitle: 'Looking to scale your business via innovation?',
  bannerDescription: `We implement convenient and customer-friendly marketplaces and hyper-delivery systems with secure online payments and digital wallets, combined with multi-layer security, top-notch data integrity, and cloud scalability.`,
  statistics: [
    {
      title: '20+',
      description: 'Websites and Apps',
    },
    {
      title: '10000+',
      description: 'Hours Worked',
    },
    {
      title: '200K+',
      description: 'Positive Client Business Reviews',
    },
    {
      title: '50+',
      description: 'Technologies',
    },
  ],
  tags: ['E-Commerce', 'Hypermarkets'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag('title', 'Hire the best E-commerce developers', true);
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
