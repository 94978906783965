import React from 'react';

import { renderRoutes } from 'react-router-config';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ route }) => {
  return <React.Fragment>{renderRoutes(route.routes)}</React.Fragment>;
};

Main.displayName = 'Product-Page';
export default Main;
