import { getBackgroundImageStyle } from '@arivaa-react/helpers/common';
import React from 'react';
import './styles.scss';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ name, image, title, designation, company }) => {
  return (
    <div className="testimonial-card">
      <p className="review">“ {title} “</p>
      <div className="author">
        <span
          className="image"
          style={{
            backgroundImage: getBackgroundImageStyle(image),
          }}
        />
        <div className="text">
          <p className="name">{name}</p>
          <p className="position">
            {designation} at {company}
          </p>
        </div>
      </div>
    </div>
  );
};

Main.displayName = 'Testimonial Card';

export default Main;
