import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import { ReactComponent as TwoArrows } from '../../../images/laxaar/two-arrows.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/app-logo.png';
import screenshot1 from './assets/screenshot1.png';
import screenshot2 from './assets/screenshot2.png';
import screenshot3 from './assets/screenshot3.png';
import screenshot4 from './assets/screenshot4.png';
import screenshot5 from './assets/screenshot5.png';
import screenshot6 from './assets/screenshot6.png';
import screenshot7 from './assets/screenshot7.png';
import screenshot8 from './assets/screenshot8.png';
import screenshot9 from './assets/screenshot9.png';
import screenshot10 from './assets/screenshot10.png';
import screenshot11 from './assets/screenshot11.png';
import Device from '@arivaa-react/ui-components/devices';
import { getMetaTag } from '../../../helpers/seo';
import { openChat } from '../../../helpers/chat';
import {
  BuildOutlined,
  CaretRightOutlined,
  CloudSyncOutlined,
  GlobalOutlined,
  RadarChartOutlined,
  RocketOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10,
    screenshot11,
  ];

  return (
    <div className="public-page product fitness">
      <div>
        <div className="hero-section">
          <div className="container">
            <Row gutter={48}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="introduction">
                  <div className="logo">
                    <img loading="lazy" alt="image" src={logo} />
                  </div>
                  <p className="title">Fitness Pro</p>
                  <p className="description">
                    Empowering your fitness business and sales via state of art
                    and fully automated Customer Relationship Management
                  </p>
                  <div className="actions">
                    <a
                      href="https://rzp.io/l/9IAhw25"
                      target="_blank"
                      aria-label="Payment link"
                    >
                      <Button className="yellow-btn">Buy Now - $3000</Button>
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Device type={'imac'} image={screenshot1} />
              </Col>
            </Row>
          </div>
        </div>
        <div className="section features">
          <div className="container">
            <Row gutter={48}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="feature">
                  <div className="icon">
                    <BuildOutlined />
                  </div>
                  <div className="text">
                    <p className="title">Whitelabeling and Branding</p>
                    <p className="description">
                      We will customize the system design and look & feel as per
                      your business branding and preference at no extra charge
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="feature">
                  <div className="icon">
                    <CloudSyncOutlined />
                  </div>
                  <div className="text">
                    <p className="title">Onsite Deployment</p>
                    <p className="description">
                      For seamless business transition, We will deploy the
                      system on your preferred infrastructure with no additional
                      charges.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="feature">
                  <div className="icon">
                    <RocketOutlined />
                  </div>
                  <div className="text">
                    <p className="title">Amazing go live support</p>
                    <p className="description">
                      Even after going live, For any issues, questions or
                      doubts, feel free to reach out to us via our Customer
                      Success team, always eager and ready to reply within
                      minutes.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="section information">
          <div className="container">
            <Row gutter={80}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Device type={'imac'} image={screenshot5} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <div className="text">
                  <p className="title">
                    Simplified and Automated Customer onboarding
                  </p>
                  <p className="description">
                    Give your clients and yourself a fully automated solution
                    for paying you and managing all your awesome services via
                    single online point of contact
                  </p>
                  <div className="actions">
                    <Button className="yellow-btn" onClick={openChat}>
                      Get in touch
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="product-faqs wide">
          <div className="curve upper">
            <UpperWave />
          </div>
          <div className="content">
            <div className="container">
              <p className="title">Some Amazing Features</p>
              <div className="features">
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <div className="feature">
                      <div className="icon">
                        <UserOutlined />
                      </div>
                      <div className="text">
                        <p className="title">Customer Module</p>
                        <p className="description">
                          Optimize and personalize your customer experience with
                          best-in-class user experience with features like
                          Dedicated customer Profile, Online session booking,
                          Personalized communications and chat and much more.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <div className="feature">
                      <div className="icon">
                        <TeamOutlined />
                      </div>
                      <div className="text">
                        <p className="title">Trainer Module</p>
                        <p className="description">
                          Best customer management experience for the trainers
                          with best-in-class capabilities like booking calendar,
                          client notes, trainer profile, payment tracking and
                          multi client management and much more
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <div className="feature">
                      <div className="icon">
                        <RadarChartOutlined />
                      </div>
                      <div className="text">
                        <p className="title">Admin Module</p>
                        <p className="description">
                          Get a holistic view of your business as an admin with
                          features like Analytics Dashboard, Payment Plan
                          Management, Customer Management, Trainer Management,
                          Purchase History, Session history and much more
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <div className="feature">
                      <div className="icon">
                        <GlobalOutlined />
                      </div>
                      <div className="text">
                        <p className="title">Online Payments</p>
                        <p className="description">
                          Personalized payment plans as per your business
                          requirements for your customers so that customers can
                          you pay and track payments in a hassle free manner
                          which can also be tracked in the admin module
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="action">
                <Button className="primary-btn" onClick={openChat}>
                  Interested?
                </Button>
              </div>
            </div>
          </div>
          <div className="curve">
            <LowerWave />
          </div>
        </div>

        <div className="section information reverse">
          <div className="container">
            <Row gutter={48}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="list">
                  <p className="title">
                    Booking Calendar for easy management of sessions
                  </p>
                  <div className="item">
                    <div className="icon">
                      <YellowCheck />
                    </div>
                    <p className="details">
                      Both customers and trainers can track all the scheduled
                      sessions via a monthly, weekly and yearly calendar
                    </p>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <YellowCheck />
                    </div>
                    <p className="details">
                      Both customers and trainers can re-schedule or
                      cancellation of booked sessions as per your business rules
                    </p>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <YellowCheck />
                    </div>
                    <p className="details">
                      Transparent business feedback via review and rating of
                      trainers for completed sessions
                    </p>
                  </div>
                  <div className="actions">
                    <Button className="yellow-btn" onClick={openChat}>
                      More Questions?
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Device type={'imac'} image={screenshot7} />
              </Col>
            </Row>
          </div>
        </div>

        <div className="section information">
          <div className="container">
            <Row gutter={80}>
              <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Device type={'imac'} image={screenshot3} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <div className="text">
                  <p className="title">Fully secured authentication system</p>
                  <p className="description">
                    This system has an adaptable authentication and
                    authorization mechanisms in place to ensure the right data
                    access for the right person and prevent unauthorized access
                    via features like secured login, email verification, otp
                    based password reset mechanisms, role based authorization
                    and much more.
                  </p>
                  <div className="actions">
                    <Button className="yellow-btn" onClick={openChat}>
                      Get in touch
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="section screens">
          <div className="container">
            <div className="heading">
              <p className="title">A high level view of the system</p>
              <p className="description"></p>
            </div>
            <Row gutter={48}>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <div className="carousel">
                  <Carousel
                    effect="fade"
                    customPaging={(i) => (
                      <a>
                        <img loading="lazy" alt="image" src={images[i]} />
                      </a>
                    )}
                    slidesToScroll={1}
                    slidesToShow={1}
                    speed={500}
                    dotsClass="slick-dots slick-thumb"
                    dots
                    infinite={false}
                    arrows={true}
                    nextArrow={<NextDark />}
                    prevArrow={<PreviousDark />}
                  >
                    {images.map((item, index) => {
                      return (
                        <div className="slide" key={index}>
                          <img loading="lazy" alt="image" src={item} />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </Col>
              <Col xs={0} sm={0} md={0} lg={8} xl={8}>
                <ContactCard title={'Need Customization?'} />
              </Col>
            </Row>
          </div>
          <div className="section information">
            <div className="container">
              <Row gutter={48}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Device type={'imac'} image={screenshot8} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className="text">
                    <p className="title">
                      Business centric accounting and analytics for the business
                      owners
                    </p>
                    <div className="list arrows">
                      <div className="item">
                        <div className="icon">
                          <TwoArrows />
                        </div>
                        <p className="details">
                          Detailed and separate revenue analytics based on
                          purchases and sessions
                        </p>
                      </div>
                      <div className="item">
                        <div className="icon">
                          <TwoArrows />
                        </div>
                        <p className="details">
                          Customer and Trainer management along with customer
                          specific purchase history and session history
                        </p>
                      </div>
                      <div className="item">
                        <div className="icon">
                          <TwoArrows />
                        </div>
                        <p className="details">
                          Easily manage all the payment plans for your customers
                          without impacting the existing business
                        </p>
                      </div>
                      <div className="item">
                        <div className="icon">
                          <TwoArrows />
                        </div>
                        <p className="details">
                          Detailed user management with features like force
                          verification, disable logins, login history and much
                          more
                        </p>
                      </div>
                    </div>
                    <div className="actions">
                      <a
                        href="https://rzp.io/l/9IAhw25"
                        target="_blank"
                        aria-label="Payment link"
                      >
                        <Button className="yellow-btn">
                          Get it Now - $3000
                        </Button>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequent Questions</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="Is there any recurring license cost e.g any yearly license cost to this product?"
                  key="1"
                >
                  <p>
                    There is no recurring license cost. There is just one time
                    license cost of 3000$ for using the system lifetime for your
                    business
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system? " key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system? "
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Button className="primary-btn" onClick={openChat}>
                Still got questions?
              </Button>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

Main.displayName = 'Web Product';
Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag(
        'title',
        'Pro Fitness CRM | Empowering your fitness business and sales via state of art and fully automated Customer Relationship Management',
        true
      ),
      ...getMetaTag(
        'description',
        'Pro Fitness CRM | Empowering your fitness business and sales via state of art and fully automated Customer Relationship Management',
        true
      ),
      ...getMetaTag('image', screenshot1, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'Pro Fitness CRM',
      },
    ];
  },
};
//Pre process the container with Redux Plugins
export default Main;
