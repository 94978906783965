import React from 'react';
import './styles.scss';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ data = [] }) => {
  return (
    <ul>
      {(data || []).map((item, index) => (
        <li key={index}>
          <span className="tag">{item}</span>
        </li>
      ))}
    </ul>
  );
};

Main.displayName = 'Tag-List';

export default Main;
