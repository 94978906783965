import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/auto-bidder-logo.svg';
import heroimg from './assets/auto-bidder-hero.png';
import autoBidderProjects from './assets/auto-bidder-projects.png';
import autoBidderProposals from './assets/auto-bidder-proposals.png';
import autoBidderAnalytics from './assets/auto-bidder-analytics.png';
import screenshot1 from './assets/screenshot1.png';
import screenshot2 from './assets/screenshot2.png';
import screenshot3 from './assets/screenshot3.png';
import screenshot4 from './assets/screenshot4.png';
import screenshot5 from './assets/screenshot5.png';
import { getMetaTag } from '../../../helpers/seo';
import {
  CaretRightOutlined,
  CustomerServiceOutlined,
  DeploymentUnitOutlined,
  LayoutOutlined,
} from '@ant-design/icons';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
  ];

  return (
    <div className="public-page product auto-bidder">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="logo"/>
                </div>
                <p className="title">
                  Auto-Bidder - Making Bidding Easier Than Ever!!
                </p>
                <p className="description">
                  Automate and optimize bidding on freelancer.com with features
                  like proposal filtering, real-time bid monitoring & customized
                  proposals. Bid on hundreds of proposals swiftly, saving time
                  for strategic decisions.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={heroimg} alt="image"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <LayoutOutlined />
                </div>
                <div className="text">
                  <p className="title">One Click Bidding</p>
                  <p className="description">
                    Activate the bidding extenstion with just one click and
                    place bids on hundreds of projects in no time.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <DeploymentUnitOutlined />
                </div>
                <div className="text">
                  <p className="title">Onsite Deployment</p>
                  <p className="description">
                    For smooth business transition, our team will deploy the
                    system onto your chosen infrastructure at no extra cost.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CustomerServiceOutlined />
                </div>
                <div className="text">
                  <p className="title">Go Live Support</p>
                  <p className="description">
                    Our Customer Support team is here to assist you even after
                    going live. Reach out to us anytime with your queries.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={autoBidderAnalytics} alt="image"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Bid Maximum Proposals With Minimum Effort
                </p>
                <p className="description">
                  Minimize manual workload by swiftly bidding on hundreds of
                  proposals within minutes, eliminating the need for hours of
                  manual effort. Streamline your workflow and reclaim hours of
                  valuable time for strategic decision-making and business
                  growth.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Get in touch</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="list">
                <p className="title">
                  Add More Power To Your Bidding With Specialized Features
                </p>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Enhanced project targeting where you can customise your
                    bidding strategy by targeting specific keywords in project
                    titles, descriptions, or required skills.
                  </p>
                </div>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Set minimum and maximum project prices, adjust suggested
                    bids by percentage, and define durations for both fixed and
                    hourly projects according to your preferences.
                  </p>
                </div>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Bid selectively on projects from specific regions,
                    optimizing your chances of success based on your
                    geographical preferences.
                  </p>
                </div>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Got Questions?</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={autoBidderProjects} alt="image"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={autoBidderProposals} alt="image"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Drive Results with Tailored Proposals that Speak to Your
                  Client's Needs
                </p>
                <p className="description">
                  Craft personalized proposals tailored to specific keywords or
                  project requirements. Create multiple proposals with ease,
                  utilizing short codes for bid summaries, skills, experiences,
                  and employer questions. The system randomly selects and
                  submits a proposal at runtime, maximizing your engagement
                  potential.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Let's get started?</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">Unified Web Experience For Smooth Bidding</p>
            <p className="description">
              Consistent functionality paired with an intuitive interface gives
              a smooth user experience. Its user-friendly design facilitates
              effortless navigation, ensuring accessibility for all users.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image"/>
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image"/>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <div className="card-container">
                <ContactCard title="Looking for something similar?" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave blue">
          <ProductBlueLine />
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequent Questions</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="How much does it cost to use this product?"
                  key="1"
                >
                  <p>
                    This is a monthly subscription based project with price
                    starting at just 20$/month
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    No, this product is a chrome extension that can be installed
                    in google chrome on any system
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag(
        'title',
        'Auto-Bidder | Auto - Bidding Chrome Extension for freelancer.com',
        true
      ),
      ...getMetaTag(
        'description',
        'Automate and optimize bidding on freelancing platforms with features like proposal filtering and real-time bid monitoring. Bid on hundreds of proposals swiftly, saving time for strategic decisions.',
        true
      ),
      ...getMetaTag('image', heroimg, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'Auto-Bidder',
      },
    ];
  },
};
Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
