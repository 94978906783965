import React from 'react';
import './styles.scss';
import { ReactComponent as Icon } from '../../images/laxaar/icon.svg';
import { ReactComponent as ReviewWave } from '../../images/laxaar/review-wave.svg';
import Rating from '../rating';
import { ReactComponent as Marks } from '../../images/laxaar/marks.svg';
import { ReactComponent as DashedBorder } from '../../images/laxaar/dashed-border.svg';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ name, designation, title, rating, company }) => {
  return (
    <div className="review-card">
      <div className="review-wave left">
        <ReviewWave />
      </div>
      <div className="content">
        <div className="info">
          <div className="person">
            <p className="name">{name}</p>
            <p className="position">
              {designation} at <span className="text">{company}</span>
            </p>
          </div>
          <div className="stars">
            <Rating rating={rating} />
          </div>
        </div>
        <p className="separator">
          <span className="marks">
            <Marks />
          </span>
          <DashedBorder />
        </p>
        <p className="text">{title}</p>
      </div>
    </div>
  );
};

Main.displayName = 'Service Card';

export default Main;
