import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Creating Result Driven',
  subTitle: 'Mobile Apps',
  description: `We come with a repertoire of solutions; right from prototypes to designing to state-of-the-art end-to-end mobile app solutions. We deliver products that work across all platforms including various types of phones, tablets, and smartwatches.
`,
  services: [
    'Native Mobile Apps Development ',
    'Hybrid Mobile Apps Development ',
    'Smart Watch Design and Development',
    'Mobile Testing and Automation Services',
  ],
  bannerTitle: 'Does your business call for a mobile app?',
  bannerDescription: `Our team of professionals holds extensive experience in custom app development, enabling them to build creative solutions suited to specific business needs. Our app development services will help you reach a greater audience, generate more revenue and help your business scale.`,
  statistics: [
    {
      title: '50+',
      description: 'Live Mobile Apps',
    },
    {
      title: '12000+',
      description: 'Hours Worked',
    },
    {
      title: '100K+',
      description: 'App Stores Reviews',
    },
    {
      title: '1M',
      description: 'App Stores Revenue',
    },
  ],
  tags: ['React Native', 'Flutter', 'IOS', 'Android'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'High Quality Mobile App Development for your Business',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
