export function getMetaTag(name, value, includeSocial) {
  const obj = {
    [name]: value,
  };
  if (includeSocial) {
    obj[`og:${name}`] = value;
    if (name === 'image') {
      name = name + ':src';
    }
    obj[`twitter:${name}`] = value;
  }
  return obj;
}

export function getDefaultJsonLdData(pageUrl, metadata) {
  return {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': 'https://laxaar.com/#organization',
        name: 'Laxaar',
        url: 'https://laxaar.com/',
        logo: {
          '@type': 'ImageObject',
          inLanguage: 'en-US',
          '@id': 'https://laxaar.com/#/schema/logo/image/',
          url: 'https://laxaar.com/logo.png',
          contentUrl: 'https://laxaar.com/logo.png',
          width: 985,
          height: 229,
          caption: 'Laxaar',
        },
        image: {
          '@id': 'https://laxaar.com/#/schema/logo/image/',
        },
        sameAs: [
          'https://www.facebook.com/laxaar',
          'https://twitter.com/LaxaarServ',
        ],
      },
      {
        '@type': 'ImageObject',
        inLanguage: 'en-US',
        '@id': metadata.image,
        url: metadata.image,
        contentUrl: metadata.image,
        width: 1102,
        height: 531,
      },
      {
        '@type': 'WebSite',
        '@id': 'https://laxaar.com/#website',
        url: 'https://laxaar.com/',
        name: 'Laxaar',
        description:
          'Experts in Web Development, Mobile Development, DevOps, ML and AI Solutions',
        publisher: {
          '@id': 'https://laxaar.com/#organization',
        },
        inLanguage: 'en-US',
        potentialAction: {
          '@type': 'SearchAction',
          target: 'https://www.laxaar.com/search?query={search_term_string}',
          'query-input': 'required name=search_term_string',
        },
      },
      {
        '@type': 'WebPage',
        '@id': pageUrl,
        url: pageUrl,
        name: metadata.title,
        isPartOf: {
          '@id': 'https://laxaar.com/#website',
        },
        primaryimageOfPage: {
          '@id': metadata.image,
        },
        image: {
          '@id': metadata.image,
        },
        thumbnailUrl: metadata.image,
        datePublished: '2023-04-24T08:29:40.963Z',
        dateModified: new Date().toISOString(),
        description: metadata.shortDescription,
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [pageUrl],
          },
        ],
      },
    ],
  };
}
