import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import { ReactComponent as TwoArrows } from '../../../images/laxaar/two-arrows.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/app-logo.png';
import heroImage from './assets/edocsify-hero.png';
import exportPdf from './assets/export-pdf.png';
import multiLang from './assets/multi-lang.png';
import darkMode from './assets/dark-mode.png';
import admin from './assets/admin.png';
import sections from './assets/sections.png';
import screenshot1 from './assets/screenshot1.png';
import screenshot2 from './assets/screenshot2.png';
import screenshot3 from './assets/screenshot3.png';
import screenshot4 from './assets/screenshot4.png';
import screenshot5 from './assets/screenshot5.png';
import screenshot6 from './assets/screenshot6.png';
import screenshot7 from './assets/screenshot7.png';
import screenshot8 from './assets/screenshot8.png';
import screenshot9 from './assets/screenshot9.png';
import screenshot10 from './assets/screenshot10.png';
import screenshot11 from './assets/screenshot11.png';
import screenshot12 from './assets/screenshot12.png';
import screenshot13 from './assets/screenshot13.png';
import screenshot14 from './assets/screenshot14.png';
import { openChat } from '../../../helpers/chat';
import { getMetaTag } from '../../../helpers/seo';
import {
  BuildOutlined,
  CaretRightOutlined,
  CloudSyncOutlined,
  RocketOutlined,
} from '@ant-design/icons';

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10,
    screenshot11,
    screenshot12,
    screenshot13,
    screenshot14,
  ];
  return (
    <div className="public-page product">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <div className="introduction">
                <div className="logo">
                  <img loading="lazy" alt="image" src={logo} />
                </div>
                <p className="title">Edocsify</p>
                <p className="description">
                  One tool to automate all your documentations and their online
                  presence with live deployments
                </p>
                <div className="actions">
                  {/* <a href="https://edocsify.com" target={'_blank'}>
                    <Button className="yellow-btn-text">Demo</Button>
                  </a> */}
                  <a
                    href="https://rzp.io/l/euYpUHN7j5"
                    target={'_blank'}
                    aria-label="Payment link"
                  >
                    <Button className="yellow-btn">Buy Now - $100</Button>
                  </a>

                  <a onClick={openChat} target={'_blank'} aria-label="Demo">
                    <Button className="yellow-btn-text">
                      Request for Demo
                    </Button>
                  </a>
                  {/* <p className="description">
                    Demo Credentials : admin@admin.com / 123456
                  </p> */}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
              <div className="graphic">
                <img loading="lazy" alt="image" src={heroImage} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="wave yellow">
          <ProductYellowLine />
        </div>
        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <BuildOutlined />
                </div>
                <div className="text">
                  <p className="title">Whitelabeling and Branding</p>
                  <p className="description">
                    We will customize the system design and look & feel as per
                    your business branding and preference at no extra charge
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CloudSyncOutlined />
                </div>
                <div className="text">
                  <p className="title">Onsite Deployment</p>
                  <p className="description">
                    For seamless business transition, We will deploy the system
                    on your preferred infrastructure with no additional charges.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <RocketOutlined />
                </div>
                <div className="text">
                  <p className="title">Amazing go live support</p>
                  <p className="description">
                    Even after going live, For any issues, questions or doubts,
                    feel free to reach out to us via our Customer Success team,
                    always eager and ready to reply within minutes.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
              <div className="graphic">
                <img loading="lazy" alt="image" src={exportPdf} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <div className="text">
                <p className="title">
                  Export full or partial content as PDF file
                </p>
                <p className="description">
                  Download any documentation as pdf file with just one click and
                  access/share it offline anytime.
                </p>
                <div className="actions">
                  <Button className="yellow-btn" onClick={openChat}>
                    Get in touch
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="list">
                <p className="title">
                  Create unlimited documentations with instant online
                  deployments
                </p>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Easy to use wysiyg editor which will simply make you fall in
                    love with its clean and intuitive design
                  </p>
                </div>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Manage your documentations smartly with the highest degree
                    of content reusability with one click Content Replication
                    across documentations
                  </p>
                </div>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Content categorization into sections and articles makes
                    content more user friendly and seo friendly
                  </p>
                </div>
                <div className="actions">
                  <Button className="yellow-btn" onClick={openChat}>
                    More Questions?
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img loading="lazy" alt="image" src={sections} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">Simple and easy to use admin panel</p>
            <p className="description">
              Fully equipped admin panel to manage each and every aspect of your
              documentation workflow and end to end content deployments
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img loading="lazy" alt="image" src={images[i]} />
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img loading="lazy" alt="image" src={item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title={'Need Customization?'} />
            </Col>
          </Row>
        </div>
        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img loading="lazy" alt="image" src={admin} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Each documentation on separate subdomain with instant
                  deployment
                </p>
                <div className="list arrows">
                  <div className="item">
                    <div className="icon">
                      <TwoArrows />
                    </div>
                    <p className="details">
                      Each documentation is a separate website accessible at a
                      separate subdomain of your business domain so that it has
                      a unique identity and online presence.
                    </p>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <TwoArrows />
                    </div>
                    <p className="details">
                      <b>
                        E.g A documentation named arivaa with system on
                        edocsify.com will be accessible at arivaa.edocsify.com
                      </b>
                    </p>
                  </div>
                  {/*<div className="item">
                    <div className="icon">
                      <TwoArrows />
                    </div>
                    <p className="details">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Facilisi sed facilisi sem id purus ligula.
                    </p>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <TwoArrows />
                    </div>
                    <p className="details">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Facilisi sed facilisi sem id purus ligula.
                    </p>
                  </div>*/}
                </div>
                <div className="actions">
                  <Button className="yellow-btn" onClick={openChat}>
                    Interested?
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="wave blue">
          <ProductBlueLine />
        </div>
        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Automatic Translation with 80+ Languages Support
                </p>
                <p className="description">
                  Edocsify supports more than 80 world wide languages, which
                  make it easy for your users to understand the documentation in
                  their own language
                </p>
                <div className="actions">
                  <Button className="yellow-btn" onClick={openChat}>
                    Liked it?
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img loading="lazy" alt="image" src={multiLang} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img loading="lazy" alt="image" src={darkMode} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">A whole new look with a Dark mode</p>
                <p className="description">
                  Dark Mode introduces a dramatic new look of the documentation.
                  It makes easier to read documentation in less light.
                </p>
                <div className="actions">
                  <a
                    href="https://rzp.io/l/euYpUHN7j5"
                    target={'_blank'}
                    aria-label="Payment link"
                  >
                    <Button className="yellow-btn">Buy Now - $1500</Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequent Question</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="Is there any recurring license cost e.g any yearly license cost to this product?"
                  key="1"
                >
                  <p>
                    There is no recurring license cost. There is just one time
                    license cost of 1500$ for using the system lifetime for your
                    business/institute/company
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system? " key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system? "
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Button className="primary-btn" onClick={openChat}>
                {' '}
                Still got questions?
              </Button>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};
Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag(
        'title',
        'Edocsify | One tool to automate all your documentations and their online presence with live deployments',
        true
      ),
      ...getMetaTag(
        'description',
        'Edocsify | One tool to automate all your documentations and their online presence with live deployments',
        true
      ),
      ...getMetaTag('image', heroImage, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'Edocsify',
      },
    ];
  },
};
Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default Main;
