import React from 'react';
import './styles.scss';
import { ReactComponent as Icon } from '../../images/laxaar/icon.svg';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ title, subTitle, image, description }) => {
  return (
    <div className="service-card">
      <div className="graphic">
        {image ? (
          <object type="image/svg+xml" data={image} aria-label={title}></object>
        ) : (
          <Icon />
        )}
      </div>
      <div className="text">
        <p className="title">
          {title} <span className="highlight">{subTitle}</span>
        </p>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

Main.displayName = 'Service Card';

export default Main;
