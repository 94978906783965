import React from 'react';
import './styles.scss';
import { Col, Row, Skeleton } from 'antd';
import Footer from 'containers/footer';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { GET_ENTITIES } from 'app-redux/actions';
import { ReactComponent as BlueLine } from '../../images/laxaar/services-blue-line.svg';
import { ReactComponent as LowerWave } from '../../images/laxaar/testimonial-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../images/laxaar/testimonial-upper-wave.svg';
import { ReactComponent as YellowLine } from '../../images/laxaar/services-yellow-line.svg';
import ReviewCard from '../../components/review-card';
import { getMetaTag } from '../../helpers/seo';

const Main = ({ pageData, loading }) => {
  const { testimonial = [], technology = [], services = [] } = pageData || {};
  let testimonialData = [];
  let serviceData = [];
  for (let i = 0; i < testimonial.length; i = i + 1) {
    testimonialData.push(
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={8}
        key={i}
        className={((i + 3) / 3) % 2 !== 0 ? 'margin' : ''}
      >
        {testimonial[i] && <ReviewCard {...testimonial[i]} />}
        {/*{testimonial[i + 1] && <ReviewCard {...testimonial[i + 1]} />}
        {testimonial[i + 2] && <ReviewCard {...testimonial[i + 2]} />}*/}
      </Col>
    );
  }
  for (let i = 0; i < services.length; i = i + 2) {
    serviceData.push(
      <Col xs={24} sm={24} md={24} lg={24} xl={8} key={i}>
        <div className={((i + 2) / 2) % 2 !== 0 ? 'item margin' : 'item'}>
          <div className="heading">
            <div className="icon">
              <img loading="lazy" alt="image" src={services[i].image} />
            </div>
            <p className="title">
              {services[i].title}{' '}
              <span className="highlight">{services[i].subTitle}</span>
            </p>
          </div>
          <p className="text">{services[i].description}</p>
        </div>
        {services[i + 1] && (
          <div className={((i + 2) / 2) % 2 !== 0 ? 'item' : 'item orange'}>
            <div className="heading">
              <div className="icon">
                <img loading="lazy" alt="image" src={services[i + 1].image} />
              </div>
              <p className="title">
                {services[i + 1].title}{' '}
                <span className="highlight">{services[i + 1].subTitle}</span>
              </p>
            </div>
            <p className="text">{services[i + 1].description}</p>
          </div>
        )}
      </Col>
    );
  }

  return (
    <div className="screen services animate__animated animate__fadeIn">
      <div className="vector" />
      <div className="vector left" />
      <div className="wave blue">
        <BlueLine />
      </div>
      <div className="wave yellow">
        <YellowLine />
      </div>
      <div className="container">
        <div className="heading">
          <p className="primary-text">Quality Services</p>
          <p className="primary-light-text">for your Business</p>
        </div>
        <div className="services-list">
          <div className="list">
            <Row gutter={48}>
              {loading ? (
                <Row gutter={48}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div className="card skeleton">
                      <div className="card-details">
                        <Skeleton paragraph={{ rows: 8 }} />
                      </div>
                    </div>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                    <div className="card skeleton">
                      <div className="card-details">
                        <Skeleton paragraph={{ rows: 8 }} />
                      </div>
                    </div>
                  </Col>
                  <Col xs={0} sm={0} md={8} lg={8} xl={8}>
                    <div className="card skeleton">
                      <div className="card-details">
                        <Skeleton paragraph={{ rows: 8 }} />
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                serviceData
              )}
            </Row>
          </div>
        </div>

        <div className="section technologies">
          <p className="title">Cutting Edge Technologies</p>
          <div className="list">
            <ul>
              {technology.map((item, index) => {
                return (
                  <li key={index}>
                    <div className="item">
                      <div className="logo">
                        <object
                          type="image/svg+xml"
                          data={item.image}
                          aria-label={item.title}
                        ></object>
                      </div>
                      <p className="text">{item.title}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="section reviews">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="list">
          <div className="container">
            <p className="title">What Our Clients Say?</p>
            <Row gutter={48} align="top">
              {testimonialData}
            </Row>
          </div>
        </div>
        <div className="curve lower">
          <LowerWave />
        </div>
      </div>

      <Footer />
    </div>
  );
};

Main.routeProps = {
  public: true,
  getPageData: async function () {
    const requests = {
      testimonial: {
        entityName: 'testimonial',
        size: 50,
        sort: 'rating',
      },
      technology: {
        entityName: 'technology',
        size: 50,
        sort: 'createdAt',
        sortType: 'desc',
      },
      services: {
        entityName: 'service',
        all: true,
        search: [`type;Services`],
        sort: 'createdAt',
        sortType: 'asc',
      },
    };
    let output = {};

    await Promise.all(
      Object.keys(requests).map(async (key) => {
        try {
          let payload = await createAction(GET_ENTITIES, {
            ...requests[key],
          }).payload;
          payload = requests[key].all ? payload.data : payload.data.data;
          output[key] = payload;
        } catch (e) {
          console.error('Error while getting data for landing page', {
            e,
            key,
          });
        }
      })
    );
    return output;
  },
  getMetaTags: () => {
    return getMetaTag(
      'title',
      'High Quality Website development, Mobile App development',
      true
    );
  },
  priority: '1.0',
};
export default Main;
