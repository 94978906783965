/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
// import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/logo.jpg';
import section1 from './assets/section1.png';
import section2 from './assets/section2.png';
import section3 from './assets/section3.png';
import section4 from './assets/section4.png';

import screenshot1 from './assets/screenshot1.jpg';
import screenshot2 from './assets/screenshot2.jpg';
import screenshot3 from './assets/screenshot3.jpg';
import screenshot4 from './assets/screenshot4.jpg';
import screenshot5 from './assets/screenshot5.jpg';
import screenshot6 from './assets/screenshot6.jpg';
import screenshot7 from './assets/screenshot7.jpg';
import screenshot8 from './assets/screenshot8.jpg';
import screenshot9 from './assets/screenshot9.jpg';
import screenshot10 from './assets/screenshot10.jpg';
import screenshot11 from './assets/screenshot11.jpg';
import screenshot12 from './assets/screenshot12.jpg';
import screenshot13 from './assets/screenshot13.jpg';
import screenshot14 from './assets/screenshot14.jpg';
import screenshot15 from './assets/screenshot15.jpg';
import screenshot16 from './assets/screenshot16.jpg';
import screenshot17 from './assets/screenshot17.jpg';
import screenshot18 from './assets/screenshot18.jpg';
import screenshot19 from './assets/screenshot19.jpg';
import screenshot20 from './assets/screenshot20.jpg';
import screenshot21 from './assets/screenshot21.jpg';
import screenshot22 from './assets/screenshot22.jpg';
import { getMetaTag } from '../../../helpers/seo';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10,
    screenshot11,
    screenshot12,
    screenshot13,
    screenshot14,
    screenshot15,
    screenshot16,
    screenshot17,
    screenshot18,
    screenshot19,
    screenshot20,
    screenshot21,
    screenshot22,
  ];
  return (
    <div className="public-page product shipment-booking-app">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <p className="title">Shipment Booking App</p>
                <p className="description">
                  Leverage ShipMate Pro to transform your shipment booking
                  services with speed and efficiency. Whether expanding locally
                  or internationally, our solution guarantees streamlined
                  operations, dependability, and an even user experience for
                  both shippers and customers.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section1} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <Icon type="compass" />
                </div>
                <div className="text">
                  <p className="title">Dynamic Rate Comparison</p>
                  <p className="description">
                    Instantly comparison rates from multiple carriers to ensure
                    the best deal.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <Icon type="file-search" />
                </div>
                <div className="text">
                  <p className="title">Automated Documentation</p>
                  <p className="description">
                    Generate and manage shipping documents automatically,
                    reducing errors and saving time.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <Icon type="customer-service" />
                </div>
                <div className="text">
                  <p className="title">Customizable Preferences</p>
                  <p className="description">
                    Set preferences for carriers, shipping methods, and routes
                    to align with your business strategy.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section2} alt="image" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  ShipMate Pro - Comprehensive Shipment Booking Applicationt
                </p>
                <p className="description">
                  Automate and optimize shipment bookings for your users with
                  features like instant rate comparisons, auto-filled booking
                  forms, and real-time capacity checks. Let your users book
                  shipments swiftly, saving time for strategic logistics
                  planning.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Get in touch</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Maximum Shipment Bookings With Minimal Effort
                </p>
                <p className="description">
                  Minimize manual workload by swiftly booking multiple shipments
                  within minutes, eliminating the need for hours of manual
                  effort. Streamline your workflow and reclaim hours of valuable
                  time for strategic decision-making and business growth.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Got Questions?</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section3} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section4} alt="image" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Improve Customer Satisfaction with Real-Time Tracking and
                  Support
                </p>
                <p className="description">
                  Offer your customers peace of mind with real-time tracking
                  capabilities. Keep them informed with automated notifications
                  and provide top-notch support through a dedicated customer
                  service portal. Enhance engagement and build trust with
                  reliable updates and prompt responses to inquiries.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Let's get started?</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">
              Consistent Web Experience for Effortless Shipment Booking
            </p>
            <p className="description">
              Consistent functionality paired with an intuitive interface offers
              a smooth user experience. Its user-friendly design facilitates
              effortless navigation, ensuring accessibility for all users.
              Whether booking a single shipment or managing bulk orders,
              ShipMate Pro makes the process simple and efficient.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image" />
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <div className="card-container">
                <ContactCard title="Looking for something similar?" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave blue">
          <ProductBlueLine />
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequently Asked Questions (FAQs)</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="Is there any recurring license cost e.g any yearly license cost to this product?"
                  key="1"
                >
                  <p>
                    There is no recurring license cost. There is just one time
                    license cost of 3000$ for using the system lifetime for your
                    business
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system? " key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system? "
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag('title', 'Shipment Booking App', true),
      ...getMetaTag(
        'description',
        'Leverage ShipMate Pro to transform your shipment booking services with speed and efficiency. Whether expanding locally or internationally, our solution guarantees streamlined operations, dependability, and an even user experience for both shippers and customers.',
        true
      ),
      ...getMetaTag('image', section1, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'Shipment Booking App',
      },
    ];
  },
};

Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
