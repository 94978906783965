import { getMetaTag } from '../../../helpers/seo';
import PageComponent from '../page-component';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Innovate your Business',
  subTitle: 'With Automation',
  description: `We are living in the age of fast-paced technology that has paved the way for Automation, to a point where human intervention has become second. Our software automation solutions help businesses in automating various business processes to cut down expenses and generate more profit.
`,
  services: [
    'Content Scraping Solutions for Web and Mobile',
    'Web and Mobile Bot Development',
    'Social media automation via autobots',
    'Content Automation Scripts Development',
  ],
  bannerTitle: 'Willing to magnify your efficiency with Automation?',
  bannerDescription: `We help you transform your manpower-intensive business processes to technology-centric operations with the flexibility to scale up and down the automation solutions as per business needs.`,
  statistics: [
    {
      title: '100+',
      description: 'Automation Projects',
    },
    {
      title: '100M+',
      description: 'Scrapped Data Points',
    },
    {
      title: '10K+',
      description: 'Business Automations',
    },
    {
      title: '50+',
      description: 'Happy Clients',
    },
  ],
  tags: ['Web Automation', 'Data Scraping', 'Machine Learning'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Sophisticated Bot Solutions & Expert Scraping Tools For Your Needs',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
