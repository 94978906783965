import React from 'react';
import './styles.scss';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ image, title, shortDescription, tags, id, caseStudy }) => {
  return (
    <Link
      routeKey={caseStudy ? 'case-study' : 'blog'}
      routeParams={{
        key: id,
      }}
    >
      <div className="blog-card">
        <div className="image">
          <img loading="lazy" alt="image" src={image} />
        </div>
        <div className="details">
          <div className="tags">
            <ul>
              {(tags || []).map((item, index) => (
                <li key={index}>
                  <span className="tag">{item}</span>
                </li>
              ))}
            </ul>
          </div>
          <p className="title">{title}</p>
          <p className="description">{shortDescription}</p>
        </div>
      </div>
    </Link>
  );
};

Main.displayName = 'Product-Card';

export default Main;
