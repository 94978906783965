import withPost from 'containers/with-post';
import { blogMapper } from '../../helpers/mappers';
import BlogPost from 'components/blog-post';
import BlogCard from 'components/blog-card';

const Main = withPost({
  entityName: 'blog-post',
  mapper: blogMapper,
  classNames: {
    page: 'single-blog animate__animated animate__fadeIn',
  },
  PostComponent: BlogPost,
  RelatedPostComponent: BlogCard,
  requestParams: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Blogs',
        routeKey: 'blogs',
      },
      {
        label: window.app.pageData?.post?.title,
      },
    ];
  },
});
Main.url = 'blog/:key';
Main.displayName = 'Blog';

//Pre process the container with Redux Plugins
export default Main;
