import { productMapper } from '../../helpers/mappers';
import withPostList from 'containers/with-post-list';
import './styles.scss';
import ProductCard from '../../components/product-card';
import { getMetaTag } from '../../helpers/seo';

const Main = withPostList({
  entityName: 'product',
  mapper: productMapper,
  title: 'Premium',
  subTitle: 'Prebuilt Products',
  CardComponent: ProductCard,
  classNames: {
    page: 'portfolio products animate__animated animate__fadeIn',
  },
  requestParams: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'High Quality Software Products for your Business',
        true
      );
    },
    priority: '1.0',
  },
  showFilters: false,
});
Main.displayName = 'Products';

export default Main;
