import { GET_ENTITIES } from 'app-redux/actions';
import React, { useEffect, useState } from 'react';
import { createAction } from '@arivaa-react/redux/helpers/action';
import preprocess from 'containers/preprocess';
export default (Main, config) => {
  config = config || {};
  let App = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
      (async () => {
        setLoading(true);
        try {
          const { payload } = await props.getData({
            ...config.requestConfig,
          });
          setData(payload.data);
          config.onSuccess instanceof Function &&
            config.onSuccess(payload.data);
        } catch (e) {
          console.error('Error while getting data', {
            e,
          });
          setError(e);
          config.onError instanceof Function && config.onError(e);
        }
        setLoading(false);
      })();
    }, []);
    return <Main {...props} loading={loading} data={data} error={error} />;
  };

  /**
   * Bind Redux Actions
   * @param dispatch
   * @returns {{Object}}
   */
  const bindAction = (dispatch) => {
    return {
      getData: (data) => {
        return dispatch(
          createAction(GET_ENTITIES, {
            ...data,
          })
        );
      },
    };
  };
  App = preprocess(App, {
    connect: [null, bindAction],
    localize: true,
  });
  return App;
};
