import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/logo.svg';
import section1 from './assets/section1.png';
import section2 from './assets/section2.png';
import section3 from './assets/section3.png';

import screenshot1 from './assets/screenshot1.jpg';
import screenshot2 from './assets/screenshot2.jpg';
import screenshot3 from './assets/screenshot3.png';
import screenshot4 from './assets/screenshot4.png';
import screenshot5 from './assets/screenshot5.png';
import screenshot6 from './assets/screenshot6.jpg';
import screenshot7 from './assets/screenshot7.png';
import { getMetaTag } from '../../../helpers/seo';
import {
  AntCloudOutlined,
  AppstoreOutlined,
  CaretRightOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
  ];

  return (
    <div className="public-page product e-learning-application">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="image" />
                </div>
                <p className="title">
                  EduPlay - Ready-to-Launch E-Learning Platform Solution
                </p>
                <p className="description">
                  Get a EduPlay that streamlines your online education business,
                  ensuring a smooth and scalable experience for both learners
                  and instructors. Our solution offers robust features and a
                  user-friendly interface, making it easier than ever to expand
                  and manage your e-learning platform efficiently.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section1} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <AppstoreOutlined />
                </div>
                <div className="text">
                  <p className="title">Multi-platform Support</p>
                  <p className="description">
                    Compatible with iOS, Android, and web browsers.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <SafetyCertificateOutlined />
                </div>
                <div className="text">
                  <p className="title">Secure Payment Options</p>
                  <p className="description">
                    Multiple payment gateways for smooth & secure transactions.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <AntCloudOutlined />
                </div>
                <div className="text">
                  <p className="title">Cloud Integration</p>
                  <p className="description">
                    Secure and scalable cloud-based storage and hosting.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section2} alt="image" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Auto-Enroller To Simplify Student Enrollment
                </p>
                <p className="description">
                  Automate and optimize the enrollment process with features
                  like intelligent form processing, real-time data validation,
                  and customized enrollment paths. Enroll hundreds of students
                  swiftly to different courses, saving time for strategic
                  educational initiatives.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Get in touch</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Enhanced Learning Journey with Specialized Features
                </p>
                <p className="description">
                  Users can customize their learning paths by filtering courses
                  based on interests, skill levels, and career goals. They can
                  set learning reminders, track progress with interactive
                  dashboards, and engage in peer discussions to deepen their
                  understanding. The tailored recommendations ensure that the
                  users find the most relevant courses to achieve their
                  objectives.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Got Questions?</Button>
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section3} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">
              Unified Web Experience for Unbothered Learning Journey
            </p>
            <p className="description">
              Consistent functionality paired with an intuitive interface
              ensures a smooth user experience. Its user-friendly design
              facilitates effortless navigation, ensuring accessibility for all
              users, from learners to administrators.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image" />
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title="Looking for something similar?" />
            </Col>
          </Row>
        </div>

        <div className="wave blue">
          <ProductBlueLine />
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequently Asked Questions (FAQs)</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="Is there any recurring license cost e.g any yearly license cost to this product?"
                  key="1"
                >
                  <p>
                    There is no recurring license cost. There is just one time
                    license cost of 3000$ for using the system lifetime for your
                    business
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system? " key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system? "
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag(
        'title',
        'EduPlay - Ready-to-Launch E-Learning Platform Solution',
        true
      ),
      ...getMetaTag(
        'description',
        'Get a EduPlay that streamlines your online education business, ensuring a smooth and scalable experience for both learners and instructors. Our solution offers robust features and a user-friendly interface, making it easier than ever to expand and manage your e-learning platform efficiently.',
        true
      ),
      ...getMetaTag('image', section1, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'EduPlay',
      },
    ];
  },
};

Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
