import React from 'react';
import './styles.scss';
import SupportForm from 'containers/supportForm';
import preprocess from 'containers/preprocess';
/**
 * @description Support
 * @type Page
 * @author
 */
const Main = ({ translate }) => {
  return (
    <div className="support-form">
      <div className="heading">
        <p className="page-title">{translate('support.title')}</p>
        <p className="description">{translate('support.subTitle')}</p>
      </div>
      <SupportForm />
    </div>
  );
};

Main.routeProps = {
  public: true,
  getMetaTags: () => {
    return getMetaTag('title', 'Get support for your issues with Laxaar', true);
  },
};
export default preprocess(Main, {
  localize: true,
});
