import withPost from 'containers/with-post';
import JobPost from 'components/job-post';
const Main = withPost({
  entityName: 'job',
  PostComponent: JobPost,
  renderDirect: true,
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Careers',
        routeKey: 'careers',
      },
      {
        label: window.app.pageData?.post?.title,
      },
    ];
  },
});
Main.url = 'job-details/:key';
Main.displayName = 'Job-Details';
//Pre process the container with Redux Plugins
export default Main;
