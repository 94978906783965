import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import { ReactComponent as TwoArrows } from '../../../images/laxaar/two-arrows.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/connecty-logo.svg';
import connecty from './assets/connecty-hero.png';
import connectyThreeScreens from './assets/connecty-three-screens.png';
import connectyAccount from './assets/connecty-account.png';
import connectyTwoScreens from './assets/connecty-two-screens.png';

import screenshot1 from './assets/screenshot1.png';
import screenshot2 from './assets/screenshot2.png';
import screenshot3 from './assets/screenshot3.png';
import screenshot4 from './assets/screenshot4.png';
import screenshot5 from './assets/screenshot5.png';
import screenshot6 from './assets/screenshot6.png';
import screenshot7 from './assets/screenshot7.png';
import screenshot8 from './assets/screenshot8.png';
import screenshot9 from './assets/screenshot9.png';

import mobile1 from './assets/mobile-app/mobile-screenshot1.png';
import mobile2 from './assets/mobile-app/mobile-screenshot2.png';
import mobile3 from './assets/mobile-app/mobile-screenshot3.png';
import mobile4 from './assets/mobile-app/mobile-screenshot4.png';
import mobile5 from './assets/mobile-app/mobile-screenshot5.png';
import mobile6 from './assets/mobile-app/mobile-screenshot6.png';
import mobile7 from './assets/mobile-app/mobile-screenshot7.png';
import mobile8 from './assets/mobile-app/mobile-screenshot8.png';
import mobile9 from './assets/mobile-app/mobile-screenshot9.png';
import mobile10 from './assets/mobile-app/mobile-screenshot10.png';
import mobile11 from './assets/mobile-app/mobile-screenshot11.png';
import mobile12 from './assets/mobile-app/mobile-screenshot12.png';
import mobile13 from './assets/mobile-app/mobile-screenshot13.png';
import { getMetaTag } from '../../../helpers/seo';
import {
  CaretRightOutlined,
  CustomerServiceOutlined,
  DeploymentUnitOutlined,
  LayoutOutlined,
} from '@ant-design/icons';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
  ];

  const mobileMockups = [
    mobile1,
    mobile2,
    mobile3,
    mobile4,
    mobile5,
    mobile6,
    mobile7,
    mobile8,
    mobile9,
    mobile10,
    mobile11,
    mobile12,
    mobile13,
  ];

  const text = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices 
arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus 
ligula. Facilisis ultrices arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi 
sed facilisi sem id purus ligula. Facilisis ultrices arcu, viverra in interdum. Lorem ipsum dolor sit amet, consectetur 
adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices arcu, viverra in interdum. 
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi sed facilisi sem id purus ligula. Facilisis ultrices 
arcu, viverra in interdum
`;
  return (
    <div className="public-page product connecty">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="logo"/>
                </div>
                <p className="title">Connecty - Online Chat Application</p>
                <p className="description">
                  Giving people an experience of smooth communication with
                  Connecty. Whether you're a startup, enterprise, or social
                  platform, our team crafts dynamic solutions that elevate user
                  engagement and drive growth. Connect people effortlessly
                  through secure real time messaging, voice calls, and video
                  chats. Get intuitive interfaces, and customizable features
                  tailored to your needs. Let's build the chat app of your
                  dreams together.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={connecty} alt="image"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <LayoutOutlined />
                </div>
                <div className="text">
                  <p className="title">Third Party Integration</p>
                  <p className="description">
                    Integration with other apps and tools for a manageable
                    workflow through the application.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <DeploymentUnitOutlined />
                </div>
                <div className="text">
                  <p className="title">Onsite Deployment</p>
                  <p className="description">
                    For smooth business transition, our team will deploy the
                    system onto your chosen infrastructure at no extra cost.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CustomerServiceOutlined />
                </div>
                <div className="text">
                  <p className="title">Go Live Support</p>
                  <p className="description">
                    Our Customer Support team is here to assist you even after
                    going live. Reach out to us anytime with your queries.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={connectyThreeScreens} alt="image"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">Your all-in-one chatting platform</p>
                <p className="description">
                  With features like group chats, file sharing, and customizable
                  notifications, the users can stay connected and productive on
                  the go.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Get in touch</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="list">
                <p className="title">
                  What Makes Connecty The Perfect Chat Application
                </p>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Exchanging messages in real-time with friends and
                    colleagues.
                  </p>
                </div>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Sharing images, videos, and files seamlessly within chats.
                  </p>
                </div>
                {/* <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Creating groups for collaborative discussions and coordination.
                  </p>
                </div> */}
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Ensures privacy and security with end-to-end encryption.
                  </p>
                </div>
                {/* <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Personalizes the chat experiences with a variety of themes.
                  </p>
                </div> */}
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Got Questions?</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={connectyAccount} alt="image"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">Unified Web Experience</p>
            <p className="description">
              Consistent functionality and user interface on web for enhanced
              convenience and accessibility.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image"/>
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image"/>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title="Looking for something similar?" />
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={connectyTwoScreens} alt="image"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Best Design Choices Integrated With Modern Standards
                </p>
                <p className="description">
                  Striking the perfect balance between timeless design and
                  contemporary excellence, we perfectly blend the best design
                  principles with cutting-edge modern standards.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Let's get started?</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave blue">
          <ProductBlueLine />
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">
              Consistent Android and iOS Mobile Experience
            </p>
            <p className="description">
              Experience consistent excellence across all Android and iOS
              platforms
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img loading="lazy" src={mobileMockups[i]} alt="image"/>
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {mobileMockups.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img loading="lazy" src={item} alt="image"/>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title="Looking for something similar?" />
            </Col>
          </Row>
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequently Asked Questions (FAQs)</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="What customization options are available in Connecty?"
                  key="1"
                >
                  <p>
                    In Connecty, we offer a variety of customization options to
                    meet the diverse needs of our users. It includes branding
                    customization, theme customization, widget customization,
                    language localization, and many more.
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system?" key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system?"
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag(
        'title',
        'Connecty | Online Chat and Calling Application',
        true
      ),
      ...getMetaTag(
        'description',
        'Connecty is designed to facilitate effortless communication and collaboration. With user-friendly features and robust security measures, the app ensures efficient exchange of messages while prioritizing privacy and reliability.',
        true
      ),
      ...getMetaTag('image', connecty, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'Connecty | Online Chat and Calling Application',
      },
    ];
  },
};
Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
