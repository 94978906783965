import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Col, Row } from 'antd';
import Link from '../../@arivaa-react/components/link';
import { ReactComponent as Money } from '../../images/laxaar/icons/money.svg';
import { ReactComponent as Cart } from '../../images/laxaar/icons/cart.svg';
import { ReactComponent as Demand } from '../../images/laxaar/icons/demand.svg';
import { ReactComponent as Healthcare } from '../../images/laxaar/icons/healthcare.svg';
import { ReactComponent as Web } from '../../images/laxaar/icons/web.svg';
import { ReactComponent as Mobile } from '../../images/laxaar/icons/mobile.svg';
import { ReactComponent as Bot } from '../../images/laxaar/icons/bot.svg';
import { ReactComponent as Extension } from '../../images/laxaar/icons/extension.svg';
import { ReactComponent as Cloud } from '../../images/laxaar/icons/cloud.svg';
import { ReactComponent as Blocks } from '../../images/laxaar/icons/blocks.svg';
import { ReactComponent as Server } from '../../images/laxaar/icons/server.svg';
import { ReactComponent as Rocket } from '../../images/laxaar/icons/rocket.svg';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="expertise">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <div className="menu">
            <p className="section-title">Industry wide Portfolio</p>
            <Link className="link" routeKey="expertise.banking-and-finance">
              <div className="item">
                <div className="graphic">
                  <Money />
                </div>
                <div className="text">
                  <p className="title">Banking and Finance</p>
                  <p className="description">Crypto, Loans, Insurance</p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.ecommerce-hypermarkets">
              <div className="item">
                <div className="graphic">
                  <Cart />
                </div>
                <div className="text">
                  <p className="title">E-Commerce,Hypermarkets</p>
                  <p className="description">E-Stores,Grocery, Delivery</p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.ondemand-services">
              <div className="item">
                <div className="graphic">
                  <Demand />
                </div>
                <div className="text">
                  <p className="title">On Demand Services</p>
                  <p className="description">Home Services, Cab Booking</p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.healthcare">
              <div className="item">
                <div className="graphic">
                  <Healthcare />
                </div>
                <div className="text">
                  <p className="title">Medical and Healthcare</p>
                  <p className="description">E-Pharmacy, CRM, Bots</p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <div className="menu">
            <p className="section-title">Software Development</p>
            <Link className="link" routeKey="expertise.website-development">
              <div className="item">
                <div className="graphic">
                  <Web />
                </div>
                <div className="text">
                  <p className="title">Website Development</p>
                  <p className="description">MEAN, MERN, Java, PHP, Python</p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.mobile-app-development">
              <div className="item">
                <div className="graphic">
                  <Mobile />
                </div>
                <div className="text">
                  <p className="title">Mobile App Development</p>
                  <p className="description">
                    React Native, Flutter, Android, iOS
                  </p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.automation">
              <div className="item">
                <div className="graphic">
                  <Bot />
                </div>
                <div className="text">
                  <p className="title">Automation and Scripting</p>
                  <p className="description">
                    Web Automation,Bots, Data Scraping
                  </p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.extension-development">
              <div className="item">
                <div className="graphic">
                  <Extension />
                </div>
                <div className="text">
                  <p className="title">Extension Development</p>
                  <p className="description">Desktop and Browser Extensions</p>
                </div>
              </div>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <div className="menu">
            <p className="section-title">Cloud and DevOps</p>
            <Link
              className="link"
              routeKey="expertise.hosting-and-maintainence"
            >
              <div className="item">
                <div className="graphic">
                  <Server />
                </div>
                <div className="text">
                  <p className="title">Cloud Hosting Solutions</p>
                  <p className="description">
                    Free Maintainence and 24x7 Support
                  </p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.cloud-infrastructure">
              <div className="item">
                <div className="graphic">
                  <Cloud />
                </div>
                <div className="text">
                  <p className="title">Cloud Infrastructure</p>
                  <p className="description">AWS, Google Cloud, Azure etc.</p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.cloud-development">
              <div className="item">
                <div className="graphic">
                  <Blocks />
                </div>
                <div className="text">
                  <p className="title">Cloud Development</p>
                  <p className="description">Serverless, Docker, Databases</p>
                </div>
              </div>
            </Link>
            <Link className="link" routeKey="expertise.cloud-deployments">
              <div className="item">
                <div className="graphic">
                  <Rocket />
                </div>
                <div className="text">
                  <p className="title">Cloud Deployments</p>
                  <p className="description">CI/CD, Code Pipelines, Releases</p>
                </div>
              </div>
            </Link>
            {/* <Link className="link" routeKey="expertise.on-premise-servers">
              <div className="item">
                <div className="graphic">
                  <Server />
                </div>
                <div className="text">
                  <p className="title">On Premise Servers</p>
                  <p className="description">
                    Plesk, Cpanel, VPS, Load Balancers
                  </p>
                </div>
              </div>
            </Link> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'Sample-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
