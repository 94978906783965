import React from 'react';
import './styles.scss';
import ContactForm from '../../containers/contactForm';
import { ReactComponent as ReviewWave } from '../../images/laxaar/contact-card-wave.svg';
import SocialLinks from 'components/social-links';

/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = ({ title }) => {
  return (
    <div className="contact-card">
      <div className="review-wave">
        <ReviewWave />
      </div>
      <p className="title">{title || 'Consult us for free?'}</p>
      <ContactForm showLabels />
      <SocialLinks />
    </div>
  );
};

Main.displayName = 'Contact Card';

export default Main;
