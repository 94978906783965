import React, { Component } from 'react';
import './styles.scss';
import { Row, Col, Icon, message } from 'antd';
import Footer from 'containers/footer';
import { createAction } from '../../@arivaa-react/redux';
import { CREATE_ENTITY } from '../../redux/actions';
import ContactForm from 'containers/contactForm';
import { ReactComponent as ReviewWave } from '../../images/laxaar/contact-card-wave.svg';
import { ReactComponent as Phone } from '../../images/laxaar/phone.svg';
import { ReactComponent as Mail } from '../../images/laxaar/mail.svg';
import SocialLinks from 'components/social-links';
import { getMetaTag } from '../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="screen contact-page">
      <div className="vector" />
      <div className="vector left" />
      <div className="container">
        <div className="heading">
          <span className="primary-text">Contact </span>
          <span className="primary-light-text">Us</span>
        </div>
        <div className="content">
          <Row gutter={100}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="contact-card">
                <div className="review-wave">
                  <ReviewWave />
                </div>
                <div className="info">
                  <p className="title">Contact Information</p>
                  <p className="message">
                    Fill up the form and our team will get back to you within 24
                    hours or reach us via the live chat on bottom right. We
                    normally reply within few minutes
                  </p>
                  <ul>
                    <li>
                      <a
                        className="phone item"
                        href={`tel:${window.app.ui.PHONE}`}
                      >
                        <Phone />
                        <span className="text">
                          {' '}
                          {(window.app.ui.PHONE || '').replace(
                            /(\d{2})(\d{5})(\d{5})/,
                            '$1-$2-$3'
                          )}
                        </span>
                      </a>
                    </li>
                    {/* <li>
                      <p className="item">
                        <Location />
                        <span className="text">Ludhiana, Punjab, India</span>
                      </p>
                    </li> */}
                    <li>
                      <a
                        className="item"
                        href={`mailto:${window.app.ui.CONTACT_EMAIL}`}
                      >
                        <Mail />
                        <span className="text">
                          {window.app.ui.CONTACT_EMAIL}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="social">
                  <SocialLinks />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <ContactForm
                showLabels={true}
                formProps={{
                  className: 'contact-form',
                }}
                inputProps={{
                  message: { className: 'input textarea', size: 15 },
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Main.displayName = 'Contact';
/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    submitEnquiry: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'contact',
          ...data,
        })
      );
    },
  };
};

Main.routeProps = {
  getMetaTags: () => {
    return getMetaTag(
      'title',
      'Get free consultation for your next software project',
      true
    );
  },
  priority: 1.0,
};

export default Main;
