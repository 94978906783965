import React from 'react';
import './styles.scss';
import Footer from 'containers/footer';
import { Col, Row } from 'antd';
import ProductInstallationForm from 'containers/productInstallationForm';
import HostingCard from 'components/hosting-card';
import { getMetaTag } from '../../helpers/seo';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="screen feature-request animate__animated animate__fadeIn">
      <div className="vector" />
      <div className="vector left" />
      <div className="container">
        <div className="section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <div className="heading">
                <p className="primary-text">Product Installation</p>
                <p className="primary-light-text">Request</p>
              </div>
              <div className="description">
                <p>
                  Please note that your installation request can take upto 1-2
                  days to process based on the quality and validity of
                  information provided by you
                </p>
              </div>
              <div className="details">
                <div className="form">
                  <ProductInstallationForm />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
              <HostingCard />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};
Main.routeProps = {
  getMetaTags: () => {
    return getMetaTag(
      'title',
      'Product Installation Request | Mobile app products | Website Products',
      true
    );
  },
};
export default Main;
