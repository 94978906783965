import React from 'react';
import './styles.scss';
import { ReactComponent as Facebook } from '../../images/laxaar/fb.svg';
import { ReactComponent as Github } from '../../images/laxaar/github.svg';
import { ReactComponent as LinkedIn } from '../../images/laxaar/linkedin.svg';
import { ReactComponent as Twitter } from '../../images/laxaar/tw.svg';
/**
 * @description Social Links Component
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="social">
      <ul>
        {window.app.ui.LINKEDIN && (
          <li>
            <a
              target="_blank"
              href={window.app.ui.LINKEDIN}
              aria-label="Linked In"
            >
              <LinkedIn />
            </a>
          </li>
        )}
        {window.app.ui.GITHUB && (
          <li>
            <a target="_blank" href={window.app.ui.GITHUB} aria-label="Github">
              <Github />
            </a>
          </li>
        )}

        {window.app.ui.FACEBOOK && (
          <li>
            <a
              target="_blank"
              href={window.app.ui.FACEBOOK}
              aria-label="Facebook"
            >
              <Facebook />
            </a>
          </li>
        )}
        {window.app.ui.TWITTER && (
          <li>
            <a
              target="_blank"
              href={window.app.ui.TWITTER}
              aria-label="Twitter"
            >
              <Twitter />
            </a>
          </li>
        )}
        {window.app.ui.FREELANCER && (
          <li>
            <a
              target="_blank"
              href={window.app.ui.FREELANCER}
              aria-label="Freelancer"
            >
              <Twitter />
            </a>
          </li>
        )}
        {window.app.ui.UPWORK && (
          <li>
            <a target="_blank" href={window.app.ui.UPWORK} aria-label="Upwork">
              <Twitter />
            </a>
          </li>
        )}
        {window.app.ui.FIVER && (
          <li>
            <a target="_blank" href={window.app.ui.FIVER} aria-label="Fiver">
              <Twitter />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

Main.displayName = 'Social-Links';

export default Main;
