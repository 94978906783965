import React from 'react';
import './styles.scss';
import { message, Button, Input, Form } from 'antd';
import preProcess from 'containers/preprocess';
import { ReactComponent as ModalWave } from '../../images/laxaar/modal-wave.svg';
import FilePicker from '@arivaa-react/components/filePicker';
import { ModalUtils } from '@arivaa-react/components/modal';
import { CREATE_ENTITY } from 'app-redux/actions';
import { createAction } from '@arivaa-react/redux';
const { TextArea } = Input;
const { Item } = Form;
const spinningSelector = '.apply-form';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ form, title, id, submitEnquiry, uploadFile, hideModal }) => {
  const { getFieldDecorator } = form;
  const validations = {
    email: {
      rules: [
        { required: true, message: 'Please enter your email' },
        { type: 'email', message: 'Please enter a valid email' },
      ],
    },
    resume: {
      rules: [{ required: true, message: 'Please upload your resume' }],
    },
  };

  const Email = (
    <Item hasFeedback={true} label={'Email Address'}>
      {getFieldDecorator(
        'email',
        validations.email
      )(<Input placeholder={'Email'} className="input" />)}
    </Item>
  );

  const Phone = (
    <Item hasFeedback={true} label={'Phone'}>
      {getFieldDecorator('phone')(
        <Input placeholder={'Phone Number'} className="input" />
      )}
    </Item>
  );

  const CoverLetter = (
    <Item hasFeedback={true} label={'Cover Letter'}>
      {getFieldDecorator('cover')(<TextArea placeholder="Cover Letter" />)}
    </Item>
  );

  const Attachment = (
    <Item hasFeedback={true} label={'Upload Resume'}>
      {getFieldDecorator(
        'resume',
        validations.resume
      )(
        <FilePicker
          uploadProps={{
            accept: '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf',
          }}
          maxFileSize={50 * 1024 * 1024}
        />
      )}
    </Item>
  );

  /**
   * On Submit of  Form
   * @param event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { validateFieldsAndScroll, resetFields } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        values = {
          ...values,
        };
        values.resume = (
          await uploadFile({
            name: values.resume.name,
            type: values.resume.type,
            file: values.resume.dataUri,
            fileCode: 'mediaFile',
          })
        ).payload.data.url;
        const { error, payload } = await submitEnquiry({
          ...values,
          jobId: id,
        });
        if (error) {
          throw payload.response;
        }
        resetFields();
        message.success(
          'Thank you for your application, We will get back to you soon.'
        );
        hideModal();
      } catch (e) {
        message.error('There was an error while submitting your application.');
        console.error(e);
      }
      window.stopSpinning(spinningSelector);
    });
  };

  return (
    <div className="apply-form form">
      <div className="fields">
        <p className="title">Apply to {title}</p>
        <Form onSubmit={handleSubmit}>
          <div className="inline">
            {Email}
            {Phone}
          </div>
          {CoverLetter}
          {Attachment}
          <div className="action">
            <Button className="yellow-btn" htmlType="submit">
              Submit Application
            </Button>
          </div>
        </Form>
      </div>
      <div className="wave">
        <ModalWave />
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    submitEnquiry: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'job-application',
          ...data,
        })
      );
    },
    uploadFile: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'storage',
          ...data,
        })
      );
    },
  };
};

const component = preProcess(Form.create()(Main), {
  connect: [null, bindAction],
  localize: true,
  withRouter: true,
});
ModalUtils.registerModal('jobForm', component);
//Pre process the container with Redux Plugins
export default component;
