import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Redefining Healthcare',
  subTitle: 'Through Technology',
  description: `The Covid-19 Pandemic has drastically increased the need for customized software solutions in the Healthcare Industry, starting from complex patient services to everyday staff management. At Laxaar, we help clients create user-friendly tools that meet end-user needs.
`,
  services: [
    'Hosptial CRM and Analytics Suites',
    'Instant VOIP, Realtime Video and Chat Apps',
    'Appointment Management Tools for Clinics',
    'MHealth, Fitness and Wearables Development',
  ],
  bannerTitle: 'Want to enhance and automate medical services?',
  bannerDescription: `At Laxaar we believe in an end user-focused approach where we try to put technology in the forefront to ensure ease of operation to every individual in the medical and healthcare industry and simplify complex delivery of medical services.`,
  statistics: [
    {
      title: '5+',
      description: 'Live Systems',
    },
    {
      title: '20000+',
      description: 'Lines of Code',
    },
    {
      title: '10K+',
      description: 'Doctor, Patient Reviews',
    },
    {
      title: '100K+',
      description: 'Concurrent Users',
    },
  ],
  tags: ['Healthcare', 'Medical'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Hire the best healthcare software development company',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
