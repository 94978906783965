import React from 'react';
import './styles.scss';
import PublicHeader from './publicHeader';

const Main = ({ type, ...props }) => {
  if (typeof type === 'undefined') {
    type = 'public';
  }
  switch (type) {
    case 'public':
      return <PublicHeader {...props} />;
    default:
      return null;
  }
};
export default Main;
