import React from 'react';
import './styles.scss';
import Footer from 'containers/footer';
import { Col, Row } from 'antd';
import QuoteForm from 'containers/quoteForm';
import BusinessCard from '../../components/business-card';
import HostingCard from '../../components/hosting-card';
import { getMetaTag } from '../../helpers/seo';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="screen feature-request animate__animated animate__fadeIn">
      <div className="vector" />
      <div className="vector left" />
      <div className="container">
        <div className="section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <div className="heading">
                <p className="primary-text">Start Your Journey to</p>
                <p className="primary-light-text">Market Success</p>
              </div>
              <div className="description">
                <p>
                  Got an amazing business idea? Take a minute to fill the form
                  below and Let’s bring it to market together.
                </p>
              </div>
              <div className="details">
                <div className="form">
                  <QuoteForm />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
              <HostingCard />
              <br />
              <br />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
              <BusinessCard />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Main.routeProps = {
  getMetaTags: () => {
    return getMetaTag(
      'title',
      'Get free quote for your next software idea',
      true
    );
  },
  priority: 0.9,
};
export default Main;
