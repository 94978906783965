import React, { useEffect, useState } from 'react';
import preprocess from './preprocess';
export default (Main, config) => {
  config = config || {};
  const showPageSpinner = !!Main.showPageSpinner;
  let App = (props) => {
    const [pageData, setPageData] = useState(props.pageData);
    const [loading, setLoading] = useState(false);
    const getData = async () => {
      try {
        if (
          props.getPageData instanceof Function &&
          props.pageData === undefined
        ) {
          if (showPageSpinner) {
            window.startSpinning('.app-container');
          } else {
            setLoading(true);
          }
          setPageData(
            await props.getPageData(props.route, {
              dispatch: props.dispatch,
              ...props.match,
            })
          );
        } else {
          setPageData(props.pageData);
        }
      } catch (e) {
        console.error('Error while getting page data', { e });
      }
      if (showPageSpinner) {
        window.stopSpinning('.app-container');
      } else {
        setLoading(false);
      }
    };
    useEffect(() => {
      getData();
    }, [props.match.url]);
    const metaTags =
      (props?.route?.getMetaTags && props.route.getMetaTags(pageData)) || {};
    if (window.document) {
      window.document.title = metaTags.title || window.app.defaultTitle;
    }
    return (
      <Main
        {...props}
        loading={loading}
        pageData={pageData}
        getPageData={getData}
      />
    );
  };
  App = preprocess(App, {
    connect: [null, null],
  });
  return App;
};
