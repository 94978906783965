import { getRouteByPath } from '@arivaa-react/react-router5';

export function caseStudyMapper({
  key,
  title,
  tags,
  shortDescription: description,
  image: media,
}) {
  return {
    title,
    description,
    media,
    tags,
    className: 'custom-class',
    linkProps: {
      routeKey: 'case-study',
      routeParams: {
        key,
      },
    },
  };
}

export function blogMapper({
  key,
  title,
  tags,
  shortDescription: description,
  image: media,
  category,
}) {
  return {
    title,
    description,
    media,
    tags,
    category,
    className: 'custom-class',
    linkProps: {
      routeKey: 'blog',
      routeParams: {
        key,
      },
    },
  };
}

export function testimonialMapper({
  title: review,
  rating,
  name,
  designation,
  image: media,
}) {
  return {
    name,
    rating,
    review,
    media,
    designation,
    className: 'custom-class',
  };
}

export function portfolioMapper({
  key,
  title,
  tags,
  shortDescription: description,
  image: media,
  rating,
  review,
}) {
  return {
    title,
    description,
    media,
    tags,
    className: 'custom-class',
    linkProps: {
      routeKey: 'project',
      routeParams: {
        key,
      },
    },
    rating,
    review,
  };
}

export function productMapper({
  title,
  tags,
  shortDescription: description,
  productIcon,
  purchaseUrl,
  image: media,
  productUrl,
  price,
}) {
  return {
    title,
    description,
    media,
    buttonLinkProps:
      (purchaseUrl || '').indexOf('http') !== -1
        ? {
            href: purchaseUrl,
          }
        : {
            routeKey: getRouteByPath(purchaseUrl)
              ? getRouteByPath(purchaseUrl).routeKey
              : undefined,
          },
    tags,
    appIcon: productIcon,
    className: 'custom-class',
    buttonProps: {
      children: `Buy${price ? ' - ' + price : ''}`,
    },
    linkProps:
      (productUrl || '').indexOf('http') !== -1
        ? {
            href: purchaseUrl,
          }
        : {
            routeKey: getRouteByPath('/product' + productUrl)
              ? getRouteByPath('/product' + productUrl).routeKey
              : undefined,
          },
  };
}

export function technologyMapper({ title, image: media }) {
  return {
    title,
    media,
    className: 'custom-class',
  };
}
