import React, { Component } from 'react';
import './styles.scss';
import { Row, Col, Icon, message } from 'antd';
import Footer from 'containers/footer';
import { createAction } from '@arivaa-react/redux';
import { CREATE_ENTITY } from 'app-redux/actions';
import ContactForm from 'containers/contactForm';
import { ReactComponent as ReviewWave } from '../../images/laxaar/contact-card-wave.svg';
import Link from '@arivaa-react/components/link';
import { ReactComponent as YellowCheck } from '../../images/laxaar/yellow-check.svg';
import SocialLinks from 'components/social-links';
import { getMetaTag } from '../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="screen contact-page">
      <div className="vector" />
      <div className="vector left" />
      <div className="container">
        <div className="heading">
          <span className="primary-text">Become Our </span>
          <span className="primary-light-text">Partner</span>
        </div>
        <div className="content">
          <Row gutter={100}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="contact-card">
                <div className="review-wave">
                  <ReviewWave />
                </div>
                <div className="info">
                  <p className="title">Best Affiliate Program</p>
                  <p className="message">
                    We are always looking for likeminded partnerships for mutual
                    growth.
                  </p>
                  <p className="message">
                    If you believe you can help us hunt for new software
                    development projects. All you need is to provide us with
                    relevant leads, and we’ll reward your efforts according to
                    our Referral Program. Feel free to checkout the{' '}
                    <Link routeKey="staticPages.partner-program">details</Link>
                  </p>

                  <ul>
                    <li>
                      <Link className="phone item">
                        <YellowCheck />
                        <span className="text">Competitive Referral Bonus</span>
                      </Link>
                    </li>
                    <li>
                      <p className="item">
                        <YellowCheck />
                        <span className="text">Great Partnerships</span>
                      </p>
                    </li>
                    <li>
                      <p className="item">
                        <YellowCheck />
                        <span className="text">Co-Branding and Growth</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <SocialLinks />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <ContactForm
                showLabels={true}
                formProps={{
                  className: 'contact-form',
                }}
                inputProps={{
                  message: { className: 'input textarea', size: 15 },
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Main.displayName = 'Contact';
/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    submitEnquiry: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'contact',
          ...data,
        })
      );
    },
  };
};
Main.routeProps = {
  getMetaTags: () => {
    return getMetaTag(
      'title',
      'Partner with one of the leading software companies',
      true
    );
  },
};
export default Main;
