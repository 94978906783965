import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import { ReactComponent as TwoArrows } from '../../../images/laxaar/two-arrows.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/freshmart-logo.png';
import figma from './assets/figma.png';
import heroimg from './assets/freshmart.png';
import freshmarthome from './assets/freshmart-homepage.png';
import freshmartaddress from './assets/freshmart-address.png';
import mobileMockup from './assets/mobile-mockup.png';
import screenshot1 from './assets/screenshot1.png';
import screenshot2 from './assets/screenshot2.png';
import screenshot4 from './assets/screenshot4.png';
import screenshot5 from './assets/screenshot5.png';
import screenshot6 from './assets/screenshot6.png';
import screenshot7 from './assets/screenshot7.png';
import screenshot8 from './assets/screenshot8.png';
import screenshot9 from './assets/screenshot9.png';
import screenshot10 from './assets/screenshot10.png';
import screenshot11 from './assets/screenshot11.png';

import mobile1 from './assets/mobile-app/01_SplashScreen.png';
import mobile2 from './assets/mobile-app/02_Intro.png';
import mobile3 from './assets/mobile-app/03_Login.png';
import mobile4 from './assets/mobile-app/04_Signup.png';
import mobile5 from './assets/mobile-app/05_OTP.png';
import mobile6 from './assets/mobile-app/06_Homescreen.png';
import mobile7 from './assets/mobile-app/07_Categories.png';
import mobile8 from './assets/mobile-app/08_productList.png';
import mobile9 from './assets/mobile-app/09_productPage.png';
import mobile10 from './assets/mobile-app/10_ShoppingCart.png';
import mobile11 from './assets/mobile-app/11_OfferNotification.png';
import mobile12 from './assets/mobile-app/12_Profile.png';
import mobile13 from './assets/mobile-app/13_myorders.png';
import mobile14 from './assets/mobile-app/14_OrderPlaced.png';
import mobile15 from './assets/mobile-app/15_ProfileEdit.png';
import { getMetaTag } from '../../../helpers/seo';
import { CaretRightOutlined, CloudSyncOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10,
    screenshot11,
  ];

  const mobileMockups = [
    mobile1,
    mobile2,
    mobile3,
    mobile4,
    mobile5,
    mobile6,
    mobile7,
    mobile8,
    mobile9,
    mobile10,
    mobile11,
    mobile12,
    mobile13,
    mobile14,
    mobile15,
  ];

  return (
    <div className="public-page product freshmart">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img loading="lazy" alt="image" src={logo} />
                </div>
                <p className="title">
                  Freshmart - Figma Template for Grocery Website and Mobile App
                </p>
                <p className="description">
                  Clean and creative Figma template created with the Organic and
                  Grocery Stores in mind
                </p>
                <div className="logo">
                  <img loading="lazy" src={figma} />
                </div>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img loading="lazy" alt="image" src={heroimg} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="wave yellow">
          <ProductYellowLine />
        </div>
        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CloudSyncOutlined />
                </div>
                <div className="text">
                  <p className="title">Elegant Design</p>
                  <p className="description">
                    Design that is incredibly simple to work with and adapt to
                    your needs.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CloudSyncOutlined />
                </div>
                <div className="text">
                  <p className="title">60+ Screens</p>
                  <p className="description">
                    Clean and well-organized Layouts based on Figma Styles and
                    Components
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CloudSyncOutlined />
                </div>
                <div className="text">
                  <p className="title">Top-Notch Support</p>
                  <p className="description">
                    Active and experienced design support team to provide you an
                    excellent customer support experience.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img loading="lazy" alt="image" src={freshmarthome} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Suitable for all types of online organic and grocery stores
                </p>
                <p className="description">
                  With each file being carefully layered and containing an
                  overview of the hierarchy of groups and layers for easy
                  access, You can easily replace customize it as per your needs.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Get in touch</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="list">
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    From user onboarding to checkouts, We have got it all
                    covered
                  </p>
                </div>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Clean lines, soft colors, and fluid UX that customes look
                    for
                  </p>
                </div>
                <div className="item">
                  <div className="icon">
                    <YellowCheck />
                  </div>
                  <p className="details">
                    Easy one click process of applying your own branding and
                    colors
                  </p>
                </div>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Got Questions?</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img loading="lazy" alt="image" src={freshmartaddress} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">Unified and Consistent Web Experience</p>
            <p className="description">
              Experience the design innovation on web
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img loading="lazy" alt="image" src={images[i]} />
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img loading="lazy" alt="image" src={item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title="Looking for something similar?" />
            </Col>
          </Row>
        </div>
        <div className="wave blue">
          <ProductBlueLine />
        </div>
        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic mobile-mockup">
                {/* <ProductMacPhone /> */}
                <img loading="lazy" alt="image" src={mobileMockup} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Best design choices integrated with modern standards
                </p>
                <p className="description">
                  Our modern & rich choice of design standards will encourage
                  you to think in a different way. You will get a better
                  experience with our immaculate integration of design with
                  technology
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Let's get started?</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="section screens">
          <div className="heading">
            <p className="title">
              Consistent android and ios mobile experience
            </p>
            <p className="description">
              Experience the new mobile design standards
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img loading="lazy" alt="image" src={mobileMockups[i]} />
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {mobileMockups.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img loading="lazy" alt="image" src={item} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title="Looking for something similar?" />
            </Col>
          </Row>
        </div>
      </div>
      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequent Question</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="What do i get as a deliverables when i purchase this product?"
                  key="1"
                >
                  <p>
                    We will send you a link to download the figma file along
                    with all the assets.
                  </p>
                </Panel>
                <Panel
                  header="Can you customise this design as per my brand and choice?"
                  key="1"
                >
                  <p>
                    Yes absolutely, Please feel free to contact us discuss the
                    customizations
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any development and infrastructure services so that i don't have to worry about developing this design?"
                  key="3"
                >
                  <p>
                    Yes we do, We can develop this design in your choice of
                    technologies and platforms. Additionally we can also provide
                    fully tailor made hosting solution for this product a very
                    nominal charge depending on the your traffic for this
                    system.
                  </p>
                </Panel>
                <Panel header="How about updates of the template? " key="4">
                  <p>
                    As we give you the live figma access so all the future
                    versions are automatically accessible to you
                  </p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="5"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
                <Panel
                  header="Is there any recurring license cost e.g any yearly license cost to this product?"
                  key="6"
                >
                  <p>
                    There is no recurring license cost. There is just one time
                    license cost of 500$ for using the system lifetime for your
                    business/institute/company
                  </p>
                </Panel>
                <Panel header="What level of support do you  provide?" key="7">
                  <p>
                    We provide full support for any issues in accessing or using
                    the figma designs along with full bug support as well.
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="yellow-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag('title', 'Freshmart | Grocery Application', true),
      ...getMetaTag(
        'description',
        'Clean and creative Figma template created with the Organic and Grocery Stores in mind',
        true
      ),
      ...getMetaTag('image', heroimg, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'Freshmart | Grocery Application',
      },
    ];
  },
};
Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
