import React, { useEffect } from 'react';
import './styles.scss';
import { ReactComponent as ReviewWave } from '../../images/laxaar/contact-card-wave.svg';
import RazorpayPaymentButton from '../razorpay-payment-button';
/**
 * @description Sample Container
 * @type Container
 * @author Jasjot
 */
const Main = () => {
  return (
    <div className="business-card">
      <div className="review-wave">
        <ReviewWave />
      </div>
      <p className="title">Our Hosting and 24x7 Maintainence Support Plans</p>
      <p className="reward">
        Starting from <span className="value">$44/month</span>
      </p>

      {/* <Link routeKey="become-partner">
        <Button className="btn yellow-btn">Purchase</Button>
      </Link> */}
      <RazorpayPaymentButton id="pl_Kf2QVr5pYMi3l7" />
    </div>
  );
};

Main.displayName = 'Contact Card';

export default Main;
