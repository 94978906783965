import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Ingenious',
  subTitle: 'Software Extensions',
  description: `If you want to relieve users from working on repetitive tasks and transform the way software interacts with them, Laxaar is here for you. Experience the power of software extensions in making processes faster, safer and better.
`,
  services: [
    'Extension Development for Chrome, Firefox and Safari',
    'Automator Extensions and Script Development for Mac',
    'Shell Extensions for Linux and Windows',
    'VS Code Extensions Development',
  ],
  bannerTitle: 'Why choose Laxaar for extension development?',
  bannerDescription: `We have helped various business ease their business scenarios using software extensions, including password management, content aggregation, signature management, web searching, and more.`,
  statistics: [
    {
      title: '30+',
      description: 'Extensions Developed',
    },
    {
      title: '20+',
      description: 'Clients',
    },
    {
      title: '1000+',
      description: 'Daily Hour Savings',
    },
    {
      title: '10K',
      description: 'Earned Revenue',
    },
  ],
  tags: ['Extension', 'Machine Learning', 'Web Automation'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Hire the best chrome extension development company',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
