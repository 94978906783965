import withPost from 'containers/with-post';
import { caseStudyMapper } from '../../helpers/mappers';
import BlogPost from 'components/blog-post';
import BlogCard from 'components/blog-card';
const Main = withPost({
  entityName: 'case-study',
  mapper: caseStudyMapper,
  postRequestParams: {
    populate: true,
  },
  requestParams: {
    populate: true,
  },
  classNames: {
    page: 'single-blog',
  },
  PostComponent: BlogPost,
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Case Studies',
        routeKey: 'case-studies',
      },
      {
        label: window.app.pageData?.post?.title,
      },
    ];
  },
  RelatedPostComponent: (props) => {
    return <BlogCard caseStudy={true} {...props} />;
  },
});
Main.url = 'case-study/:key';
Main.displayName = 'Case-Study';
//Pre process the container with Redux Plugins
export default Main;
