import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Transforming Businesses',
  subTitle: 'With Cloud',
  description: `At Laxaar we unlock the true potential of Cloud for your business. Levering its scalability and flexibility, we build and run business apps that are fully managed on Cloud and come with features like scalable platform architecture and future-proof development.
`,
  services: [
    'Serverless Application Development',
    'Cloud Native Apps Development',
    'Microservices Development and Design',
    'Cloud Migration and Application Re-Architecting',
  ],
  bannerTitle: 'Looking to enter the Cloud without technical debt?',
  bannerDescription: `Our Cloud Development Services will help your business stand out among competitors, increase your ROI, and improve Retention Rates. We help you deliver more personalized care with the help of Cloud Applications.`,
  statistics: [
    {
      title: '50+',
      description: 'Cloud Apps',
    },
    {
      title: '10000+',
      description: 'Cloud Hours',
    },
    {
      title: '20+',
      description: 'Businesses Migrated to Cloud',
    },
    {
      title: '50+',
      description: 'Technologies',
    },
  ],
  tags: ['AWS', 'Heroku', 'Google Cloud'],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Expert development in Serverless, Microservices, Cloud Architecture',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
