import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/app-logo.svg';
import heroimg from './assets/hero-section-img.png';
import customers from './assets/customers.png';
import createinvoice from './assets/create-invoice.png';
import herographics from './assets/hero-graphics.png';

import screenshot1 from './assets/screenshot1.jpg';
import screenshot2 from './assets/screenshot2.jpg';
import screenshot3 from './assets/screenshot3.jpg';
import screenshot4 from './assets/screenshot4.jpg';
import screenshot5 from './assets/screenshot5.jpg';
import screenshot6 from './assets/screenshot6.jpg';
import screenshot7 from './assets/screenshot7.jpg';
import screenshot8 from './assets/screenshot8.jpg';
import { getMetaTag } from '../../../helpers/seo';
import {
  CalculatorOutlined,
  CaretRightOutlined,
  ContainerOutlined,
  DeploymentUnitOutlined,
} from '@ant-design/icons';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
  ];

  return (
    <div className="public-page product invoice-generator">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="logo"/>
                </div>
                <p className="title">
                  Invoice Generator - Invoice Generating Software
                </p>
                <p className="description">
                  Unlock the power of swift invoicing with our Invoice
                  Generating Solution. Whether you're a freelancer, small
                  business, or enterprise, our custom invoice generator software
                  ensures streamlined billing processes. From elegant design to
                  automated calculations, Invoice Generator makes invoicing
                  painless and efficient, empowering your business to thrive and
                  expand effortlessly.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={heroimg} alt="image"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CalculatorOutlined />
                </div>
                <div className="text">
                  <p className="title">Automated Calculations</p>
                  <p className="description">
                    No more manual math! Users can now automatically calculate
                    totals, taxes, and discounts.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <ContainerOutlined />
                </div>
                <div className="text">
                  <p className="title">Recurring Invoices</p>
                  <p className="description">
                    Users can set up recurring billing processes effortlessly
                    and in a manageable way for regular clients.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <DeploymentUnitOutlined />
                </div>
                <div className="text">
                  <p className="title">Onsite Deployment</p>
                  <p className="description">
                    For smooth business transition, our team will deploy the
                    system onto your chosen infrastructure at no extra cost.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={customers} alt="image"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Manage Global Clients with Multi-Currency Support
                </p>
                <p className="description">
                  Users can serve clients worldwide with ease by generating
                  invoices in multiple currencies. It simplifies international
                  transactions, ensuring your global business operations run
                  smoothly without the hassle of currency conversions.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Get in touch</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Streamline Client Management & Their Invoicing
                </p>
                <p className="description">
                  Reduce administrative workload by managing client details and
                  invoices efficiently. Invoice Generator allows you to maintain
                  comprehensive client profiles, track payment statuses, and
                  automate invoice generation, helping you stay organized and
                  focused on growing your business.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Got Questions?</Button>
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={createinvoice} alt="image"/>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={herographics} alt="image"/>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Multi-Platform Support for Flexible Access
                </p>
                <p className="description">
                  Users can access invoicing tools from any device, anywhere,
                  with Invoice Generator's multi-platform support. Whether
                  they’re using a desktop, tablet, or smartphone, they can enjoy
                  consistent functionality and smooth integration across all
                  platforms.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Let's get started?</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">
              Unified User Interface for an Intuitive Experience
            </p>
            <p className="description">
              A cohesive and intuitive interface that simplifies navigation and
              usage. Invoice Generator’s user-friendly design ensures that
              creating, managing, and tracking invoices is straightforward,
              allowing users to focus on what matters the most.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image"/>
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image"/>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <ContactCard title="Looking for something similar?" />
            </Col>
          </Row>
        </div>

        <div className="wave blue">
          <ProductBlueLine />
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequently Asked Questions (FAQs)</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="Is it compatible with other accounting or business management software?"
                  key="1"
                >
                  <p>
                    We understand the importance of integration with other
                    accounting and business management software. Whether you use
                    QuickBooks, Xero, or any other platform, it is assured that
                    our Invoice Generator can work alongside them harmoniously.
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system? " key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system? "
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag(
        'title',
        'BillForge | Invoice Generating Web Application',
        true
      ),
      ...getMetaTag(
        'description',
        'Effortlessly manage invoices with Invoice Generator. Automated calculations, recurring billing, multi-currency support, and intuitive interface streamline your invoicing process for global clients. Accessible across all platforms.',
        true
      ),
      ...getMetaTag('image', heroimg, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'BillForge | Invoice Generating Web Application',
      },
    ];
  },
};
Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
