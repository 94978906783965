import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Carousel, Col, Collapse, Icon, Row } from 'antd';
import Link from '@arivaa-react/components/link';
//import mackbook from 'images/laxaar/screenshot.png';
//import { ReactComponent as ProductMacPhone } from '../../images/laxaar/product-mac-phone.svg';
import { ReactComponent as YellowCheck } from '../../../images/laxaar/yellow-check.svg';
import Footer from 'containers/footer';
import ContactCard from '../../../components/contact-card';
import { ReactComponent as NextDark } from '../../../images/laxaar/arrow-fill-next.svg';
import { ReactComponent as PreviousDark } from '../../../images/laxaar/arrow-fill-prev.svg';
import { ReactComponent as ProductYellowLine } from '../../../images/laxaar/product-yellow-line.svg';
import { ReactComponent as ProductBlueLine } from '../../../images/laxaar/product-blue-line.svg';
import { ReactComponent as LowerWave } from '../../../images/laxaar/FAQ-lower-wave.svg';
import { ReactComponent as UpperWave } from '../../../images/laxaar/FAQ-upper-wave.svg';
import logo from './assets/logo.svg';
import section1 from './assets/section1.png';
import section2 from './assets/section2.png';
import section3 from './assets/section3.png';
import section4 from './assets/section4.png';

import screenshot1 from './assets/screenshot1.jpg';
import screenshot2 from './assets/screenshot2.jpg';
import screenshot3 from './assets/screenshot3.jpg';
import screenshot4 from './assets/screenshot4.jpg';
import screenshot5 from './assets/screenshot5.jpg';
import screenshot6 from './assets/screenshot6.jpg';
import screenshot7 from './assets/screenshot7.jpg';
import screenshot8 from './assets/screenshot8.jpg';
import screenshot9 from './assets/screenshot9.jpg';
import screenshot10 from './assets/screenshot10.jpg';
import screenshot11 from './assets/screenshot11.jpg';
import { getMetaTag } from '../../../helpers/seo';
import {
  CaretRightOutlined,
  CompassOutlined,
  CustomerServiceOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';

const ProductMacPhone = () => null;
const mackbook = null;

const { Panel } = Collapse;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const images = [
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10,
    screenshot11,
  ];

  return (
    <div className="public-page product bike-trip-booking">
      <div className="container">
        <div className="hero-section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="introduction">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <p className="title">GearUpGo - Bike Rental Application</p>
                <p className="description">
                  Simplify the booking process for users with GearUpGo, a
                  application that enables swift and hassle-free motorbike
                  reservations. With intuitive interfaces and seamless
                  navigation, users can browse available bikes, select preferred
                  models, and confirm bookings within minutes, enhancing overall
                  user satisfaction.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Know more</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section1} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave yellow">
          <ProductYellowLine />
        </div>

        <div className="section features">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CompassOutlined />
                </div>
                <div className="text">
                  <p className="title">GPS Navigation</p>
                  <p className="description">
                    Integration with GPS navigation to help users find pickup
                    and drop-off locations easily.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <FileSearchOutlined />
                </div>
                <div className="text">
                  <p className="title">Search and Filters</p>
                  <p className="description">
                    Enable users to search for available bikes based on
                    location, date, price, and bike specifications.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="feature">
                <div className="icon">
                  <CustomerServiceOutlined />
                </div>
                <div className="text">
                  <p className="title">Go Live Support</p>
                  <p className="description">
                    Our Customer Support team is here to assist you even after
                    going live. Reach out to us anytime.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={80}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section2} alt="ïmage" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">Secure Payments Gaining Customer Trust</p>
                <p className="description">
                  Ensure secure transactions and smooth rides for your customers
                  with our integrated payment system. Built-in encryption and
                  fraud prevention measures guarantee safe transactions, while
                  automated invoicing and payment processing streamline the
                  rental experience for both parties, fostering trust and
                  reliability.
                </p>
              </div>
              <div className="actions">
                <Link routeKey="contact">
                  <Button className="yellow-btn">Get in touch</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information reverse">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">Real-Time Tracking for Enhanced Safety</p>
                <p className="description">
                  Prioritize user safety and peace of mind with real-time
                  tracking features. Utilizing GPS technology, renters can track
                  their bike's location in real-time, providing added security
                  against theft or loss. Additionally, administrators can
                  monitor fleet movements and optimize logistics for efficient
                  operations.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Got Questions?</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                {/* <ProductMacPhone /> */}
                <img src={section3} alt="image" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="section information">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="graphic">
                <img src={section4} alt="image" />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="text">
                <p className="title">
                  Responsive Customer Support, Every Step of the Way
                </p>
                <p className="description">
                  Deliver exceptional customer service with our responsive
                  support system. Whether users have inquiries about bookings,
                  require assistance during rentals, or need troubleshooting
                  guidance, our dedicated support team is available around the
                  clock to address their needs promptly, ensuring a smooth and
                  enjoyable rental experience.
                </p>
                <div className="actions">
                  <Link routeKey="contact">
                    <Button className="yellow-btn">Let's get started?</Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section screens">
          <div className="heading">
            <p className="title">
              Customizable Features for Tailored Experiences
            </p>
            <p className="description">
              Craft your bike rental platform to meet your specific business
              needs with customizable features. From personalized branding and
              user interfaces to flexible pricing models and promotional tools,
              GearUpGo empowers you to create a unique and engaging experience
              that resonates with your target audience, driving customer loyalty
              and business growth.
            </p>
          </div>
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <div className="carousel">
                <Carousel
                  effect="fade"
                  customPaging={(i) => (
                    <a>
                      <img src={images[i]} alt="image" />
                    </a>
                  )}
                  slidesToScroll={1}
                  slidesToShow={1}
                  speed={500}
                  dotsClass="slick-dots slick-thumb"
                  dots
                  infinite={false}
                  arrows={true}
                  nextArrow={<NextDark />}
                  prevArrow={<PreviousDark />}
                >
                  {images.map((item, index) => {
                    return (
                      <div className="slide" key={index}>
                        <img src={item} alt="image" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </Col>
            <Col xs={0} sm={0} md={0} lg={8} xl={8}>
              <div className="card-container">
                <ContactCard title="Looking for something similar?" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="wave blue">
          <ProductBlueLine />
        </div>
      </div>

      <div className="product-faqs">
        <div className="curve upper">
          <UpperWave />
        </div>
        <div className="content">
          <div className="container">
            <p className="title">Frequently Asked Questions (FAQs)</p>
            <div className="questions">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                expandIconPosition={'right'}
              >
                <Panel
                  header="Is there any recurring license cost e.g any yearly license cost to this product?"
                  key="1"
                >
                  <p>
                    There is no recurring license cost. There is just one time
                    license cost of 3000$ for using the system lifetime for your
                    business
                  </p>
                </Panel>
                <Panel
                  header="Do i require any hosting or infrastructure for this product?"
                  key="2"
                >
                  <p>
                    Yes this product requires ideally a 2GB Cloud or VPS or
                    nodejs enabled cpanel hosting. We can host it on your
                    preferred hosting provider
                  </p>
                </Panel>
                <Panel
                  header="Do you provide any hosting so that i don't have to worry about it?"
                  key="3"
                >
                  <p>
                    Yes we do, We provide you a fully tailor made hosting
                    solution for this product a very nominal charge depending on
                    the your business traffic for this system.
                  </p>
                </Panel>
                <Panel header="How about maintainence of the system? " key="4">
                  <p>
                    Ideally once deployed, this system doesn't require any
                    regular maintainence unless there are frequent
                    customizations to this product. If you get those
                    customizations done by us, We anyways run a maintainence
                    check while deploying the customizations.
                  </p>
                </Panel>
                <Panel
                  header="Do i get the source code or designs for the system? "
                  key="5"
                >
                  <p>Yes, We can provide that on request</p>
                </Panel>
                <Panel
                  header="Can i use this license for more than one business?"
                  key="6"
                >
                  <p>
                    No, This license is only for one time use. If you have
                    multiple businesses that require this solution, then you can
                    reach us and we can see how a deal can be made as per your
                    requirements
                  </p>
                </Panel>
              </Collapse>
            </div>
            <div className="action">
              <Link routeKey="contact">
                <Button className="primary-btn">Still got questions?</Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="curve">
          <LowerWave />
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

Main.routeProps = {
  getMetaTags: () => {
    return {
      ...getMetaTag('title', 'GearUpGo - Bike Rental Application', true),
      ...getMetaTag(
        'description',
        'Simplify the booking process for users with GearUpGo, a application that enables swift and hassle-free motorbike reservations. With intuitive interfaces and seamless navigation, users can browse available bikes, select preferred models, and confirm bookings within minutes, enhancing overall user satisfaction.',
        true
      ),
      ...getMetaTag('image', section1, true),
    };
  },
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Products',
        routeKey: 'products',
      },
      {
        label: 'GearUpGo - Bike Rental Application',
      },
    ];
  },
};

Main.displayName = 'Web Product';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
