import withPost from 'containers/with-post';
import { portfolioMapper } from '../../helpers/mappers';
import PortfolioPost from 'components/portfolio-post';
import PortfolioCard from '../../components/portfolio-small-card';
const Main = withPost({
  entityName: 'portfolio',
  mapper: portfolioMapper,
  postRequestParams: {
    populate: true,
  },
  requestParams: {
    //populate: true,
    sort: 'projectDate',
    sortType: 'desc',
  },
  classNames: {
    page: 'single-portfolio',
  },
  PostComponent: PortfolioPost,
  RelatedPostComponent: PortfolioCard,
  getBreadcrumbs: () => {
    return [
      {
        label: 'Home',
        routeKey: 'landing',
      },
      {
        label: 'Portfolio',
        routeKey: 'portfolio',
      },
      {
        label: window.app.pageData?.post?.title,
      },
    ];
  },
});
Main.url = 'project/:key';
Main.displayName = 'Project';

//Pre process the container with Redux Plugins
export default Main;
