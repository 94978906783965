import React from 'react';
import preProcess from 'containers/preprocess';
import { Form, Input, Select, Radio } from 'antd';
import ContactForm from 'containers/contactForm';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { GET_ENTITIES } from 'app-redux/actions';
import withApiData from 'containers/with-api-data';

const { Option } = Select;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ product, data, loading }) => {
  return (
    <ContactForm
      showLabels={true}
      inline={true}
      itemProps={{
        message: {
          label: 'Infrastructure Details',
        },
      }}
      inputProps={{
        message: {
          placeholder:
            'Please enter any domain/server/smtp/sms gateway details that you have already and would like us to reuse.',
        },
      }}
      getExtraFields={
        !product
          ? ({ form }) => {
              const { getFieldDecorator } = form;
              return [
                <Form.Item
                  label={'Select Product'}
                  key={'product'}
                  hasFeedback={true}
                >
                  {getFieldDecorator('product', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select the product',
                      },
                    ],
                    initialValue:
                      data && data.length > 0 ? data[0].title : undefined,
                  })(
                    <Select loading={loading}>
                      {(data || []).map(({ title }, index) => {
                        return (
                          <Option value={title} key={index}>
                            {title}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>,
                <Form.Item
                  key={'hostingInterested'}
                  label={
                    'Would you be interested in specially optimized hosting and maintainence provided by us for this product?'
                  }
                  hasFeedback={true}
                >
                  {getFieldDecorator('hostingInterested', {
                    initialValue: 'Yes',
                  })(
                    <Radio.Group size="large">
                      <Radio.Button value={'Yes'}>Yes</Radio.Button>
                      <Radio.Button value={'No'}>No</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>,
                <Form.Item
                  label={'License/Purchase Id'}
                  key={'purchaseId'}
                  hasFeedback={true}
                >
                  {getFieldDecorator('purchaseId', {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the License/Purchase Id',
                      },
                    ],
                  })(
                    <Input
                      placeholder={
                        'License/Purchase Id provided to you in your invoice'
                      }
                      className="input"
                    />
                  )}
                </Form.Item>,
              ];
            }
          : undefined
      }
      extra={{
        category: 'Enhancement',
        product: product ? product : undefined,
      }}
    />
  );
};

export default withApiData(Main, {
  requestConfig: {
    entityName: 'product',
    all: true,
  },
});
