import React from 'react';
import './styles.scss';
import { ReactComponent as Marks } from '../../images/laxaar/marks.svg';
import { ReactComponent as DashedBorder } from '../../images/laxaar/dashed-border.svg';
import { ReactComponent as ReviewWave } from '../../images/laxaar/review-wave.svg';
import Rating from '../../components/rating';
import { getCarouselArrowsWithoutError } from '../../helpers/carousel';
import OtherInformation from './other-information';
import ImageCarousel from './image-carousel';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ post }) => {
  const images = [
    ...(post && post.image ? [post.image] : []),
    ...((post && post.additionalImages) || []),
  ];

  return post ? (
    <div className="portfolio-details">
      <p className="title">{post.title}</p>
      <ImageCarousel post={post} />
      <div className="description">
        <div className="about">
          <p className="label">About</p>
          <div
            className="editor-content"
            dangerouslySetInnerHTML={{ __html: post.description }}
          />
        </div>
        <OtherInformation post={post} />
      </div>
      {post.testimonial && (
        <div className="review">
          <p className="label">Client Review</p>
          <div className="review-card">
            <div className="review-wave left">
              <ReviewWave />
            </div>
            <div className="content">
              <div className="info">
                <div className="person">
                  <p className="name">{post.testimonial.name}</p>
                  <p className="position">
                    {post.testimonial.designation} at{' '}
                    <span className="text">{post.testimonial.company}</span>
                  </p>
                </div>
                <div className="stars">
                  <Rating rating={post.testimonial.rating} />
                </div>
              </div>
              <p className="separator">
                <span className="marks">
                  <Marks />
                </span>
                <DashedBorder />
              </p>
              <p className="text">{post.testimonial.title}</p>
            </div>
            <div className="review-wave">
              <ReviewWave />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

Main.displayName = 'Portfolio-Post';

export default Main;
