import React from 'react';
import './styles.scss';
import Footer from 'containers/footer';
import { Col, Row } from 'antd';
import EnhancementForm from 'containers/enhancementForm';
import BusinessCard from 'components/business-card';
import { getMetaTag } from '../../helpers/seo';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="screen feature-request animate__animated animate__fadeIn">
      <div className="vector" />
      <div className="vector left" />
      <div className="container">
        <div className="section">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16}>
              <div className="heading">
                <p className="primary-text">Product Feature</p>
                <p className="primary-light-text">Request</p>
              </div>
              <div className="description">
                <p>
                  Not quite satisfied with what you’ve bought, but wish the
                  product you purchased could do more?
                </p>
                <p>We are waiting to discuss it with you.</p>
              </div>
              <div className="details">
                <div className="form">
                  <EnhancementForm />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
              <BusinessCard />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};
Main.routeProps = {
  getMetaTags: () => {
    return getMetaTag(
      'title',
      'Website Enhancement | Mobile App Enhancement | Software Enhancement | Laxaar Product Enhancements ',
      true
    );
  },
};
export default Main;
