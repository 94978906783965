/**
 * Contains Routing for the application
 */
import React from 'react';
import { renderRoutes } from 'react-router-config';
import pages from './pages';
import { history } from './redux';
import Error404 from './pages/error404';
import Main from 'containers/main';
import StaticPage from 'containers/static-page';
import { initializeRoutes } from '@arivaa-react/react-router5';
import { Redirect } from 'react-router-dom';
import { getMetaTag } from './helpers/seo';

/**
 * Route keys & Paths Map
 * @type {{Object}}
 */
const MainRoute = {
  component: Main,
  children: {},
};

/**
 * Append Page Routes
 */
MainRoute.children = {
  ...MainRoute.children,
  ...pages,
};

/**
 * Create Static Pages we get from server
 * @returns
 */
export const createStaticRoutes = () => {
  const { staticPages } = window.app || {};
  if (!staticPages) {
    return;
  }
  let pages = {};
  (staticPages || []).forEach((page, index) => {
    pages[page.route] = {
      url: page.route,
      component: StaticPage,
      routeProps: {
        getMetaTags: (pageData) => {
          return getMetaTag('title', page.title, true);
        },
        html: page.html || '',
        title: page.title || '',
        public: true,
        cache: true,
        priority: 0.5,
      },
    };
  });
  MainRoute.children.staticPages = {
    component: ({ route }) => <div>{renderRoutes(route.routes)}</div>,
    url: 'pages',
    routeProps: {
      public: true,
      cache: true,
    },
    children: pages,
  };
};

// For Caching
let routes;

/**
 * Get Routes for initializing
 * @returns
 */
export const getRoutes = () => {
  if (routes) {
    return routes;
  }
  createStaticRoutes();
  routes = [
    {
      component: Main,
      routes: [
        /* {
          path: '/',
          exact: true,
          component: () => <Redirect to="/landing" />,
        },*/
        {
          path: '/contact-us',
          exact: true,
          component: () => <Redirect to="/contact" />,
        },
        ...initializeRoutes(MainRoute.children, history, {
          error404Page: '/error404',
          getExtraProps: ({ path }) => {
            const isCurrentRoute =
              window.app.initialPageUrl === window.location.pathname;

            return {
              ...getPageConfiguration(path),
              pageData: isCurrentRoute ? window.app.pageData : undefined,
            };
          },
        }),
        {
          component: Error404,
        },
      ],
    },
  ];
  return routes;
};

/**
 * Route Declarations
 * Set publicHeader = false to disable publicHeader
 * Set public = true to allow guest access to a route
 * @type {XML}
 */
export const Routes = () => {
  return renderRoutes(getRoutes());
};

/**
 * Get Page Configuration for any specific page
 * @param {*} path
 * @returns
 */
export function getPageConfiguration(path) {
  let config = ((window.app && window.app.pageConfigurations) || []).find(
    (config) => {
      return '/' + config.routeUrl === path;
    }
  );
  config = config || {};
  let output = {};
  (config.properties || []).forEach(({ name, value }) => {
    output[name] = value;
  });
  return output;
}
