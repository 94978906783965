import React, { useCallback, useEffect, useState } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Col, Row, Input } from 'antd';
import Link from '@arivaa-react/components/link';
import Footer from 'containers/footer';
import ContactSection from '../../components/contact-section';
import { createAction } from '@arivaa-react/redux/helpers/action';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GET_ENTITIES, UI_SET_PROPERTY } from 'app-redux/actions';
import SkeletonGrid from 'components/skeleton-grid';
import { ReactComponent as Wave15 } from '../../images/laxaar/wave-line-15-degree.svg';
import { ReactComponent as Wave19 } from '../../images/laxaar/wave-line-19-degree.svg';
import { useDidUpdateEffect } from 'components/useDidUpdateEffect';
import debounce from 'lodash.debounce';

const { Search } = Input;

export default (config) => {
  config = config || {};
  const {
    requestParams: initialRequestParams,
    entityName,
    getFilters,
    classNames = {},
    getPageData: getExtraPageData,
    title,
    onFilterChange,
    CardComponent,
    subTitle,
    routeProps,
    showFilters,
    getBreadcrumbs,
  } = config;
  const pageSize = 6;
  /**
   * @description Sample Container
   * @type Container
   * @author Inderdeep
   */
  const Main = ({
    pageData,
    getData,
    loading: initialLoading,
    requestParams: newRequestParams,
    cardListProps: newCardListProps,
  }) => {
    const [requestParams, setRequestParams] = useState(
      initialRequestParams || {}
    );
    const [total, setTotal] = useState(pageData?.total || 0);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(pageData?.data || []);
    const [loading, setLoading] = useState(!!initialLoading);
    const [filterMap, setFilterMap] = useState({});
    const filters = getFilters instanceof Function ? getFilters(pageData) : [];

    const getNewData = useCallback(
      async (override) => {
        setLoading(true);
        try {
          const { payload } = await getData({
            page: override ? 1 : page + 1,
            size: pageSize,
            ...requestParams,
            ...newRequestParams,
          });
          setPage(override ? 1 : page + 1);
          setData(
            override ? payload.data.data : [...data, ...payload.data.data]
          );
          setTotal(payload.data.total);
        } catch (e) {
          console.error('Error while getting new data', { e });
        }
        setLoading(false);
      },
      [page, data, newRequestParams, requestParams]
    );

    const debouncedHandleChange = useCallback(
      debounce((value) => {
        if (value) {
          const searchFilter = {
            // search: [`title;${value};like`, `shortDescription;${value};like`],
            search: `title;${value};like`,
            // 'search[1]': `shortDescription;${value};like`,
            // operator: 'or',
          };
          setRequestParams((prevRequestParams) => ({
            ...prevRequestParams,
            ...searchFilter,
          }));
        } else {
          setRequestParams(initialRequestParams);
        }
      }, 400),
      [initialRequestParams, requestParams]
    );

    useDidUpdateEffect(() => {
      setLoading(initialLoading);
    }, [initialLoading]);

    useDidUpdateEffect(() => {
      if (pageData && pageData.data) {
        setData(pageData.data);
        setTotal(pageData.total);
      }
    }, [pageData]);

    useDidUpdateEffect(() => {
      getNewData(true);
    }, [newRequestParams, requestParams]);

    useDidUpdateEffect(() => {
      onFilterChange instanceof Function &&
        filters &&
        filters.length > 0 &&
        setRequestParams({
          ...initialRequestParams,
          ...onFilterChange(filterMap),
        });
    }, [filterMap]);

    return (
      <div className={`screen ${classNames.page}`}>
        <div className="vector" />
        <div className="vector right" />
        <div className="wave">
          <Wave15 />
        </div>
        <div className="wave curved">
          <Wave19 />
        </div>
        <div className="container">
          <div className="heading">
            <span className="primary-text">{title} </span>
            <span className="primary-light-text">{subTitle}</span>
          </div>
          <div className="search">
            <Search
              allowClear
              placeholder="Search"
              size="large"
              onChange={(e) => debouncedHandleChange(e.target.value)}
            />
          </div>
          <InfiniteScroll
            dataLength={data.length}
            next={getNewData}
            hasMore={total > data.length}
            loader={total === 0 ? null : <SkeletonGrid />}
            scrollThreshold={0.6}
          >
            {showFilters !== false && (
              <div className="filters">
                <ul>
                  <li
                    onClick={() => {
                      setFilterMap({});
                    }}
                  >
                    <Link
                      className={`filter${
                        Object.keys(filterMap).length === 0 ? ' active' : ''
                      }`}
                    >
                      Show All
                    </Link>
                  </li>

                  {(filters || []).map((item, index) => (
                    <li key={index}>
                      <Link
                        className={`filter${
                          filterMap[item.value] ? ' active' : ''
                        }`}
                        onClick={() => {
                          setFilterMap({
                            ...filterMap,
                            [item.value]: !filterMap[item.value],
                          });
                        }}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="list">
              {
                <Row gutter={24} type="flex" align="top">
                  {data.map((item, index) => {
                    return (
                      <Col xs={24} sm={24} md={24} lg={8} xl={8} key={index}>
                        <CardComponent {...item} id={item.key} />
                      </Col>
                    );
                  })}
                </Row>
              }
              {/* {loading && page === 1 && <SkeletonGrid />} */}
              {loading && <SkeletonGrid />}
            </div>
            {/* {total > data.length && (
            <Button className="yellow-btn" onClick={()=>getNewData()}>
              Load More
            </Button>
          )} */}
          </InfiniteScroll>
        </div>
        <ContactSection />
        <Footer />
      </div>
    );
  };

  /**
   * Bind Redux Actions
   * @param dispatch
   * @returns {{Object}}
   */
  const bindAction = (dispatch) => {
    return {
      getData: (data) => {
        return dispatch(
          createAction(GET_ENTITIES, {
            entityName,
            ...data,
          })
        );
      },
      saveData: (data) => {
        return dispatch(
          createAction(UI_SET_PROPERTY, {
            ...data,
          })
        );
      },
    };
  };
  /**
   * Bind State to props
   * @param dispatch
   * @returns {{Object}}
   */
  const mapStateToProps = (state) => {
    return {};
  };
  Main.displayName = 'Post-List';
  Main.showPageSpinner = false;
  Main.routeProps = {
    getPageData: async function () {
      const { data } = await createAction(GET_ENTITIES, {
        entityName,
        page: 1,
        size: pageSize,
        ...initialRequestParams,
      }).payload;
      return {
        ...data,
        ...(getExtraPageData instanceof Function
          ? await getExtraPageData.apply(this, arguments)
          : {}),
      };
    },
    ...routeProps,
  };
  //Pre process the container with Redux Plugins
  return preProcess(Main, {
    connect: [mapStateToProps, bindAction],
    localize: true,
  });
};
