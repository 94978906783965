import BlogCard from '../../components/blog-card';
import withPostList from 'containers/with-post-list';
import { getMetaTag } from '../../helpers/seo';
const Main = withPostList({
  entityName: 'blog-post',
  title: 'We believe in',
  subTitle: 'Sharing Knowledge',
  CardComponent: BlogCard,
  classNames: {
    page: 'portfolio products animate__animated animate__fadeIn',
  },
  requestParams: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Latest blogs on Website and App Development, AI and DevOps',
        true
      );
    },
    priority: 1.0,
  },
  showFilters: false,
});
Main.displayName = 'Blogs';

export default Main;
