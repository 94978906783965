import React from 'react';
import './styles.scss';
import { Col, Row } from 'antd';
import Link from '@arivaa-react/components/link';
import SocialLinks from 'components/social-links';
import { ReactComponent as DarkLogo } from '../../images/laxaar/logo-dark.svg';

var view = function () {
  return (
    <div className="footer">
      <div className="main">
        <div className="container">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <div className="about">
                <div className="logo">
                  <DarkLogo />
                </div>
                <p className="manifesto">Our Engineering Philosphy</p>
                <p className="message">
                  A code is useless in a realistic scenario if it’s not
                  comprehensible to those who have to maintain it or want to
                  reuse it. At Laxaar we understand this, and thus always focus
                  on writing codes that are easy to understand for the next
                  individual using it.
                </p>
                {/*  <Link className="link">
                  <span className="text">Get a free audit & proposal</span>
                  <Arrow />
                </Link>*/}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Row gutter={48}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="links odd">
                  <p className="label">Company</p>
                  <ul>
                    <li>
                      <Link routeKey="services">Services</Link>
                    </li>
                    <li>
                      <Link routeKey="portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link routeKey="products">Products</Link>
                    </li>
                    <li>
                      <Link routeKey="careers">Careers</Link>
                    </li>
                  </ul>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="links even"
                >
                  <p className="label">Knowledge</p>
                  <ul>
                    <li>
                      <Link routeKey="case-studies">Case Studies</Link>
                    </li>
                    <li>
                      <Link routeKey="faqs">FAQs</Link>
                    </li>
                    <li>
                      <Link routeKey="staticPages.terms">
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link routeKey="staticPages.privacy">Privacy Policy</Link>
                    </li>
                  </ul>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="links odd">
                  <p className="label">Reach Us</p>
                  <ul>
                    <li>
                      <Link routeKey="quote">Get Quote</Link>
                    </li>
                    <li>
                      <Link routeKey="enhancement">Feature Request</Link>
                    </li>
                    <li>
                      <Link routeKey="become-partner">Partner With Us</Link>
                    </li>
                    <li>
                      <Link routeKey="contact">Contact Us</Link>
                    </li>
                  </ul>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="links even"
                >
                  <p className="label">Expertise</p>
                  <ul>
                    <li>
                      <Link routeKey="expertise.website-development">
                        Website Development
                      </Link>
                    </li>
                    <li>
                      <Link routeKey="expertise.cloud-development">
                        Cloud Development
                      </Link>
                    </li>
                    <li>
                      <Link routeKey="expertise.mobile-app-development">
                        Mobile Development
                      </Link>
                    </li>
                    <li>
                      <Link routeKey="expertise.hosting-and-maintainence">
                        Cloud Hosting Solutions
                      </Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="others">
        <div className="container">
          <div className="left">
            <ul>
              <li>
                <p>Copyright © {new Date().getFullYear()} Laxaar</p>
              </li>
              <li className="space">
                <span className="separator" />
              </li>
              <li>
                <Link routeKey="staticPages.terms">
                  <p>Terms and Conditions</p>
                </Link>
              </li>
              <li>
                <Link routeKey="staticPages.privacy">
                  <p>Privacy Policy</p>
                </Link>
              </li>
              <li>
                <Link routeKey="staticPages.cookies">
                  <p>Cookies Policy</p>
                </Link>
              </li>
              {/* <li>
                <Link routeKey="staticPages.payment-policy">
                  <p>Payment Policy</p>
                </Link>
              </li> */}
              <li>
                <Link routeKey="staticPages.partner-program">
                  <p>Partner Program</p>
                </Link>
              </li>
            </ul>
          </div>
          <div className="right">
            <SocialLinks />
          </div>
        </div>
      </div>
    </div>
  );
};
export default view;
