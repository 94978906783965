import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Scale your Business',
  subTitle: 'With Cloud',
  description: `Irrespective of the size of your business, Cloud Infrastructure is the right choice for you for its state-of-the-art technology. From start-ups to Fortune 500 companies, we provide the option to customize a spot for themselves on the Cloud.
`,
  services: [
    'Cloud Security & Risk Management',
    'Cloud Automation and Management',
    'Cloud Operations and Reports',
    'Cloud discovery and optimization',
  ],
  bannerTitle: 'Still not sure about Cloud Infrastructure?',
  bannerDescription: `Cloud Deployment will not only establish your expertise in modern technology on an industry level but also give your business the benefit of seamless extensibility, flexibility, and scalability with its extensive features.`,
  statistics: [
    {
      title: '500+',
      description: 'Identified Security Risks',
    },
    {
      title: '30+',
      description: 'Ongoing Clients',
    },
    {
      title: '200+',
      description: 'Daily Managed Cloud Servers',
    },
    {
      title: '20+',
      description: 'Cloud Providers Support',
    },
  ],
  process: [
    {
      title: 'Financial Analysis',
      details: [
        'How much is the cloud budget?',
        'Purpose behind the cloud infrastructure?',
        'Who are our target audience?',
        'What are the scaling expectations?',
        'Applicable regulations and compliances?',
      ],
    },
    {
      title: 'Technical Analysis',
      details: [
        'Best cloud providers for the business?',
        'Long term outlook for the provider?',
        'Downtime and SLA Trends ?',
        'Scaling approach of the providers?',
        'Software License Requirements?',
      ],
    },
    {
      title: 'Security Analysis',
      details: [
        'Type of services to be run?',
        'Security Risk Identification?',
        'Security Risk Analysis and Mitigation?',
        'Service Dependency Analysis?',
        'Infrastructure Architecture Planning?',
      ],
    },
    {
      title: 'Deployment Plan',
      details: [
        'Infrastructure Backlog Planning',
        'Infrastructure Planning and Discussion',
        'Security Testing Plan',
        'Stakeholder review meetings',
        'Go Live and Release Plan',
      ],
    },
  ],
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Expert Devops Consultants in AWS, Heroku, Google Cloud and Azure',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
