import PageComponent from '../page-component';
import { getMetaTag } from '../../../helpers/seo';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = PageComponent({
  title: 'Curated Cloud Hosting',
  subTitle: ' With 99.99% Uptime',
  description: `In today's digital era of everything with being reliant on stability and reputation of software products, we provide cloud hosting and maintainence solutions for all the software projects that we work on which are specifically optimized to cater the needs of the project and its timely maintained on both software side and its infrastructure in order to keep them running without any unexpected issues that may result in any downtimes.`,
  services: [
    'Dedicated/Shared Cloud Servers',
    'Unlimited Bugfixes at no extra cost',
    'Free Maintainence and Upgrades',
    'Automated code releases and deployments',
  ],
  bannerTitle:
    'Want to focus on your business needs without any technical hurdles?',
  bannerDescription: `As per our studies, an outdated product and infrastructure not maintained for an year can lead upto re-spending of 100% of the initial project cost to upgrade depending on various factors. Our hosting and maintainence plans are priced at a fraction of that cost (Starting from 50$/month) that help you attend to your business needs without the fear of extra spending on outdated products, upgrades and issue fixes.`,
  statistics: [
    {
      title: '50+',
      description: 'Daily maintained products',
    },
    {
      title: '100+',
      description: 'Happy Clients',
    },
    {
      title: '1000+',
      description: 'Monthly Monitorings and Upgrades',
    },
    {
      title: '100K+ USD',
      description: 'Saved on expensive upgrades',
    },
  ],
  process: [
    {
      title: 'Project Analysis',
      details: [
        'What are the server requirements?',
        'Long term outlook for the project?',
        'Downtime and SLA requirements ?',
        'Scaling requirements of the projects?',
        'Software License Requirements?',
      ],
    },
    {
      title: 'Financial Analysis',
      details: [
        'How much is the infrastructure budget?',
        'Daily traffic estimations?',
        'Who are our target audience?',
        'Realtime support requirements?',
        'Applicable regulations and compliances?',
      ],
    },

    {
      title: 'Security Analysis',
      details: [
        'Type of services to be run?',
        'Security Risk Identification?',
        'Security Risk Analysis and Mitigation?',
        'Service Dependency Analysis?',
        'Infrastructure Architecture Planning?',
      ],
    },
    {
      title: 'Deployment Plan',
      details: [
        'Infrastructure Backlog Planning',
        'Infrastructure Planning and Discussion',
        'Security Testing Plan',
        'Stakeholder review meetings',
        'Go Live and Release Plan',
      ],
    },
  ],
  tags: ['CPanel', 'VPS', 'Plesk', 'Cloud'],
  quoteButtonProps: {
    children: 'Talk to us',
  },
  routeProps: {
    getMetaTags: () => {
      return getMetaTag(
        'title',
        'Get the best hosting solutions for your website',
        true
      );
    },
  },
});
Main.displayName = 'Product-Page';
export default Main;
